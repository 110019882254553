export default {
  loading: 'Indlæser',
  notAvailable: 'Beklager, denne billet er ikke længere tilgængelig',
  soldOut: 'Billetten er muligvis udsolgt.',
  refresh: 'Opdater dine resultater',
  check: 'Tjek din rejseplan',
  timesLocal: 'alle tider er lokale',
  departingFrom: 'Du rejser fra',
  arrivingAt: 'Du ankommer til',
  returningTo: 'men rejser hjem fra',
  returningFrom: 'men rejser hjem fra',
  zuruck: '',
  bookTicket: 'Bestil dine billetter',
  readBefore: 'Læs før du bestiller',
  pricesShown:
    'Angivne priser inkluderer altid et skøn over alle obligatoriske skatter og afgifter, men husk at',
  checkAll:
    'kontrollere ALLE billetoplysninger, samlede priser samt vilkår og betingelser',
  beforeBook: 'på bestillingssiden, før du bestiller.',
  extraFees: 'Se ekstragebyrer',
  someAirlines: 'Nogle flyselskaber/rejsebureauer tager ekstragebyrer for',
  baggage: 'bagage',
  insurance: 'forsikring',
  orUseOf: 'eller for betaling med',
  creditCards: 'kreditkort',
  view: 'Se',
  airlineFees: 'flyselskabets gebyrer',
  an: '.',
  checkTC: 'Se vilkår og betingelser for rejsende på mellem 12 og 16 år.',
  restrictions:
    'Der kan være begrænsninger på unge passagerer, der rejser alene.',
  adults: 'voksne',
  adult: 'voksen',
  children: 'børn',
  child: 'barn',
  infants: 'spædbørn',
  infant: 'spædbarn',
  combine: 'Kombiner 2 enkeltflyvninger',
}
