import Vue from 'vue'
import VueI18n from 'vue-i18n'
import axios from 'axios'
import router from './router'
// import en from '@/lang/en/index.js'

Vue.use(VueI18n)

const loadedLanguages = []

// export const i18n = new VueI18n({
//   locale: 'en',
//   fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
//   messages: { en }
// })

export async function initializeVueI18n() {
  const { default: store } = await import('./store')
  const initLocale = store.getters.initLocale
  const { default: translations } = await import(
    /* webpackChunkName: "lang-[request]" */ `@/lang/${initLocale}/index.js`
  )
  const messages = {
    [initLocale]: translations,
  }

  loadedLanguages.push(initLocale)

  return new VueI18n({
    locale: initLocale,
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    messages,
  })
}

function setI18nLanguage(lang) {
  window.vm.$i18n.locale = lang
  axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export function loadLanguageAsync(lang) {
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  return import(
    /* webpackChunkName: "lang-[request]" */ `@/lang/${lang}/index.js`
  ).then((messages) => {
    window.vm.$i18n.setLocaleMessage(lang, messages.default)
    loadedLanguages.push(lang)
    return setI18nLanguage(lang)
  })
}

router.beforeEach((to, from, next) => {
  if (!window.vm) {
    return next()
  }
  const lang = to.params.locale
  loadLanguageAsync(lang).then(() => next())
})
