<template>
  <button
    class="btn btn-gray"
    :tabindex="disabled ? '-1' : '0'"
    :disabled="disabled"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot>Click me</slot>
  </button>
</template>

<script>
export default {
  name: 'SecondaryButton',
  props: {
    disabled: {
      type: Boolean,
    },
  },
}
</script>
