export default {
  save: 'Save',
  cancel: 'Cancel',
  contact: 'Contact',
  direct: 'Direct flights',
  deals: 'Best deals',
  logout: 'Logout',
  london_gatwick: 'London Gatwick',
  preferences: 'Preferences',
  search: 'Search',
  title: 'Flights to The Gambia',
  description:
    "The world's most comprehensive resource for flights to The Gambia",
}
