export default {
  NB: 'Bemærk venligst',
  nextTip: 'Næste tip',
  previousTip: 'Forrige tip',
  lastTip: 'Sidste tip',
  tip_1:
    "Denne 'månedlige visning' giver et overblik over de billigste flypriser, der er fundet inden for de sidste " +
    '15 dage. Priserne kan have ændret sig, så for at få de seneste tilbud skal du udføre en live søgning ved at klikke på knappen ',
  button: '.',
  tip_2: 'Brug fanen',
  toShow: 'til at vise',
  restrict:
    ' eller for at begrænse varighed og / eller tidligste afgang eller seneste tilbagevenden. ',
  cycle: 'Du kan cykle gennem de resterende resultater ved at bruge knapperne ',
  orClick: '(eller klik direkte på datoerne)',
  tip_3: 'Når du har fundet passende datoer, kan du klikke på',
  liveSearch:
    'for at udføre en live søgning af de seneste tilbud og priser for dine valgte datoer',
  letsGo: 'Ok lad os gå!',
}
