export default {
  airlineFees: 'Cargos de las aerolíneas',
  previousPage: 'Volver a la página anterior',
  pricesInclude:
    'Los precios siempre incluyen un cálculo aproximado de todos los impuestos y cargos obligatorios, pero algunas aerolíneas/agencias de viajes aplican cargos adicionales por ',
  baggage: 'equipaje',
  insurance: 'seguros',
  useOf: 'uso de ',
  creditCards: 'tarjetas de crédito',
  other: 'u otros servicios',
  findDetails:
    'Abajo encontrarás información sobre los cargos que aplica cada aerolínea:',
}
