export default {
  confirm: 'Confirmar contraseña',
  email: 'Dirección de correo electrónico',
  name: 'Nombre',
  password: 'Contraseña',
  register: 'Registrarte',
  registerText:
    'El registro falta sólo un minuto y te permite recibir notificaciones por correo electrónico de los precios de los vuelos. Puedes cancelar en cualquier momento...',
  registerSuccess: 'Te has registrado exitosamente.',
  registerSubmitting: 'Un momento...',
  update: 'Actualizar',
  updateSubmitting: 'Actualizando...',
  delete: 'Suprimir',
  deleteSubmitting: 'Eliminación...',
  forgot: '¿Olvidaste tu contraseña?',
  login: 'Iniciar sesión',
  loginSubmitting: 'Iniciando una sesión...',
  remember: 'Recuérdame',
  tryAgain: 'Por favor, inténtelo de nuevo...',
  serverConnection: 'No se puede conectar al servidor',
  failed: 'Estas credenciales no coinciden con nuestros registros.',
  throttle:
    'Demasiados intentos de acceso. Por favor intente nuevamente en :seconds segundos.',
  passwordLength:
    'Las contraseñas deben coincidir y contener al menos 6 caracteres',
  reset: '¡Tu contraseña ha sido restablecida!',
  sent: '¡Te hemos enviado por correo el enlace para restablecer tu contraseña!',
  token: 'El token de recuperación de contraseña es inválido.',
  user: 'No podemos encontrar ningún usuario con ese correo electrónico.',
  contactUs: 'Contact us',
  contactText:
    'Have you spotted an error? Is there anything you would like us to improve?',
  drop: "Drop us an email and we'll do our best to help!",
  message: 'Your message',
  contact: 'Send message',
  contactSubmitting: 'Sending message',
}
