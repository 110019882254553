<template>
  <span ref="trigger">
    <slot></slot>
    <Transition :name="effect">
      <div
        v-if="show"
        ref="popover"
        class="
          absolute
          p-2
          bg-white
          border border-gray-300
          shadow
          z-10
          rounded
          max-w-sm
        "
        :class="[placement]"
      >
        <div class="arrow"></div>
        <div @click.stop="close">
          <slot name="content">
            <!-- eslint-disable vue/no-v-html -->
            <div v-html="content"></div>
            <!--eslint-enable-->
          </slot>
        </div>
      </div>
    </Transition>
  </span>
</template>

<script>
import PopoverMixin from './utils/popoverMixins.js'

export default {
  mixins: [PopoverMixin],
  props: {
    trigger: {
      type: String,
      default: 'click',
    },
  },
  methods: {
    close(e) {
      if (e.target.className.includes('popover-hide')) {
        this.show = false
      }
    },
  },
}
</script>

<style>
.popover.top,
.popover.left,
.popover.right,
.popover.bottom {
  display: block;
}

.fade-enter-active {
  animation: fade-in 0.3s ease-in;
}

.fade-leave-active {
  animation: fade-out 0.3s ease-out;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
