export default {
  getPriceAlerts: 'Modtag prisagenter',
  airlines: 'Flyselskaber',
  combination: 'Kombinationer af flyselskaber',
  airports: 'Lufthavne',
  stops: 'Mellemlandinger',
  direct: 'Direkte',
  oneStop: '1 omstigning',
  twoPlusStops: '2+ omstigningner',
  none: 'Ingen',
  selectAll: 'Vælg alle',
  clearAll: 'Fravælg alle',
  flyOut: 'Flyv ud og hjem fra',
  flyOutGerman: '',
  sameAirports: 'samme lufthavne',
  no: 'Ingen',
  changeAirport: 'skift lufthavn',
  duringConnections: 'under forbindelser',
  departFrom: 'Rejs fra',
  arriveAt: 'Ankom til',
  journeyDuration: 'Rejsens varighed',
  hour: 'time',
  hours: 'timer',
  departureTimes: 'Afgangstider',
  arrivalTimes: 'Ankomsttider',
  outbound: 'Afrejse',
  inbound: 'Retur',
}
