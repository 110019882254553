<template>
  <label
    class="
      p-1
      flex-1 flex
      justify-center
      items-center
      h-12
      rounded-tl
      border-b-4
      focus:outline-none focus:shadow-inset
    "
    :class="{
      'bg-white text-green-500 font-semibold border-green-500': isSpecific,
      'cursor-pointer bg-gray-200 border-gray-200 hover:border-white hover:bg-white focus:bg-gray-300':
        !isSpecific,
    }"
    :tabindex="isSpecific ? -1 : 0"
    @click.prevent="$emit('select-calendar-type', type)"
    @keydown.enter.prevent="$emit('select-calendar-type', type)"
    @keydown.space="$emit('select-calendar-type', type)"
  >
    <InlineSvg name="calendar" classes="w-4 h-4 inline"></InlineSvg>
    <span class="ml-1">{{ name }}</span>
    <input class="hidden" name="triptype" type="radio" tabindex="-1" />
  </label>
</template>

<script>
export default {
  name: 'CalendarTypeTab',
  props: {
    type: {
      type: String,
      default: 'specific',
    },
  },
  computed: {
    isSpecific() {
      return this.$store.state.criteria.calendarType === this.type
    },
    name() {
      const nameKey = `${this.type}Date`

      return this.$t(`searchControlsContainer.${nameKey}`)
    },
  },
}
</script>
