export default {
  AMS: 'Amsterdam',
  BHX: 'Birmingham',
  LGW: 'Londen Gatwick',
  MAN: 'Manchester',
  BCN: 'Barcelona',
  BRU: 'Brussel',
  LPA: 'Gran Canaria',
  DKRA: 'Dakar',
  FRA: 'Frankfurt',
  DUS: 'Dusseldorf',
  LEJ: 'Parijs',
  CDG: 'München',
  return: 'retour',
  oneWay: 'enkele reis',
  topDeals: 'De beste aanbiedingen van vandaag',
  welcome:
    'Welkom op de website met de meest uitgebreide informatie voor goedkope vluchten naar Gambia!',
  explain_1:
    'NB Dit project is/was een work-in-progress en mag alleen als zodanig worden beschouwd. Ik schreef het oorspronkelijk als een standaard Laravel-applicatie, maar ontkoppelde toen de front- en back-ends, dus het is nu een Vue-CLI-project dat wordt aangedreven door een Laravel API.',
  explain_2:
    'Ik was halverwege met het migreren van de frontend van Bootstrap naar Tailwind CSS, dus de styling is op sommige plaatsen nogal slordig. En aangezien ik de webschraper heb afgesloten die deze Skyscanner-resultaten met die van mij heeft uitgebreid, zult u niet het belangrijkste voordeel van de site ervaren. Maar hopelijk geeft het je toch een idee van waar het allemaal om ging!',
  readMore: 'Lees verder',
  readLess: 'Lees minder',
}
