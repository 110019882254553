<template>
  <button
    class="
      flex
      justify-center
      items-center
      w-full
      h-12
      cursor-pointer
      bg-gray-200
      hover:bg-white
      focus:outline-none focus:shadow-inset
    "
    :class="{ 'border-yellow-500 bg-yellow-300': isCheapestMonthSelected }"
    tabindex="0"
    @click.prevent="$emit('select-cheapest-month')"
    @keydown.enter.prevent="$emit('select-cheapest-month')"
    @keydown.space="$emit('select-cheapest-month')"
  >
    {{ $t('searchControlsContainer.cheapestMonth') }}
  </button>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'CheapestMonthButton',
  computed: {
    ...mapState(['criteria']),
    isCheapestMonthSelected() {
      return this.$store.state.criteria.outboundDate === 'anytime'
    },
  },
}
</script>
