export default {
  getPriceAlerts: 'Ricevi avvisi di prezzo',
  airlines: 'Linee aeree',
  combination: 'Combinazioni di compagnie aeree',
  airports: 'Aeroporti',
  stops: 'Scali',
  direct: 'Diretto',
  oneStop: '1 scalo',
  twoPlusStops: '2+ scali',
  none: 'Nessuno',
  selectAll: 'Seleziona tutto',
  clearAll: 'Cancella tutto',
  flyOut: 'Voli di andata e ritorno da e per',
  flyOutGerman: '',
  sameAirports: 'lo stesso aeroporto',
  no: 'Gli scali non prevedono',
  changeAirport: 'cambio aeroporto',
  duringConnections: '',
  departFrom: 'Partenza da',
  arriveAt: 'Arrive at',
  journeyDuration: 'Durata del viaggio',
  hour: 'ora',
  hours: 'ore',
  departureTimes: 'Orari di partenza',
  arrivalTimes: 'Orari di arrivo',
  outbound: 'Andata',
  inbound: 'Ritorno',
}
