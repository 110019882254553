export default {
  return: 'Retur',
  oneway: 'En vei',
  from: 'Fra',
  To: 'Til',
  to: 'til',
  placeholder: 'Skriv inn land, by eller flyplass',
  nearby: 'Inkluder flyplasser i nærheten',
  swap: 'Klikk her for å bytte sted',
  outbound: 'Utreise',
  inbound: 'Retur',
  classpax: 'Kabinklasse og reisende',
  done: 'Fullført',
  cabin: 'FullførtKabinklasse',
  travellers: 'reisende',
  adult: 'Adult',
  Economy: 'Økonomi',
  PremiumEconomy: 'Økonomi ekstra',
  Business: 'Business',
  First: 'Første klasse',
  EconomyClass: 'Økonomiklasse',
  PremiumEconomyClass: 'Økonomi ekstra',
  BusinessClass: 'Business class',
  FirstClass: 'Første klasse',
  adults: 'Voksne',
  children: 'Barn',
  over16: '16+ år',
  to15: '0–15 år',
  childAge: 'Alder på barn',
  validAge:
    'Alderen din ved avreise må være gyldig for alderskategorien i bestillingen. Flyselskaper har restriksjoner for personer under 18 år som reiser alene.',
  ageLimits:
    'Aldersgrensene og retningslinjene for å reise med barn kan variere, så vennligst sjekk hos flyselskapet før du bestiller.',
  directOnly: 'Kun direkteflyvninger',
  search: 'Søk etter flyvninger',
  prev: 'Forrige',
  next: 'Neste',
  changeSearch: 'Nytt søk',
  cheapestMonth: 'Billigste måned',
  specificDate: 'Bestemt dato',
  flexibleDate: 'Hele måneden',
  wholeMonth: 'Vis hele måneden',
  showSpecific: 'Vis spesifikk dato',
  enterDate: 'Skriv inn en dato',
}
