export default {
  AMS: 'Amsterdam',
  BHX: 'Birmingham',
  LGW: 'London Gatwick',
  MAN: 'Manchester',
  BCN: 'Barcelona',
  BRU: 'Bruxelles',
  LPA: 'Gran Canaria',
  DKRA: 'Dakar',
  FRA: 'Frankfurt',
  DUS: 'Düsseldorf',
  CDG: 'Paris',
  MUC: 'München',
  return: 'retur',
  oneWay: 'enkeltbillet',
  topDeals: 'Dagens bedste tilbud',
  welcome:
    'Velkommen til hjemmesiden med den mest omfattende information til billige flyrejser til Gambia!',
  explain_1:
    'N.B. Dette projekt er/var et igangværende arbejde og skal kun ses som sådan. Jeg skrev det oprindeligt som en standard Laravel-applikation, men afkoblede derefter front- og back-ends, så det nu er et Vue-CLI-projekt, der drives af en Laravel API.',
  explain_2:
    'Jeg var halvvejs med at migrere frontenden fra Bootstrap til Tailwind CSS, så stylingen er nogle steder ret stiv. Da jeg har lukket ned for webskraberen, der forstærkede disse Skyscanner-resultater med mine egne, får du heller ikke opleve den største fordel ved siden. Men forhåbentlig vil det stadig give dig en smag af, hvad det handlede om!',
  readMore: 'Lees verder',
  readLess: 'Lees minder',
}
