export default {
  airlineFees: 'Flygbolagsavgifter',
  previousPage: 'Tillbaka till föregående sida',
  pricesInclude:
    'I priser ingår alltid en uppskattning av alla obligatoriska skatter och avgifter. Vissa flyg- eller resebolag tar dock extra betalt för ',
  baggage: 'bagage',
  insurance: 'försäkring',
  useOf: 'betalning med ',
  creditCards: 'kreditkort',
  other: ', eller andra tjänster.',
  findDetails:
    'Här nedan hittar du mer information om flygbolagens olika avgifter:',
}
