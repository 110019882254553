export default {
  outbound: 'Afrejse:',
  inbound: 'Hjemrejse:',
  selectDates: 'Vælg datoer',
  calendar: 'Kalender',
  chart: 'Diagram',
  valid: 'N. B. Pris gyldig',
  more: 'mere info',
  less: 'mindre info',
  noPrice: 'N. B. Vi har ikke fundet nogen priser for ',
  thisDate: 'denne dato ',
  theseDates: 'disse datoer ',
  notMean: 'indenfor de sidste 15 dage, men der kan være fly til rådighed!',
  doSearch: 'Klik på knappen for at udføre en levende søgning',
  directOnly: 'Kun direkte flyrejser',
  none: 'ingen',
  loading: 'Indlæser',
  prices: 'Prices',
  lowest: 'Laveste',
  medium: 'Medium',
  highest: 'Højeste',
  checkPrice: 'Prisen skal kontrolleres',
  notDirect: 'Kun 1+ stop',
  outboundFlights: 'Udgående flyvninger',
  inboundFlights: 'Indgående flyvninger',
  showFlights: 'Vis fly',
  from: 'fra',
  adultPrice: 'pris pr. voksen',
  min: 'Min',
  max: 'Maks',
  any: 'Nogen',
  summary: 'Opsummering:',
  day: 'dag',
  days: 'dage',
  earliest: 'Tidligste afgang',
  latest: 'Seneste tilbagevenden',
  showCheapest: 'Vis billigste',
  showingCheapest: 'Viser billigste',
  option: 'mulighed',
  options: 'muligheder',
  moreFilters: 'Flere filtre',
  hideFilters: 'Skjul filtre',
  showTips: 'Vis tips',
  hideTips: 'Skjul tips',
  estimatedOnly:
    'N. B. Anslåede laveste priser. Fundet i løbet af de sidste 15 dage.',
}
