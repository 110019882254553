<template>
  <section>
    <form method="post" name="frm">
      <input name="_token" type="hidden" />
      <input v-model="criteria.tripType" name="trip-type" type="hidden" />
      <input v-model="criteria.origin.code" name="originPlace" type="hidden" />
      <input
        v-model="criteria.destination.code"
        name="destinationPlace"
        type="hidden"
      />
      <input
        v-model="criteria.outboundDate"
        name="outboundDate"
        type="hidden"
      />
      <input v-model="criteria.inboundDate" name="inboundDate" type="hidden" />
      <input v-model="criteria.adults" name="adults" type="hidden" />
      <input v-model="criteria.children" name="children" type="hidden" />
      <input v-model="criteria.cabinClass" name="cabinClass" type="hidden" />

      <div class="flex mt-1 sm:mt-2">
        <TripTypeButton trip-type="roundTrip" @search-deals="searchDeals" />
        <TripTypeButton trip-type="one-way" @search-deals="searchDeals" />
      </div>

      <div class="bg-green-500 border px-4 pb-4 rounded-b-lg rounded-tr-lg">
        <OriginAndDestination @search-deals="searchDeals" />

        <div class="mt-3 sm:mt-6">
          <div class="sm:relative sm:flex">
            <TravelDates />

            <CabinClassAndTravellers />
          </div>
        </div>

        <div class="flex mt-6 sm:justify-end">
          <button
            class="
              flex flex-1
              justify-center
              items-center
              btn btn-yellow
              py-3
              text-center
              sm:flex-none sm:w-1/2
            "
            :disabled="disableSearchButton"
            tabindex="0"
            @click.prevent="searchFlights()"
          >
            {{ $t('searchControlsContainer.search') }}
            <InlineSvg
              aria-hidden="true"
              name="arrow-circle-right"
              classes="ml-4 w-6 h-6"
            ></InlineSvg>
          </button>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import CabinClassAndTravellers from '@/components/CabinClassAndTravellers'
import OriginAndDestination from '@/components/OriginAndDestination'
import TravelDates from '@/components/TravelDates'
import TripTypeButton from '@/components/TripTypeButton'

export default {
  components: {
    CabinClassAndTravellers,
    OriginAndDestination,
    TravelDates,
    TripTypeButton,
  },
  computed: {
    ...mapState(['flights', 'criteria']),
    ...mapGetters(['api', 'country', 'isRoundTrip', 'locale']),
    disableSearchButton() {
      return this.status === 'Searching'
    },
  },
  methods: {
    browseQuotes(anyTime = false, doBrowseQuoteCallback = false) {
      let outboundDate = anyTime ? 'anytime' : this.criteria.outboundDate
      let inboundDate = anyTime ? 'anytime' : this.criteria.inboundDate

      if (this.criteria.tripType === 'one-way') {
        inboundDate = null
      }

      if (!anyTime && this.criteria.calendarType === 'flexible') {
        outboundDate = this.criteria.outboundDate.substring(0, 7)
        if (inboundDate) {
          inboundDate = this.criteria.inboundDate.substring(0, 7)
        }
      }

      this.$axios
        .post(`${this.api}/browsequotes`, {
          anyTime: anyTime,
          tripType: this.criteria.tripType,
          originPlace: this.criteria.origin.code,
          destinationPlace: this.criteria.destination.code,
          outboundDate: outboundDate,
          inboundDate: inboundDate,
          adults: this.criteria.adults,
          children: this.criteria.children,
          cabinClass: this.criteria.cabinClass,
        })
        .then(({ data }) => {
          this.$store.commit('setBrowseQuotes', data)

          if (doBrowseQuoteCallback) {
            this.doBrowseQuotesCallback()
          } else {
            if (!anyTime) {
              this.$emit('monthDataReceived')
            }
          }

          this.flushSession(data)
        })
        .catch((error) => {
          this.$store.commit('setError', error.response)
          this.$store.commit('setStatus', 'UpdatesComplete')
        })
    },
    browseSearch() {
      this.$store.commit('setStatus', 'Searching')

      if (this.criteria.outboundDate === 'anytime') {
        this.browseQuotes(true, true)
      } else {
        this.browseQuotes()
        this.browseQuotes(true, false)
      }

      if (location.href.indexOf('flexible') < 0) {
        this.$router.push('/' + this.locale + '/flexible')
      }
    },
    flushSession(data) {
      let flushData = {
        queryId: data.queryId,
      }

      if (this.criteria.calendarType === 'specific') {
        flushData = {
          queryId: data.queryId,
          bestId: this.$store.getters.best.Id,
          cheapestId: this.$store.getters.cheapest.Id,
          fastestId: this.$store.getters.fastest.Id,
        }
      }

      this.$axios.post(`${this.api}/flush-session`, flushData)
    },
    liveSearch() {
      // let CancelToken = this.$axios.CancelToken;
      // let source = CancelToken.source();

      this.$axios
        .post(`${this.api}/polling-url`, {
          // cancelToken: source.token,
          tripType: this.criteria.tripType,
          originPlace: this.criteria.origin.code,
          destinationPlace: this.criteria.destination.code,
          outboundDate: this.criteria.outboundDate,
          inboundDate: this.criteria.inboundDate,
          adults: this.criteria.adults,
          children: this.criteria.children,
          cabinClass: this.criteria.cabinClass,
        })
        .then(({ data }) => {
          this.$store.commit('setQueryId', data.queryId)
          this.$store.commit('setFlights', data)

          if (data.Status.message !== 'UpdatesComplete') {
            window.setTimeout(this.pollResults, 500)
          }
        })
        .catch((error) => {
          // if (this.$axios.isCancel(error)) {
          //     console.log('Request canceled', error.message);
          // } else {
          // handle error
          this.$store.commit('setError', error.response)
          this.$store.commit('setStatus', 'Error')
          // }
        })

      if (location.href.indexOf('specific') < 0) {
        this.$router.push('/' + this.locale + '/specific')
      }
    },
    noFlights(data) {
      return (
        !Object.keys(data.Itineraries).length &&
        !Object.keys(this.$store.state.flights.Itineraries).length
      )
    },
    pollAgain() {
      window.setTimeout(this.pollResults, 3000)
    },
    pollResults() {
      this.$axios
        .post(`${this.api}/poll-results`, {
          queryId: this.$store.state.criteria.queryId,
        })
        .then(({ data }) => {
          if (data.Status.message === 'UpdatesPending') {
            this.$store.commit('setFlights', data)
            this.pollAgain()
          }

          if (data.Status.message === 'UpdatesComplete') {
            if (this.noFlights(data)) {
              return this.$store.commit('setStatus', 'NoResults')
            }

            this.$store.commit('setFlights', data)

            this.flushSession(data)
          }
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('setError', error.response)
          this.$store.commit('setStatus', 'Error')
          // this.$store.commit('setStatus', 'UpdatesComplete')
        })
    },
    routesSearch() {
      let outboundDate = this.criteria.outboundDate
      let inboundDate = this.criteria.inboundDate

      if (this.criteria.calendarType === 'flexible') {
        outboundDate = this.criteria.outboundDate.substring(0, 7)
        if (inboundDate) {
          inboundDate = this.criteria.inboundDate.substring(0, 7)
        }
      }

      this.$axios
        .post(`${this.api}/browseroutes`, {
          tripType: this.criteria.tripType,
          originPlace: this.criteria.origin.code,
          destinationPlace: this.criteria.destination.code,
          outboundDate: outboundDate,
          inboundDate: inboundDate,
          adults: this.criteria.adults,
          children: this.criteria.children,
          cabinClass: this.criteria.cabinClass,
        })
        .then(({ data }) => {
          this.$store.commit('setQueryId', data.queryId)
          this.$store.commit('setBrowseRoutes', data)
          this.$store.commit('setStatus', 'UpdatesComplete')

          this.$router.push('/' + this.locale + '/routes')
        })
        .catch((error) => {
          // console.log(error.response)

          this.$store.commit('setError', error.response)
          this.$store.commit('setStatus', 'Error')
        })
    },
    searchDeals() {
      this.$store.commit('clearDeals')

      this.$axios
        .post(`${this.api}/deals`, {
          airports: this.$store.state.dealsAirports,
          tripType: this.criteria.tripType,
          isBanjulOriginating: this.criteria.origin.code === 'BJL',
        })
        .then(({ data }) => {
          this.$store.commit('setDeals', data)
        })
        .catch((error) => {
          this.$store.commit('setError', error.response)
          this.$store.commit('setStatus', 'Error')
        })
    },
    searchFlights() {
      this.$scrollToTop()

      const frm = document.frm

      const validations = [
        {
          isFail: !frm.originPlace.value,
          text: 'validOrigin',
          focusId: 'origin',
        },
        {
          isFail: !frm.destinationPlace.value,
          text: 'validDestination',
          focusId: 'destination',
        },
        {
          isFail: !frm.outboundDate.value,
          text: 'validOutboundDate',
          focusId: 'outbound-date',
        },
        {
          isFail: !frm.inboundDate.value && this.isRoundTrip,
          text: 'validReturnDate',
          focusId: 'inbound-date',
        },
        {
          isFail: this.$store.state.criteria.children.some((age) => age === -1),
          text: 'validChildAges',
          focusId: 'travellers',
        },
      ]

      for (let i = 0; i < validations.length; i++) {
        if (!this.validateRule(validations[i])) {
          return
        }
      }

      let infants = 0

      this.$store.state.criteria.children.map((childAge) => {
        if (childAge < 2) {
          infants++
        }
      })

      if (infants > this.$store.state.criteria.adults) {
        this.$swal(this.$t('mixins.oops'), this.$t('mixins.oneChild'))
        document.getElementById('travellers').focus()
        return
      }

      this.$store.commit('setStatus', 'Searching')

      if (this.criteria.tripType === 'one-way') {
        this.$store.commit('setInboundDate', null)
        this.$store.commit('setInboundLatest', null)
      }

      if (
        this.criteria.origin.code.length === 2 ||
        this.criteria.destination.code.length === 2
      ) {
        return this.routesSearch()
      }

      if (this.criteria.calendarType === 'specific') {
        this.liveSearch()
      } else {
        this.browseSearch()
      }
    },
    validateRule({ isFail, text, focusId }) {
      if (!isFail) {
        return true
      }

      this.$swal({
        title: this.$t('mixins.oops'),
        text: this.$t(`mixins.${text}`),
        returnFocus: false,
      }).then(() => {
        document.getElementById(focusId).focus()
      })

      return false
    },
    validateForm() {},
  },
  // mounted () {
  //            this.routesSearch()
  //            this.browseSearch()
  //            this.liveSearch()
  //   }
}
</script>
