export default {
  filter: 'Filtra',
  sort: 'Ordina',
  checked: '',
  of: 'di',
  searching: 'Ricerca',
  providers: 'compagnie',
  results: 'risultati',
  showAll: 'Mostra tutti',
  appliedFilters: 'Abbiamo applicato i tuoi filtri.',
  combine: 'di combinazioni di volo',
}
