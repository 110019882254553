export default {
  NB: 'Observe',
  nextTip: 'Próxima dica',
  previousTip: 'Dica anterior',
  lastTip: 'Última dica',
  tip_1:
    'Esta "visão mensal" fornece uma visão geral dos preços de voos mais baratos encontrados nos últimos 15 dias. ' +
    'Os preços podem ter mudado, para obter as últimas ofertas que você precisará para realizar uma pesquisa ao vivo, ' +
    'clicando no botão ',
  button: '.',
  tip_2: 'Use a guia',
  toShow: 'para mostrar',
  restrict:
    ' ou para restringir a duração e / ou as datas de viagem de primeira partitda ou de último retorno. ',
  cycle: 'Você pode percorrer os resultados restantes usando os botões ',
  anzuzeigen: '',
  orClick: '(ou clique nas datas diretamente)',
  tip_3: 'Depois de encontrar datas adequadas, você pode clicar no botão',
  liveSearch:
    'para realizar uma pesquisa ao vivo das últimas ofertas e preços das datas escolhidas',
  letsGo: 'Ok, vamos!',
}
