export default {
  airlineFees: 'Frais de la compagnie aérienne',
  previousPage: 'Retour à la page précédente',
  pricesInclude:
    'Les prix comprennent toujours une estimation de tous les frais et taxes obligatoires mais certaines compagnies aériennes / agences de voyage appliqueront des frais additionnels pour ',
  baggage: 'les bagages',
  insurance: "l'assurance",
  useOf: "l'utilisation de ",
  creditCards: 'cartes de crédit',
  other: 'ou autres services',
  findDetails:
    'Vous pouvez trouver les détails des frais de chaque compagnie aérienne ci-dessous :',
}
