export default {
  to: 'till',
  of: 'av',
  results: 'resultat',
  flightDetails: 'Uppgifter',
  show: 'Visa',
  more: 'fler',
  fewer: 'färre',
  ofResults: 'resultat',
  anzeigen: '',
}
