export default {
  filter: 'Filtrera',
  sort: 'Sortera',
  checked: 'Kontrollerat ',
  of: 'av',
  searching: 'Söker',
  providers: 'leverantörer',
  results: 'resultat',
  showAll: 'Visa allt',
  appliedFilters: 'Vi har tillämpat dina filter.',
  combine: 'flygkombinationer',
}
