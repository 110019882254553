<template v-show="currency">
  <div class="classValue" :title="title">{{ totalFormatted }}</div>
</template>

<script>
export default {
  props: {
    classValue: {
      type: String,
      default: '',
    },
    currency: {
      type: Object,
      default: () => {
        return {
          decimal_digits: 2,
          decimal_separator: '.',
          id: 'USD',
          rounding_coefficient: 0,
          space_between_amount_and_symbol: false,
          symbol: '$',
          symbol_on_left: true,
          thousands_separator: ',',
          usd: 1,
        }
      },
      required: true,
    },
    decimals: {
      type: Number,
      default: 2,
    },
    divideByThousand: {
      type: Boolean,
      default: false,
    },
    dollarAmount: {
      type: Number,
      default: 0,
    },
    hideLargeSymbol: Boolean,
    noSpace: Boolean,
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    currencyFormat() {
      if (this.currency.symbol_on_left) {
        if (this.currency.space_between_amount_and_symbol) {
          if (this.showK) {
            return this.showLargeSymbol ? '%s %vk' : '%vk'
          }
          return this.showLargeSymbol ? '%s %v' : '%v'
        } else {
          if (this.showK) {
            return this.showLargeSymbol ? '%s%vk' : '%vk'
          }
          return this.showLargeSymbol ? '%s%v' : '%v'
        }
      } else {
        if (this.currency.space_between_amount_and_symbol) {
          if (this.showK) {
            return this.showLargeSymbol ? '%vk %s' : '%vk'
          }
          return this.showLargeSymbol ? '%v %s' : '%v'
        } else {
          if (this.showK) {
            return this.showLargeSymbol ? '%vk%s' : '%vk'
          }
          return this.showLargeSymbol ? '%v%s' : '%v'
        }
      }
    },
    decimalsNum() {
      if (isNaN(this.decimals)) {
        return this.currency.decimal_digits
      }

      return this.decimals
    },
    localAmount() {
      return this.dollarAmount * this.currency.usd
    },
    showLargeSymbol() {
      if (!this.hideLargeSymbol) {
        return true
      }

      return !(this.currency.symbol.length > 1)
    },
    showK() {
      return this.localAmount > 1000 && this.divideByThousand
    },
    totalFormatted() {
      let returnValue = this.$accounting.formatMoney(
        this.showK ? this.localAmount / 1000 : this.localAmount,
        {
          symbol: this.currency.symbol,
          precision: this.decimalsNum,
          thousand:
            this.currency.thousands_separator === ''
              ? ' '
              : this.currency.thousands_separator,
          decimal: this.currency.decimal_separator,
          format: this.currencyFormat,
        },
      )

      if (this.noSpace) {
        returnValue = returnValue.replace(/ /g, '')
      }

      return returnValue
    },
  },
}
</script>

<style lang="scss">
.bold {
  font-weight: bold;
}
.large-bold {
  font-size: 1.3em;
  font-weight: bold;
  white-space: nowrap;
}
.sm {
  display: block;
  font-size: 0.9em;
}
.twoSingles {
  font-size: 0.8em;
}

@media screen and (min-width: 360px) {
  .sm {
    display: block;
    font-size: 1em;
  }
}
</style>
