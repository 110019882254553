export default {
  confirm: 'Confirme a Senha',
  email: 'Endereço de e-mail',
  name: 'Nome',
  password: 'Senha',
  register: 'Registe-se',
  registerText:
    'O registro demora apenas um minuto e permite que você receba notificações por e-mail de preços de voos. Você pode cancelar a qualquer momento....',
  registerSuccess: 'Você se registrou com sucesso.',
  registerSubmitting: 'Por favor, espere...',
  update: 'Atualizar',
  updateSubmitting: 'Atualizando...',
  delete: 'Apagar',
  deleteSubmitting: 'Apagando',
  forgot: 'Esqueceu a sua senha?',
  login: 'Login',
  loginSubmitting: 'Efetuando login...',
  remember: 'Manter-me ligado(a)',
  tryAgain: 'Por favor, tente novamente...',
  serverConnection: 'Não é possível se conectar ao servidor',
  failed: 'Essas credenciais não correspondem aos nossos registros.',
  throttle: 'Muitas tentativas de login. Tente novamente em :seconds segundos.',
  passwordLength:
    'As senhas devem ter pelo menos seis caracteres e combinar a confirmação.',
  reset: 'Sua senha foi alterada!',
  sent: 'Enviamos seu link de redefinição de senha por e-mail!',
  token: 'Este token de redefinição de senha é inválido.',
  user: 'Não encontramos um usuário com esse endereço de e-mail.',
  contactUs: 'Contact us',
  contactText:
    'Have you spotted an error? Is there anything you would like us to improve?',
  drop: "Drop us an email and we'll do our best to help!",
  message: 'Your message',
  contact: 'Send message',
  contactSubmitting: 'Sending message',
}
