export default {
  filter: 'Filtrar',
  sort: 'Ordenar',
  checked: '',
  of: 'de',
  searching: 'Buscando',
  providers: 'proveedores',
  results: 'resultados',
  collating: 'Recopilación de datos',
  topTen: 'Mostrando los primeros 10 resultados',
  showAll: 'mostrar todos',
  appliedFilters: 'Hemos aplicado tus filtros.',
  combine: 'combinaciones de vuelos',
}
