export default {
  outbound: 'Salida:',
  inbound: 'Regreso:',
  selectDates: 'Seleccionar fechas',
  calendar: 'Calendario',
  chart: 'Gráfico',
  valid: 'N.B. Precio valido',
  more: 'más información',
  less: 'menos información',
  noPrice: 'N.B. No hemos encontrado precios para ',
  thisDate: 'esta fecha ',
  theseDates: 'estas fechas ',
  notMean: 'en los últimos 15 días, pero los vuelos pueden estar disponibles.',
  doSearch: 'Haga clic en el botón para realizar una búsqueda en vivo',
  directOnly: 'Solo vuelos directos',
  none: 'ningunos',
  loading: 'Cargando',
  prices: 'Precios',
  lowest: 'El más bajo',
  medium: 'Precio medio',
  highest: 'El más alto',
  checkPrice: 'Se debe consultar el precio',
  notDirect: 'Solo con 1 escala o más',
  outboundFlights: 'Vuelos de ida',
  inboundFlights: 'Vuelos de vuelta',
  showFlights: 'Mostrar vuelos',
  from: 'desde',
  adultPrice: 'precio por adulto',
  min: 'Min',
  max: 'Max',
  any: 'Todos',
  summary: 'Somario:',
  day: 'día',
  days: 'días',
  earliest: 'Primera salida',
  latest: 'Último retorno',
  showCheapest: 'Seleccione el más barato',
  showingCheapest: 'Se muestra el precio más bajo',
  option: 'opción',
  options: 'opciones',
  moreFilters: 'Mostrar filtros',
  hideFilters: 'Ocultar filtros',
  showTips: 'Mostrar consejos',
  hideTips: 'Ocultar consejos',
  estimatedOnly:
    'N.B. Solo precios estimados más bajos encontrados durante los últimos 15 días.',
}
