export default {
  connection: "Correspondence à l'aéroport",
  longWait: 'Longue attente',
  h: 'h',
  min: '',
  change: "Changer d'aéroport",
  in: 'à',
  from: 'de',
  to: 'à',
  stayOnPlane: "Rester à bord de l'avion",
  unknown: 'Aéroport de connexion (inconnu)',
}
