export default {
  outbound: 'Partida:',
  inbound: 'Volta:',
  selectDates: 'Selecione datas',
  calendar: 'Calendário',
  chart: 'Gráfico',
  valid: 'N.B. Preço válido',
  more: 'mais informações',
  less: 'menos informações',
  noPrice: 'N.B. Não encontramos preços para ',
  thisDate: 'esta data ',
  theseDates: 'essas datas ',
  notMean: 'nos últimos 15 dias, mas os vôos podem estar disponíveis!',
  doSearch: 'Clique no botão para executar uma pesquisa ao vivo',
  directOnly: 'Apenas voos diretos',
  none: 'nenhum',
  loading: 'A carregar',
  prices: 'Preços',
  lowest: 'Mais baixo',
  medium: 'Médio',
  highest: 'Mais alto',
  checkPrice: 'O preço tem de ser verificado',
  notDirect: 'Apenas 1 ou mais escalas',
  outboundFlights: 'Vôos de saída',
  inboundFlights: 'Vôos de entrada',
  showFlights: 'Mostrar voos',
  from: 'desde',
  adultPrice: 'preço por adulto',
  min: 'Mín',
  max: 'Máx',
  any: 'Qualquer',
  summary: 'Resumo:',
  day: 'dia',
  days: 'dias',
  earliest: 'A primeira partitda',
  latest: 'Último retorno',
  showCheapest: 'Mostrar mais barato',
  showingCheapest: 'Mostrando mais barato',
  option: 'opção',
  options: 'opções',
  moreFilters: 'Mais filtros',
  hideFilters: 'Ocultar filtros',
  showTips: 'Mostre as dicas',
  hideTips: 'Ocultar dicas',
  estimatedOnly:
    'N.B. Apenas os preços mais baixos estimados. Obtidos nos últimos 15 dias.',
}
