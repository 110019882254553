<template>
  <div
    class="
      mt-4
      absolute
      rounded
      p-2
      bg-gray-200
      border border-gray-300
      shadow-md
      z-10
    "
    @keydown.esc="$emit('close-cabin-class')"
  >
    <div class="flex flex-col justify-between">
      <label class="form-label" for="cabin-class">
        {{ $t('searchControlsContainer.cabin') }}
      </label>
      <div class="flex items-center relative">
        <select
          id="cabin-class"
          ref="cabinClass"
          v-model="criteria.cabinClass"
          class="flex-1 w-full text-input max-w-xl bg-white appearance-none"
          tabindex="0"
          @change="selectCabinClass"
        >
          <option
            v-for="(cabinClassName, cabinClassValue) in cabinClasses"
            :key="cabinClassValue"
            :value="cabinClassValue"
          >
            {{ $t('searchControlsContainer')[cabinClassValue] }}
          </option>
        </select>
        <div
          class="
            pointer-events-none
            absolute
            inset-y-0
            right-0
            flex
            items-center
            px-2
            text-gray-700
          "
        >
          <InlineSvg name="chevron-down" classes="w-4 h-4"></InlineSvg>
        </div>
      </div>
    </div>

    <SelectTravellers
      traveller-type="adult"
      :description="$t('searchControlsContainer.over16')"
      :is-max-disabled="isMaxDisabledAdults"
      :is-min-disabled="isMinDisabledAdults"
      :name="$t('searchControlsContainer.adults')"
      :value="criteria.adults"
      @decrease="$store.commit('decAdults')"
      @increase="$store.commit('addAdults')"
    />

    <SelectTravellers
      traveller-type="child"
      :description="$t('searchControlsContainer.to15')"
      :is-max-disabled="isMaxDisabledChildren"
      :is-min-disabled="isMinDisabledChildren"
      :name="$t('searchControlsContainer.children')"
      :value="criteria.children.length"
      @decrease="$store.commit('decChild')"
      @increase="$store.commit('addChild')"
    />

    <div
      v-for="(_, index) in criteria.children"
      :key="index"
      class="mt-4 flex items-center text-sm relative"
    >
      <label class="form-label flex-1" :for="`childAge${index}`">
        {{ $t('searchControlsContainer.childAge') }} {{ index + 1 }}
      </label>
      <select
        :id="`childAge${index}`"
        v-model="criteria.children[index]"
        class="flex-1 ml-2 w-full text-input max-w-xl bg-white appearance-none"
        @change="selectAge"
      >
        <option value="-1">-</option>
        <option value="0">0</option>
        <option v-for="age in 15" :key="age" :value="age">{{ age }}</option>
      </select>
      <div
        class="
          pointer-events-none
          absolute
          inset-y-0
          right-0
          flex
          items-center
          px-2
          text-gray-700
        "
      >
        <InlineSvg name="chevron-down" classes="w-4 h-4"></InlineSvg>
      </div>
    </div>

    <div class="text-sm">
      <p class="mt-4">N.B. {{ $t('searchControlsContainer.validAge') }}</p>
      <p class="mt-4">{{ $t('searchControlsContainer.ageLimits') }}</p>
    </div>

    <div class="flex justify-end w-full">
      <button
        class="
          bg-white
          rounded-full
          py-1
          px-2
          text-sm
          tracking-wide
          border
          focus:outline-none focus:shadow-outline
          hover:bg-gray-200 hover:border-gray-300
          active:bg-gray-300
          focus:border-gray-400
        "
        type="button"
        @click.prevent="$emit('close-cabin-class')"
      >
        {{ $t('searchControlsContainer.done') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SelectTravellers from '@/components/SelectTravellers'

export default {
  name: 'CabinClass',
  components: { SelectTravellers },
  computed: {
    ...mapState(['criteria']),
    cabinClasses() {
      return {
        Economy: this.$t('searchControlsContainer.Economy'),
        PremiumEconomy: this.$t('searchControlsContainer.PremiumEconomy'),
        Business: this.$t('searchControlsContainer.Business'),
        First: this.$t('searchControlsContainer.First'),
      }
    },
    isMaxDisabledAdults() {
      return this.criteria.adults > 7
    },
    isMaxDisabledChildren() {
      return this.criteria.children.length > 7
    },
    isMinDisabledAdults() {
      return this.criteria.adults < 2
    },
    isMinDisabledChildren() {
      return this.criteria.children.length < 1
    },
  },
  methods: {
    selectAge(e) {
      const age = parseInt(e.target.value)
      const index = parseInt(e.target.id.replace('childAge', ''))

      this.$store.commit('setChildAge', {
        age,
        index,
      })
    },
    selectCabinClass(e) {
      this.$store.commit('selectCabinClass', e.target.value)
    },
  },
}
</script>
