export default {
  to: 'para',
  of: 'de',
  results: 'resultados',
  flightDetails: 'Detalhes',
  show: 'Mostrar',
  more: 'mais',
  fewer: 'menos',
  ofResults: 'resultados',
  anzeigen: '',
}
