export default {
  loading: 'A carregar',
  notAvailable: 'Desculpe, esta tarifa já não está disponível',
  soldOut: 'O bilhete pode ter esgotado.',
  refresh: 'Atualizar resultados',
  check: 'Verifique o seu itinerário',
  timesLocal: 'todas as horas são locais',
  departingFrom: 'Está a partir de',
  arrivingat: 'Está a chegar a',
  returningTo: 'mas regressa para',
  returningFrom: 'mas regressa de',
  zuruck: '',
  bookTicket: 'Reserve o seu bilhete',
  readBefore: 'Leia antes de fazer a reserva',
  pricesShown:
    'Os preços exibidos incluem sempre uma estimativa das taxas e encargos obrigatórios, mas lembre-se sempre de',
  checkAll:
    'verificar TODA a informação relativa aos bilhetes, preços finais e termos e condições',
  beforeBook: 'no site de reservas antes de proceder às mesmas.',
  extraFees: 'Consulte as taxas extras',
  someAirlines:
    'Algumas companhias aéreas / agentes cobram um valor extra para',
  baggage: 'bagagem',
  insurance: 'seguro',
  orUseOf: 'ou utilização de',
  creditCards: 'cartões de crédito',
  view: 'Ver',
  airlineFees: 'taxas do operador',
  an: '.',
  checkTC:
    'Verificar termos e condições para viajantes entre os 12 e os 16 anos',
  restrictions:
    'Podem colocar-se restrições a passageiros menores que viajem sozinhos.',
  adults: 'adultos',
  adult: 'adulto',
  children: 'crianças',
  child: 'criança',
  infants: 'bebés',
  infant: 'bebé',
  combine: 'Combine 2 voos únicos',
}
