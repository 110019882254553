export default {
  yourAccount: 'Your account',
  profile: 'profile',
  Profile: 'Profile',
  savedSearch: 'price alert',
  savedSearches: 'Price alerts',
  congratulations: 'Congratulations!',
  updatedProfile: 'You have successfully updated your profile',
  deletedProfile: 'You have successfully deleted your profile',
  loggedInAs: 'Hi, you are logged in as',
  loggedInAsGerman: '.',
  editYour: 'You may edit your',
  editYourGerman: '',
  orSetup: ', or set up a',
  orSetupGerman: '',
  weWill:
    'and we will email you the latest flight prices you are interested in.',
  noSavedSearches: 'You do not currently have any price alerts.',
  toSetUp: 'To set one up, simply make a search from the',
  toSetUpGerman: '',
  searchPage: 'search page',
  clickSave: ' and then click on the',
  saveButton: 'Get price alerts',
  resultsPage: 'button on the results page.',
  youHave: 'You have the following price alerts',
  thankYou: 'Thank you!',
  sentSuccess: 'Your message has been sent successfully',
}
