<template>
  <div class="flex flex-col mt-1 sm:mt-2 sm:flex-row sm:items-start">
    <div class="flex-1 relative">
      <label class="form-label text-green-100" for="origin">
        {{ $t('searchControlsContainer.from') }}
      </label>
      <TypeAhead
        id="origin"
        ref="origin"
        :value="originDescription"
        :base-url="`${api}/places/autosuggest/`"
        input-class="text-input sm:h-12 sm:border-r sm:rounded-r-none"
        :nearest-airports="nearestAirports"
        :placeholder="$t('searchControlsContainer.placeholder')"
        :uses-miles="usesMiles"
        @set-place="setOrigin"
      />
    </div>

    <div class="flex justify-center sm:flex-col sm:-mt-2">
      <label class="form-label text-green-100 sm:mt-5"></label>
      <button
        class="
          px-3
          py-1
          mt-3
          -mb-3
          bg-white
          rounded
          hover:bg-gray-200
          focus:outline-none focus:shadow-outline
          sm:h-12 sm:border-b sm:-mb-0 sm:rounded-none
        "
        type="button"
        tabindex="0"
        :title="$t('searchControlsContainer.swap')"
        @click.prevent="togglePlaces"
      >
        <InlineSvg
          name="arrows-alt-v"
          classes="w-4 h-4 text-gray-800 sm:hidden"
        ></InlineSvg>
        <InlineSvg
          name="arrows-alt-h"
          classes="w-6 h-6 text-gray-800 hidden sm:block"
        ></InlineSvg>
      </button>
    </div>

    <div class="flex-1">
      <label class="form-label text-green-100" for="destination">
        {{ $t('searchControlsContainer.To') }}
      </label>
      <TypeAhead
        id="destination"
        ref="destination"
        :value="destinationDescription"
        :base-url="`${api}/places/autosuggest/`"
        input-class="text-input sm:h-12 sm:rounded-l-none sm:border-l"
        :nearest-airports="nearestAirports"
        :placeholder="$t('searchControlsContainer.placeholder')"
        :uses-miles="usesMiles"
        @set-place="setDestination"
      />
    </div>
  </div>
</template>

<script>
import TypeAhead from './TypeAhead'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'OriginAndDestination',
  components: { TypeAhead },
  data() {
    return {
      inboundClassActive:
        'text-input sm:h-12 flex-1 focus:outline-none focus:shadow-outline',
    }
  },
  computed: {
    ...mapState(['criteria', 'nearestAirports']),
    ...mapGetters(['api', 'country']),
    destinationDescription() {
      const destination = this.criteria.destination

      if (!destination.code) {
        return ''
      }

      const code =
        destination.code.length === 3 ? destination.code : this.$t('place.all')

      return (destination.name + ' (' + code + ')').replace(/\s\(\)/g, '')
    },
    inboundClass() {
      return this.criteria.tripType === 'roundTrip'
        ? this.inboundClassActive
        : this.inboundClassActive + ' text-gray-500'
    },
    originDescription() {
      let origin = this.criteria.origin

      if (!origin.code) {
        return ''
      }

      const code = origin.code.length === 3 ? origin.code : this.$t('place.all')

      return (origin.name + ' (' + code + ')').replace(/\s\(\)/g, '')
    },
    usesMiles() {
      return this.country ? !!this.country.uses_miles : true
    },
  },
  methods: {
    setDestination(place) {
      const name = place[place.type + '_name'].replace(/<\/?span[^>]*>/g, '')

      this.$store.commit('setAirportDestination', {
        name,
        code: place.id,
      })

      this.$refs.destination.val = this.destinationDescription
    },
    setOrigin(place) {
      const name = place[place.type + '_name'].replace(/<\/?span[^>]*>/g, '')

      this.$store.commit('setAirportOrigin', {
        name,
        code: place.id,
      })

      this.$refs.origin.val = this.originDescription
    },
    togglePlaces() {
      const origin = {
        ...this.criteria.origin,
        placeType: 'destination',
      }
      const destination = {
        ...this.criteria.destination,
        placeType: 'origin',
      }

      this.$store.commit('setPlace', origin)
      this.$store.commit('setPlace', destination)

      this.$store.commit('setStatus', 'Initial')

      this.$emit('search-deals')
    },
  },
}
</script>
