<template>
  <li
    :aria-label="item.airport_name"
    class="
      flex
      w-full
      p-4
      border-b border-gray-300
      cursor-pointer
      group
      hover:bg-blue-200 hover:text-blue-800
    "
    role="option"
    @click="setValue"
    @mousedown="setValue"
  >
    <svg
      class="mt-1 w-4 h-4 mr-2 align-text-bottom fill-current"
      :class="{ 'ml-6': item.inset }"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        d="M343.22 256.76c-91.78 91.77-271 227.95-293.45 205.51-22.48-22.49 113.7-201.71 205.48-293.49C347 77.01 433.18 1.84 471.66 40.31c40.7 40.69-36.68 124.66-128.44 216.45M80.11 350.33c-10.27 15.48-23.25 36.8-30.1 49.46L4.1 362.46a10.59 10.59 0 01-.82-15.96l3.22-3.2c4.64-4.66 13.44-6.57 19.58-4.26l54.04 11.29zm32.13 111.65c12.65-6.81 34.68-20.53 50.2-30.8l10.55 54.77c2.3 6.12.4 14.93-4.25 19.58l-3.21 3.23a10.56 10.56 0 01-15.95-.84l-37.34-45.94zM284.68 94.81c2.52.96 4.58 2.3 6.33 3.84-21.6 19.46-42.84 40.68-62.83 60.66a914.28 914.28 0 00-26.02 27.24l-53.12-44.05-22.78 24.25a5.18 5.18 0 01-7.3.21 5.13 5.13 0 01-.24-7.27l22.37-23.77L88.7 92.5 68.26 114a5.18 5.18 0 01-7.5-7.13l19.97-20.98L36.82 47.4c-9.9-8.04-10.61-21.99-1.61-31l6.27-6.27C50.5 1.13 67.64-2.58 79.56 1.9l205.12 92.9m225.4 337.66c4.48 11.91.77 29.05-8.23 38.06l-6.28 6.29c-9 8.99-22.95 8.27-31-1.61l-44.73-51.5-22.24 21.8a5.13 5.13 0 01-7.3-.06c-2-2.03-1.96-5.3.06-7.3l22.86-22.42-44.64-53.83L346 383.88c-1 .99-2.31 1.47-3.6 1.47a5.15 5.15 0 01-3.6-8.83l23.17-22.61-36.52-44.05c9.4-8.67 18.6-17.39 27.23-26.03C373.5 263 394.5 242 413.48 221.2c1.47 1.7 2.77 3.7 3.68 6.13l92.92 205.13"
      />
    </svg>

    <!-- eslint-disable vue/no-v-html -->
    <div>
      <p class="mt-0" v-html="`${item.airport_name} (${itemId})`"></p>
      <p class="mt-2 text-xs tracking-wider" v-html="placeDescription"></p>
    </div>
    <!--eslint-enable-->
  </li>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    usesMiles: {
      type: Boolean,
    },
  },
  computed: {
    itemId() {
      return this.item.id.length === 8
        ? this.item.id.slice(-3)
        : this.item.id_highlighted
    },
    distance() {
      const nearbyCityDistance = this.item.nearby_city_distance

      if (this.usesMiles) {
        return Math.round(nearbyCityDistance)
      }

      return Math.round(nearbyCityDistance * 1.60934)
    },
    units() {
      return this.usesMiles ? 'miles' : 'km'
    },
    placeDescription() {
      if (!this.item.nearby_city_name) {
        return `${this.item.city_name}, ${this.item.country_name}`
      }

      const from = this.$t('connection.from')

      if (this.item.region_name) {
        return `${this.item.country_name}, ${this.item.region_name} <em>(${this.distance} ${this.units} ${from} ${this.item.nearby_city_name}, ${this.item.region_name})</em>`
      }

      return `${this.item.country_name} <em>(${this.distance} ${this.units} ${from} ${this.item.nearby_city_name})</em>`
    },
  },
  methods: {
    setValue() {
      this.$emit('hit', this.item)
    },
  },
}
</script>
