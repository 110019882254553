export default {
  NB: 'Please note',
  nextTip: 'Next tip',
  previousTip: 'Previous tip',
  lastTip: 'Last tip',
  tip_1:
    "This 'monthly view' provides an overview of the cheapest flight prices found within the last 15 days. " +
    'Prices may have changed, so to get the latest offers you will need to perform a live search by clicking the ',
  button: ' button.',
  tip_2: 'Use the',
  toShow: 'tab to show',
  restrict: ' or to restrict duration and/or earliest/latest travel dates.',
  cycle: 'You can cycle through the remaining results by using the ',
  anzuzeigen: '',
  orClick: 'buttons (or click on the dates directly)',
  tip_3: 'Once you have found suitable dates, you can click on the',
  liveSearch:
    'button to perform a live search of the latest offers and prices for your chosen dates',
  letsGo: "OK, let's go!",
}
