export default {
  getPriceAlerts: 'Recibir Alertas de precio',
  airlines: 'Aerolíneas',
  combination: 'Varias aerolíneas',
  airports: 'Aeropuertos',
  stops: 'Escalas',
  direct: 'Directo',
  oneStop: '1 escala',
  twoPlusStops: '2+ escalas',
  none: 'Ninguna',
  selectAll: 'Seleccionar todo',
  clearAll: 'Borrar todo',
  flyOut: 'Usa los',
  flyOutGerman: ' para los vuelos de ida y vuelta',
  sameAirports: 'mismos aeropuertos',
  no: 'No hace falta',
  changeAirport: 'cambiar de aeropuerto',
  duringConnections: 'durante las escalas',
  departFrom: 'Sale de',
  arriveAt: 'Llega a',
  journeyDuration: 'Duración del vuelo',
  hours: 'horas',
  departureTimes: 'Horas de salida',
  arrivalTimes: 'Horas de llegada',
  outbound: 'Ida',
  inbound: 'Regreso',
}
