export default {
  getPriceAlerts: 'Få prisvarsler',
  airlines: 'Flyselskaper',
  combination: 'Kombinasjoner',
  airports: 'Airports',
  stops: 'Stopp',
  direct: 'Direkte',
  oneStop: '1 omstigning',
  twoPlusStops: '2+ overganger',
  none: 'Ingen',
  selectAll: 'Velg alle',
  clearAll: 'Fjern alle',
  flyOut: 'Flyv ut og tilbake med',
  flyOutGerman: '',
  sameAirports: 'samme flyplasser',
  no: 'Ingen',
  changeAirport: 'endring av flyplass',
  duringConnections: 'under tilkoblinger',
  departFrom: 'Avreise fra',
  arriveAt: 'Ankomme',
  journeyDuration: 'Reiselengde',
  hours: 'timer',
  departureTimes: 'Avgangstider',
  arrivalTimes: 'Ankomsttider',
  outbound: 'Utreise',
  inbound: 'Retur',
  combinations: 'Kombinasjoner',
}
