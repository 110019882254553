export default {
  connection: 'Overstappen op vliegveld',
  longWait: 'Lange wachttijd',
  h: 'u.',
  min: '',
  change: 'Luchthaven wijzigen',
  in: 'in',
  from: 'van',
  to: 'naar',
  stayOnPlane: 'Blijf aan boord van het vliegtuig',
  unknown: 'Verbindingsluchthaven (onbekend)',
}
