import { mapState, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapState(['criteria', 'user']),
    ...mapGetters([
      'api',
      'isRoundTrip',
      'country',
      'currency',
      'language',
      'locale',
    ]),
    destination() {
      return this.criteria.destination
    },
    destinationDescription: {
      get() {
        if (!this.destination.code) {
          return
        }
        const code =
          this.destination.code.length === 3
            ? this.destination.code
            : this.$t('place.all')
        return (this.destination.name + ' (' + code + ')').replace(
          /\s\(\)/g,
          '',
        )
      },
      set() {},
    },
    inboundDate() {
      let date = this.criteria.inboundDate

      if (date === 'anytime') {
        return this.$t('searchControlsContainer.cheapestMonth')
      } else {
        date = this.$moment(date)
        return this.criteria.calendarType === 'specific'
          ? date.format('ddd D MMM')
          : date.format('MMM YYYY')
      }
    },
    origin() {
      return this.criteria.origin
    },
    originDescription: {
      get() {
        if (!this.origin.code) {
          return
        }
        const code =
          this.origin.code.length === 3
            ? this.origin.code
            : this.$t('place.all')
        return (this.origin.name + ' (' + code + ')').replace(/\s\(\)/g, '')
      },
      set() {
        // console.log(text.substr(-4, 3))
        // const code = text.substr(3, -4)
        // console.log(code)
        // const code = this.origin.code.length === 3 ? this.origin.code : this.$t('place.all')
        // return (this.origin.name + ' (' + code + ')').replace(/\s\(\)/g, '')
      },
    },
    outboundDate() {
      let date = this.criteria.outboundDate

      if (date === 'anytime') {
        return this.$t('searchControlsContainer.cheapestMonth')
      } else {
        date = this.$moment(date)
        return this.criteria.calendarType === 'specific'
          ? date.format('ddd D MMM')
          : date.format('MMM YYYY')
      }
    },
  },
  methods: {
    browseSearch() {
      this.$store.commit('setStatus', 'Searching')

      if (this.criteria.outboundDate === 'anytime') {
        this.browseQuotes(true, true)
      } else {
        this.browseQuotes()
        this.browseQuotes(true, false)
      }

      if (location.href.indexOf('flexible') < 0) {
        this.$router.push('/' + this.locale + '/flexible')
      }
    },
    browseQuotes(anyTime = false, doBrowseQuoteCallback = false) {
      let outboundDate = anyTime ? 'anytime' : this.criteria.outboundDate
      let inboundDate = anyTime ? 'anytime' : this.criteria.inboundDate

      if (this.criteria.tripType === 'one-way') {
        inboundDate = null
      }

      if (!anyTime && this.criteria.calendarType === 'flexible') {
        outboundDate = this.criteria.outboundDate.substring(0, 7)
        if (inboundDate) {
          inboundDate = this.criteria.inboundDate.substring(0, 7)
        }
      }

      this.$axios
        .post(`${this.api}/browsequotes`, {
          anyTime: anyTime,
          tripType: this.criteria.tripType,
          originPlace: this.criteria.origin.code,
          destinationPlace: this.criteria.destination.code,
          outboundDate: outboundDate,
          inboundDate: inboundDate,
          adults: this.criteria.adults,
          children: this.criteria.children,
          cabinClass: this.criteria.cabinClass,
          countryId: this.country.id,
          currencyId: this.currency.id,
          locale: this.language.id,
        })
        .then(({ data }) => {
          this.$store.commit('setBrowseQuotes', data)

          if (doBrowseQuoteCallback) {
            this.doBrowseQuotesCallback()
          } else {
            if (!anyTime) {
              this.$emit('monthDataReceived')
            }
          }

          this.flushSession(data)
        })
        .catch((error) => {
          this.$store.commit('setError', error.response)
          this.$store.commit('setStatus', 'UpdatesComplete')
        })
    },
    changeOutboundDate(plusOrMinusOne) {
      if (this.criteria.outboundDate === 'anytime') {
        return
      }

      const dateUnit =
        this.criteria.calendarType === 'specific' ? 'day' : 'month'
      const outboundDate = this.$moment(this.criteria.outboundDate)
        .add(plusOrMinusOne, dateUnit)
        .format('YYYY-MM-DD')

      this.$store.commit('setOutboundDate', outboundDate)

      this.updateInboundDate()
      this.searchFlights()
    },
    changeInboundDate(plusOrMinusOne) {
      if (this.criteria.outboundDate === 'anytime') {
        return
      }

      const dateUnit =
        this.criteria.calendarType === 'specific' ? 'day' : 'month'
      const inboundDate = this.$moment(this.criteria.inboundDate)
        .add(plusOrMinusOne, dateUnit)
        .format('YYYY-MM-DD')

      this.$store.commit('setInboundDate', inboundDate)

      this.updateOutboundDate()
      this.searchFlights()
    },
    doBrowseQuotesCallback() {
      const cheapestDirect = this.$store.state.browse.cheapestAnyTime.indirect
      const cheapestIndirect = this.$store.state.browse.cheapestAnyTime.direct
      const directOrIndirect =
        cheapestDirect <= cheapestIndirect ? 'direct' : 'indirect'

      this.$store.commit(
        'setOutboundDate',
        this.$store.state.browse.cheapestAnyTime[directOrIndirect][0]
          .outboundDate,
      )
      if (this.$store.state.criteria.tripType === 'roundTrip') {
        this.$store.commit(
          'setInboundDate',
          this.$store.state.browse.cheapestAnyTime[directOrIndirect][0]
            .inboundDate,
        )
      }

      this.browseQuotes()
    },
    flushSession(data) {
      let flushData = {
        queryId: data.queryId,
      }

      if (this.criteria.calendarType === 'specific') {
        flushData = {
          queryId: data.queryId,
          bestId: this.$store.getters.best.Id,
          cheapestId: this.$store.getters.cheapest.Id,
          fastestId: this.$store.getters.fastest.Id,
        }
      }

      this.$axios.post(`${this.api}/flush-session`, flushData)
    },
    liveSearch() {
      // let CancelToken = this.$axios.CancelToken;
      // let source = CancelToken.source();

      this.$axios
        .post(`${this.api}/polling-url`, {
          // cancelToken: source.token,
          tripType: this.criteria.tripType,
          originPlace: this.criteria.origin.code,
          destinationPlace: this.criteria.destination.code,
          outboundDate: this.criteria.outboundDate,
          inboundDate: this.criteria.inboundDate,
          adults: this.criteria.adults,
          children: this.criteria.children,
          cabinClass: this.criteria.cabinClass,
          countryId: this.country.id,
          currencyId: this.currency.id,
          locale: this.language.id,
        })
        .then(({ data }) => {
          this.$store.commit('setQueryId', data.queryId)
          this.$store.commit('setFlights', data)

          if (data.Status.message !== 'UpdatesComplete') {
            window.setTimeout(this.pollResults, 500)
          }
        })
        .catch((error) => {
          // if (this.$axios.isCancel(error)) {
          //     console.log('Request canceled', error.message);
          // } else {
          // handle error
          this.$store.commit('setError', error.response)
          this.$store.commit('setStatus', 'Error')
          // }
        })

      if (location.href.indexOf('specific') < 0) {
        this.$router.push('/' + this.locale + '/specific')
      }
    },
    noFlights(data) {
      return (
        !Object.keys(data.Itineraries).length &&
        !Object.keys(this.$store.state.flights.Itineraries).length
      )
    },
    pollAgain() {
      window.setTimeout(this.pollResults, 3000)
    },
    pollResults() {
      this.$axios
        .post(`${this.api}/poll-results`, {
          queryId: this.$store.state.criteria.queryId,
        })
        .then(({ data }) => {
          if (data.Status.message === 'UpdatesPending') {
            this.$store.commit('setFlights', data)
            this.pollAgain()
          }

          if (data.Status.message === 'UpdatesComplete') {
            if (this.noFlights(data)) {
              return this.$store.commit('setStatus', 'NoResults')
            }

            this.$store.commit('setFlights', data)

            this.flushSession(data)
          }
        })
        .catch((error) => {
          this.$store.commit('setError', error.response)
          this.$store.commit('setStatus', 'Error')
          // this.$store.commit('setStatus', 'UpdatesComplete')
        })
    },
    routesSearch() {
      let outboundDate = this.criteria.outboundDate
      let inboundDate = this.criteria.inboundDate

      if (this.criteria.calendarType === 'flexible') {
        outboundDate = this.criteria.outboundDate.substring(0, 7)
        if (inboundDate) {
          inboundDate = this.criteria.inboundDate.substring(0, 7)
        }
      }

      this.$axios
        .post(`${this.api}/browseroutes`, {
          tripType: this.criteria.tripType,
          originPlace: this.criteria.origin.code,
          destinationPlace: this.criteria.destination.code,
          outboundDate: outboundDate,
          inboundDate: inboundDate,
          adults: this.criteria.adults,
          children: this.criteria.children,
          cabinClass: this.criteria.cabinClass,
        })
        .then(({ data }) => {
          this.$store.commit('setQueryId', data.queryId)
          this.$store.commit('setBrowseRoutes', data)
          this.$store.commit('setStatus', 'UpdatesComplete')

          this.$router.push('/' + this.locale + '/routes')
        })
        .catch((error) => {
          // console.log(error.response)

          this.$store.commit('setError', error.response)
          this.$store.commit('setStatus', 'Error')
        })
    },
    searchFlights() {
      let infants = 0
      const frm = document.frm

      if (!frm.originPlace.value) {
        this.$swal(this.$t('mixins.oops'), this.$t('mixins.validOrigin'))
        document.getElementById('origin').focus()
        return
      }
      if (!frm.destinationPlace.value) {
        this.$swal(this.$t('mixins.oops'), this.$t('mixins.validDestination'))
        document.getElementById('destination').focus()
        return
      }
      if (!frm.outboundDate.value) {
        this.$swal(this.$t('mixins.oops'), this.$t('mixins.validOutboundDate'))
        document.getElementById('outbound-date').focus()
        return
      }
      if (
        this.$store.state.tripType === 'roundTrip' &&
        !frm.inboundDate.value
      ) {
        this.$swal(this.$t('mixins.oops'), this.$t('mixins.validReturnDate'))
        document.getElementById('inbound-date').focus()
        return
      }
      for (let i = 0; i < this.$store.state.criteria.children.length; i++) {
        if (this.$store.state.criteria.children[i] === -1) {
          this.$swal(this.$t('mixins.oops'), this.$t('mixins.validChildAges'))
          document.getElementById('travellers').focus()
          return
        }
      }
      this.$store.state.criteria.children.map((childAge) => {
        if (childAge < 2) {
          infants++
        }
      })
      if (infants > this.$store.state.criteria.adults) {
        this.$swal(this.$t('mixins.oops'), this.$t('mixins.oneChild'))
        document.getElementById('travellers').focus()
        return
      }

      this.$store.commit('setStatus', 'Searching')

      if (this.criteria.tripType === 'one-way') {
        this.$store.commit('setInboundDate', null)
        this.$store.commit('setInboundLatest', null)
      }

      if (
        this.criteria.origin.code.length === 2 ||
        this.criteria.destination.code.length === 2
      ) {
        return this.routesSearch()
      }

      if (this.criteria.calendarType === 'specific') {
        this.liveSearch()
      } else {
        this.browseSearch()
      }
    },
    selectInboundDate(date) {
      if (date === 'anytime') {
        this.$store.commit('setInboundDate', 'anytime')
        this.$store.commit('setOutboundDate', 'anytime')
        return
      }

      this.$store.commit(
        'setInboundDate',
        this.$moment(date).format('YYYY-MM-DD'),
      )
      this.updateOutboundDate()
    },
    selectOutboundDate(date) {
      if (date === 'anytime') {
        this.$store.commit('setOutboundDate', 'anytime')
        this.$store.commit('setInboundDate', 'anytime')
        return
      }

      this.$store.commit(
        'setOutboundDate',
        this.$moment(date).format('YYYY-MM-DD'),
      )
      this.updateInboundDate()
    },
    updateInboundDate() {
      if (!this.isRoundTrip) {
        return
      }

      const outboundDate = this.criteria.outboundDate
      const inboundDate = this.criteria.inboundDate

      if (inboundDate === 'anytime') {
        this.$store.commit('setInboundDate', outboundDate)
      }

      if (inboundDate === null || inboundDate < outboundDate) {
        this.selectInboundDate(outboundDate)
        // if (document.getElementById("inbound-date")) {
        //     window.setTimeout('document.getElementById("inbound-date").focus()', 300);
        // } else {
        //     window.setTimeout('document.getElementById("travellers").focus()', 300);
        // }
      }
    },
    updateOutboundDate() {
      const outboundDate = this.criteria.outboundDate
      const inboundDate = this.criteria.inboundDate

      if (outboundDate === 'anytime') {
        this.$store.commit('setOutboundDate', inboundDate)
      }

      if (outboundDate > inboundDate) {
        this.$store.commit('setOutboundDate', inboundDate)
      }
    },
  },
}
