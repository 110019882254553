<template>
  <div class="w-full p-2 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5">
    <button
      class="
        flex-grow
        bg-green-200
        text-green-500
        cursor-pointer
        no-underline
        rounded-lg
        p-2
        w-full
        hover:bg-green-100 hover:text-green-600
        active:bg-green-300
        focus:outline-none focus:shadow-outline
      "
      @click="showDeals(airportCode)"
    >
      <span class="flex items-end justify-start block">
        <InlineSvg
          name="airplane"
          classes="w-4 h-4 inline fill-current rotate-90"
        ></InlineSvg>
        <span class="ml-2 text-lg">{{ $t('deals')[airportCode] }}</span>
      </span>

      <span class="flex items-end justify-start block">
        <span class="text-sm font-normal mb-px">
          {{ $t('pricingOptions.from') }}
        </span>
        <AppPrice
          class="text-xl inline-block ml-1"
          :currency="currency"
          :dollar-amount="deal.price"
          :decimals="0"
        ></AppPrice>
      </span>
    </button>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import SearchMixin from './mixins/SearchMixin.js'
import AppPrice from './AppPrice'

export default {
  components: {
    AppPrice,
  },
  mixins: [SearchMixin],
  props: {
    deal: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState(['deals', 'dealsAirports']),
    ...mapGetters(['currency']),
    airportCode() {
      return this.isBanjulOriginating
        ? this.deal.destinationPlace
        : this.deal.originPlace
    },
    isBanjulOriginating() {
      return this.criteria.origin.code === 'BJL'
    },
  },
  methods: {
    showDeals(airportCode) {
      this.$scrollToTop()

      this.$store.commit('setPlace', {
        code: 'BJL',
        name: 'Banjul',
        placeType: this.isBanjulOriginating ? 'origin' : 'destination',
      })

      this.$store.commit('setPlace', {
        code: airportCode,
        name: this.$t('deals')[airportCode],
        placeType: this.isBanjulOriginating ? 'destination' : 'origin',
      })

      this.$store.commit('setOutboundDate', this.deal.outboundDate)
      this.$store.commit('setInboundDate', this.deal.inboundDate)
      this.browseSearch()
    },
  },
}
</script>
