export default {
  AMS: 'Amsterdam',
  BHX: 'Birmingham',
  LGW: 'London Gatwick',
  MAN: 'Manchester',
  BCN: 'Barcelona',
  BRU: 'Bryssel',
  LPA: 'Gran Canaria',
  DKRA: 'Dakar',
  FRA: 'Frankfurt',
  DUS: 'Dusseldorf',
  CDG: 'Paris',
  MUC: 'Munich',
  return: 'retur',
  oneWay: 'enkelresa',
  topDeals: 'Dagens bästa erbjudanden',
  welcome:
    'Välkommen till webbens mest omfattande resurs för billiga flyg till Gambia!',
  explain_1:
    'N.B. Detta projekt är/var ett pågående arbete och bör endast ses som sådant. Jag skrev det ursprungligen som en standard Laravel-applikation, men kopplade sedan bort front- och bakänden, så det är nu ett Vue-CLI-projekt som drivs av ett Laravel API.',
  explain_2:
    'Jag var halvvägs med att migrera frontend från Bootstrap till Tailwind CSS, så stylingen är ganska repig på sina ställen. Dessutom, eftersom jag har stängt av webbskrapan som utökade dessa Skyscanner-resultat med mina egna, kommer du inte att få uppleva den största fördelen med webbplatsen. Men förhoppningsvis kommer det ändå att ge dig en smak av vad det handlade om!',
  readMore: 'Läs mer',
  readLess: 'Läs mindre',
}
