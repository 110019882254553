export default {
  return: 'Hin- und Rückflug',
  oneway: 'Nur Hinflug',
  from: 'Von',
  To: 'Nach',
  to: 'nach',
  placeholder: 'Land, Stadt oder Flughafen eingeben',
  nearby: 'Nahegelegene Flughäfen hinzufügen',
  swap: 'Klicken Sie hier, um den Abreise- und Ankunftsort zu tauschen.',
  outbound: 'Hinreise',
  inbound: 'Rückreise',
  classpax: 'Kabinenklasse & Reisende',
  done: 'Fertig',
  cabin: 'Kabinenklasse',
  travellers: 'Travellers',
  adult: 'Erwachsener',
  Economy: 'Economy',
  PremiumEconomy: 'Premium Economy',
  Business: 'Business class',
  First: 'First class',
  EconomyClass: 'Economy',
  PremiumEconomyClass: 'Premium Economy',
  BusinessClass: 'Business class',
  FirstClass: 'First class',
  adults: 'Erwachsene',
  children: 'Kinder',
  over16: '16 Jahre +',
  to15: '0 bis 15 Jahre',
  childAge: 'Alter des Kindes',
  validAge:
    'Ihr Alter zum Reisezeitpunkt muss für die gebuchte Kategorie gültig sein. Bei Fluggesellschaften bestehen Einschränkungen für alleine reisende Personen unter 18 Jahren.',
  ageLimits:
    'Altersbegrenzungen und Richtlinien für Reisen mit Kindern können variieren. Holt deshalb vor der Buchung Informationen bei der Fluggesellschaft ein.',
  directOnly: 'Nur Direktflüge',
  search: 'Suchen',
  prev: 'Vorh.',
  next: 'Weiter',
  changeSearch: 'Suche ändern',
  cheapestMonth: 'Günstigster Monat',
  specificDate: 'Bestimmtes Datum',
  flexibleDate: 'Ganzer Monat',
  wholeMonth: 'Gesamten Monat anzeigen',
  showSpecific: 'Bestimmtes Datum anzeigen',
  enterDate: 'Datum eingeben',
}
