export default {
  getPriceAlerts: 'Recevoir des alertes prix',
  airlines: 'Compagnies aériennes',
  combination: 'Combinaisons de compagnies aériennes',
  airports: 'Aéroports',
  stops: 'Escales',
  direct: 'Direct',
  oneStop: '1 escale',
  twoPlusStops: '2+ escales',
  none: 'Aucun',
  selectAll: 'Tout sélectionner',
  clearAll: 'Tout supprimer',
  flyOut: 'Partir et revenir depuis les',
  flyOutGerman: '',
  sameAirports: 'mêmes aéroports',
  no: 'Pas de',
  changeAirport: "changement d'aéroport",
  duringConnections: 'lors des correspondances',
  departFrom: 'Départ de',
  arriveAt: 'Arrivée à',
  journeyDuration: 'Durée du voyage',
  hours: 'heures',
  departureTimes: 'Heures de départ',
  arrivalTimes: "Heures d'arrivée",
  outbound: 'Aller',
  inbound: 'Retour',
}
