export default {
  yourAccount: 'Ton compte',
  profile: 'profil',
  Profile: 'Profil',
  savedSearch: 'recherche enregistrée',
  savedSearches: 'Recherches enregistrées',
  congratulations: 'Félicitations!',
  updatedProfile: 'Tu as réussi à mettre à jour ton profil',
  deletedProfile: 'Tu as réussi à suprimer ton profil',
  loggedInAs: 'Bonjour, tu es connecté en tant que',
  loggedInAsGerman: '.',
  editYour: 'Tu peux modifier ton',
  editYourGerman: '',
  orSetup: ', ou configurer une',
  orSetupGerman: '',
  weWill: "et nous t'enverrons les derniers prix de vols qui t'intéressent.",
  noSavedSearches: "Tu n'as actuellement aucune recherche enregistrée.",
  toSetUp:
    'Pour en configurer une, il suffit de faire une recherche à partir de la',
  toSetUpGerman: '',
  searchPage: 'page de recherche',
  clickSave: ', puis de cliquer sur le bouton',
  saveButton: 'Enregistrer la recherche',
  resultsPage: 'sur la page de résultats.',
  thankYou: 'Thank you!',
  sentSuccess: 'Votre message a été envoyé avec succès!',
}
