export default {
  airlineFees: 'Von der Fluggesellschaft erhobene Gebühren',
  previousPage: 'Zurück zur vorherigen Seite',
  pricesInclude:
    'Preise beinhalten eine Schätzung aller gesetzlichen Pflichtsteuern und Gebühren, aber einige Fluggesellschaften/Reisebüros berechnen eventuell einen Aufpreis für ',
  baggage: 'Gepäck',
  insurance: 'Versicherung',
  useOf: 'die Verwendung von ',
  creditCards: 'Kreditkarten ',
  other: 'oder andere Dienstleistungen',
  findDetails:
    'Untenstehend sind genauere Angaben zu den Gebühren der einzelnen Fluggesellschaften:',
}
