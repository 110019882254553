export default {
  outbound: 'Depart:',
  inbound: 'Return:',
  selectDates: 'Select dates',
  calendar: 'Calendar',
  chart: 'Chart',
  valid: 'N.B. Price valid',
  more: 'more info',
  less: 'less info',
  noPrice: 'N.B. We have not found any prices for ',
  thisDate: 'this date ',
  theseDates: 'these dates ',
  notMean: 'within the last 15 days, but flights may be available!',
  doSearch: 'Click the button to perform a live search',
  directOnly: 'Direct flights only',
  none: 'none',
  loading: 'Loading',
  prices: 'Prices',
  lowest: 'Lowest',
  medium: 'Medium',
  highest: 'Highest',
  checkPrice: 'Price needs to be checked',
  notDirect: '1+ stops only',
  outboundFlights: 'Outbound flights',
  inboundFlights: 'Inbound flights',
  showFlights: 'Show flights',
  from: 'from',
  adultPrice: 'price per adult',
  min: 'Min',
  max: 'Max',
  any: 'Any',
  summary: 'Summary:',
  day: 'day',
  days: 'days',
  earliest: 'Earliest departure',
  latest: 'Latest return',
  showCheapest: 'Show cheapest',
  showingCheapest: 'Showing cheapest',
  option: 'option',
  options: 'options',
  moreFilters: 'More filters',
  hideFilters: 'Hide filters',
  showTips: 'Show tips',
  hideTips: 'Hide tips',
  estimatedOnly:
    'N.B. Estimated lowest prices only. Found in the last 15 days.',
}
