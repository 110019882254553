export default {
  to: 'to',
  of: 'of',
  results: 'results',
  flightDetails: 'Your flight details',
  show: 'Show',
  more: 'more',
  fewer: 'fewer',
  ofResults: 'results',
  anzeigen: '',
}
