<template>
  <div v-show="dealsCount" class="mt-6">
    <div>
      <h2>
        {{ $t('deals.topDeals') }}
        {{
          isBanjulOriginating
            ? $t('pricingOptions.from')
            : $t('searchControlsContainer.to')
        }}
        Banjul ({{ oneWay ? $t('deals.oneWay') : $t('deals.return') }}):
      </h2>
    </div>

    <div class="flex flex-wrap justify-between -mx-2">
      <TopDeal
        v-for="deal in deals"
        :key="deal.id"
        :deal="deal"
        @monthDataReceived="selectCheapest(0)"
      ></TopDeal>

      <!-- Following lines are a hack to ensure the last <div> displays correctly -->
      <div v-for="n in 2" :key="`a${n}`" class="sm:w-1/2 md:hidden"></div>
      <div v-for="n in 3" :key="`b${n}`" class="md:w-1/3 lg:hidden"></div>
      <div v-for="n in 4" :key="`c${n}`" class="lg:w-1/4 xl:hidden"></div>
      <div v-for="n in 5" :key="`d${n}`" class="xl:w-1/5"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import BrowseMixin from './mixins/BrowseMixin.js'
import TopDeal from './TopDeal'

export default {
  components: {
    TopDeal,
  },
  mixins: [BrowseMixin],
  computed: {
    ...mapState(['criteria', 'deals']),
    dealsCount() {
      return Object.keys(this.deals).length
    },
    isBanjulOriginating() {
      return this.criteria.origin.code === 'BJL'
    },
    oneWay() {
      return this.criteria.tripType === 'one-way'
    },
  },
}
</script>
