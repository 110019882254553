<template>
  <div
    class="
      min-h-screen
      flex flex-col
      antialiased
      bg-gray-200
      dark:bg-yellow-300
      text-gray-900
      px-2
      md:pt-2
    "
  >
    <div class="flex-1 max-w-6xl sm:w-11/12 sm:m-auto">
      <!--<SkyScannerRequests v-if="showRequestBar"/>-->
      <NavBar />
      <RouterView />
      <TopDeals />
    </div>
    <SeoFooter />

    <div id="modals"></div>
    <div id="panels"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// import SkyScannerRequests from '@/components/SkyScannerRequests'
import NavBar from '@/components/NavBar'
import TopDeals from '@/components/TopDeals'
import SeoFooter from '@/components/SeoFooter'
import './assets/css/tailwind.css'

export default {
  name: 'App',
  metaInfo: {
    title: 'Flights to The Gambia',
    meta: [
      {
        name: 'description',
        content:
          "The world's most comprehensive resource for flights to The Gambia",
      },
    ],
  },
  components: {
    // SkyScannerRequests,
    NavBar,
    TopDeals,
    SeoFooter,
  },
  data() {
    return {
      showRequestBar: false,
    }
  },
  computed: {
    ...mapGetters(['api', 'initLocale']),
  },
  created() {
    this.init()

    window.addEventListener('resize', () => {
      this.saveResize()
    })

    // this.getShowRequestCountBar()
    //
    // this.listen()
  },
  mounted() {
    this.saveResize()
  },
  methods: {
    init() {
      this.$axios.get(`${this.api}/init`).then(({ data }) => {
        const settings = data.settings

        this.$store.commit('setCountries', data.countries.data)
        this.$store.commit('setLanguages', data.languages.data)
        this.$store.commit('setCurrencies', data.currencies.data)
        this.$store.commit('setNearestAirports', data.nearestAirports)
        this.$store.dispatch('saveSettings', data.settings)

        localStorage.setItem('settings', JSON.stringify(settings))

        this.getDeals(settings)

        if (settings.countryId === 'GM') {
          this.switchPlaces()
        }
      })
    },
    getDeals() {
      this.$axios
        .post(`${this.api}/deals`, {
          tripType: this.$store.state.criteria.tripType,
          airports: this.$store.state.dealsAirports,
          banjulOriginating: this.$store.state.criteria.origin.code === 'BJL',
        })
        .then((response) => {
          this.$store.commit('setDeals', response.data)
        })
    },
    switchPlaces() {
      const origin = {
        ...this.$store.state.criteria.origin,
        placeType: 'destination',
      }
      const destination = {
        ...this.$store.state.criteria.destination,
        placeType: 'origin',
      }

      this.$store.commit('setPlace', origin)
      this.$store.commit('setPlace', destination)
    },
    saveResize() {
      this.$store.commit(
        'setScreenWidth',
        window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth,
      )
    },
    // getShowRequestCountBar () {
    //   this.$axios.get('/api/admin/show-request-count-bar')
    //     .then(response => {
    //       this.showRequestBar = Boolean(response.data)
    //     })
    // },
    // listen () {
    //   window.Echo.channel('skyscanner-show-request-bar')
    //     .listen('SkyScannerRequestBarToggled', ({ showRequestCountBar }) => {
    //       this.showRequestBar = showRequestCountBar
    //     })
    // }
  },
}
</script>
