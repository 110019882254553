export default {
  NB: 'Vær oppmerksom på',
  nextTip: 'Neste tips',
  previousTip: 'Forrige tips',
  lastTip: 'Siste tips',
  tip_1:
    'Denne "månedlige visningen" gir en oversikt over de billigste flyprisene som ble funnet de siste 15 dagene. ' +
    'Prisene kan ha endret seg, så for å få de siste tilbudene må du utføre et levende søk ved å klikke på ',
  button: '.',
  tip_2: 'Bruk',
  toShow: '-fanen til å vise',
  restrict:
    ' eller for å begrense varighet og / eller tidligste avgang eller siste retur.',
  cycle: 'Du kan sykle gjennom de resterende resultatene ved å bruke ',
  anzuzeigen: '',
  orClick: '(eller klikk på datoene direkte)',
  tip_3: 'Når du har funnet passende datoer, kan du klikke på',
  liveSearch:
    'for å utføre et live søk av de siste tilbudene og prisene for de valgte datoene',
  letsGo: 'Ok la oss gå!',
}
