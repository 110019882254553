export default {
  yourAccount: 'Tu cuenta',
  profile: 'perfil',
  Profile: 'Perfil',
  savedSearch: 'búsqueda guardada',
  savedSearches: 'Búsquedas guardadas',
  congratulations: '¡Felicitaciones!',
  updatedProfile: 'Has actualizado tu perfil con éxito.',
  deletedProfile: 'Has eliminado tu perfil con éxito.',
  loggedInAs: 'Hola, has iniciado sesión como',
  loggedInAsGerman: '.',
  editYour: 'Puedes editar tu',
  editYourGerman: '',
  orSetup: ', o configurar una',
  orSetupGerman: '',
  weWill:
    'y te enviaremos por correo electrónico los últimos precios de vuelo que te interesan.',
  noSavedSearches: 'Actualmente no tiene búsquedas guardadas.',
  toSetUp: 'Para configurar una, simplemente haz una búsqueda desde la',
  toSetUpGerman: '',
  searchPage: 'página de búsqueda',
  clickSave: ', y luego haz clic en el botón',
  saveButton: 'Guardar búsqueda',
  resultsPage: 'en la página de resultados.',
  thankYou: '¡Gracias!',
  sentSuccess: 'Tu mensaje ha sido enviado exitosamente',
}
