export default {
  to: 'to',
  of: 'of',
  results: 'resultat',
  flightDetails: 'Your flight details',
  show: 'Vis',
  more: 'flere',
  fewer: 'færre',
  ofResults: 'resultater',
  anzeigen: '',
}
