export default {
  return: 'Andata e ritorno',
  oneway: 'Solo andata',
  from: 'Da',
  To: 'A',
  to: 'a',
  placeholder: 'Paese, città o aeroporto',
  nearby: 'Includi aeroporti vicini',
  swap: 'Fai clic qui per scambiare le destinazioni',
  outbound: 'Partenza',
  inbound: 'Ritorno',
  classpax: 'Classe & Viaggiatori',
  done: 'Fatto',
  cabin: 'FattoClasse di viaggio',
  travellers: 'viaggiatori',
  adult: 'Adulti',
  Economy: 'Economy',
  PremiumEconomy: 'Premium Economy',
  Business: 'Business class',
  First: 'Prima classe',
  EconomyClass: 'Classe Economy',
  PremiumEconomyClass: 'Classe Premium Economy',
  BusinessClass: 'Business class',
  FirstClass: 'Prima classe',
  adults: 'Adulti',
  children: 'Bambini',
  over16: '16+ anni',
  to15: '0-15 anni',
  childAge: 'Età del bambino',
  validAge:
    "L'età effettiva al momento della partenza deve corrispondere all'età dichiarata al momento della prenotazione. Le compagnie aeree applicano restrizioni per minori non accompagnati.",
  ageLimits:
    "Le condizioni di viaggio per bambini e i limiti d'età possono variare a seconda della compagni aerea. Si prega di verificare tali condizioni prima di prenotare.",
  directOnly: 'Solo voli diretti',
  search: 'Cerca voli',
  prev: 'Prec.',
  next: 'Pross.',
  changeSearch: 'Cambia ricerca',
  cheapestMonth: 'Il mese più conveniente',
  specificDate: 'Data specifica',
  flexibleDate: 'Tutto il mese',
  wholeMonth: 'Mostra tutto il mese',
  showSpecific: 'Mostra una data specifica',
  enterDate: 'Inserisci una data',
}
