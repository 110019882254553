<template>
  <div class="flex justify-between items-center bg-gray-200 rounded-t border-b">
    <CalendarTypeTab
      type="specific"
      @select-calendar-type="selectCalendarType($event)"
    />

    <CalendarTypeTab
      type="flexible"
      @select-calendar-type="selectCalendarType($event)"
    />
  </div>
</template>

<script>
import CalendarTypeTab from '@/components/CalendarTypeTab'

export default {
  components: { CalendarTypeTab },
  methods: {
    selectCalendarType(flexibility) {
      this.$store.commit('setCalendarType', flexibility)
    },
  },
}
</script>
