export default {
  sortBy: 'Sortieren nach',
  score: 'Bestes Angebot',
  price: 'Billigster zuerst',
  duration: 'Kürzester zuerst',
  scoreName: 'Bestes Angebot',
  priceName: 'Billigster',
  durationName: 'Kürzester',
  departure: 'Abreisezeit',
  arrival: 'Ankunftzeit',
  outboundDeparture: 'Hinreise: Abreisezeit',
  outboundArrival: 'Hinreise: Ankunftzeit',
  inboundDeparture: 'Zurück: Abreisezeit',
  inboundArrival: 'Zurück: Ankunftzeit',
  airline: 'Fluglinie',
  stops: 'Stopps',
  average: 'im Durchschnitt',
  bestTip:
    'Wir sind der Meinung, dass diese Flüge die beste Kombination von <strong>Preis</strong> und <strong>Schnelligkeit</strong> aufweisen. Wir berücksichtigen unter Umständen auch Faktoren wie Anzahl der Zwischenstopps und Komplexität des Ablaufs.',
  cheapestTip: 'Nach billigstem Preis sortieren.',
  durationTip: 'Nach kürzester Reisedauer sortieren',
}
