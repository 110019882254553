export default {
  loading: 'Lasting',
  notAvailable: 'Beklager, denne billetten er ikke lenger tilgjengelig',
  soldOut: 'Billetten kan ha blitt utsolgt.',
  refresh: 'Oppdater resultater',
  check: 'Sjekk reiseplanen din',
  timesLocal: 'alle tidspunkt er oppgitt i lokal tid',
  departingFrom: 'Du går fra',
  returningTo: 'men vender tilbake til',
  zuruck: '',
  bookTicket: 'Bestill billettene dine',
  readBefore: 'Les før du bestiller',
  pricesShown:
    'Prisene vi viser inkluderer alltid en beregning av alle obligatoriske skatter og avgifter, men husk å',
  checkAll: 'sjekke ALL billettinformasjonen, endelig pris og vilkårene',
  beforeBook: 'på bestillingsnettsiden før du bestiller.',
  extraFees: 'Se etter ekstra gebyrer',
  someAirlines: 'Noen flyselskaper / reisebyråer tar ekstra betalt for',
  baggage: 'bagasje',
  insurance: 'forsikring',
  orUseOf: 'eller bruk av',
  creditCards: 'kredittkort',
  view: 'Se',
  airlineFees: 'flyselskapenes avgifter',
  an: '.',
  checkTC: 'Sjekk vilkårene for reisende i alderen 12-16',
  restrictions:
    'Det kan gjelde begrensninger for unge passasjerer som reiser alene.',
  adults: 'voksne',
  adult: 'voksen',
  children: 'barn',
  child: 'barn',
  infants: 'spedbarn',
  infant: 'spedbarn',
  combine: 'Kombiner 2 enkeltfly',
}
