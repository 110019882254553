<template>
  <div class="mt-4">
    <label class="form-label" :for="travellerType">
      {{ name }}
    </label>

    <div class="flex items-end text-sm">
      <button
        class="mr-3px rounded focus:outline-none focus:shadow-outline"
        :disabled="isMinDisabled"
        tabindex="0"
        type="button"
        @click.stop="$emit('decrease')"
      >
        <InlineSvg
          name="icon-minus-circle"
          :classes="iconClass(isMinDisabled)"
        ></InlineSvg>
      </button>

      <input
        :id="travellerType"
        class="text-2xl w-8 bg-gray-200 text-center"
        readonly
        tabindex="-1"
        type="text"
        :value="value"
      />

      <button
        class="ml-3px rounded focus:outline-none focus:shadow-outline"
        :disabled="isMaxDisabled"
        tabindex="0"
        type="button"
        @click.stop="$emit('increase')"
      >
        <InlineSvg
          name="icon-plus-circle"
          :classes="iconClass(isMaxDisabled)"
        ></InlineSvg>
      </button>

      <div class="ml-2 text-green-600 items-baseline">
        {{ description }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectTravellers',
  props: {
    description: {
      type: String,
      default: '',
    },
    isMaxDisabled: {
      type: Boolean,
      default: false,
    },
    isMinDisabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    travellerType: {
      type: String,
      default: '',
    },
    value: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    iconClass(isDisabled) {
      const textColour = isDisabled ? 'text-green-300' : 'text-green-500'

      return `w-8 h-8 ${textColour} hover:text-green-400`
    },
  },
}
</script>
