export default {
  yourAccount: 'Ditt konto',
  profile: 'profil',
  Profile: 'Profil',
  savedSearch: 'sparade sökning',
  savedSearches: 'Sparade sökningar',
  congratulations: 'Grattis!',
  updatedProfile: 'Du har uppdaterat din profil',
  deletedProfile: 'Du har tagit bort din profil',
  loggedInAs: 'Hej, du är inloggad som',
  loggedInAsGerman: '.',
  editYour: 'Du kan redigera din',
  editYourGerman: '',
  orSetup: ', eller konfigurera en',
  orSetupGerman: '',
  weWill:
    'och vi kommer att maila dig de senaste flygpriserna du är intresserad av.',
  noSavedSearches: 'Du har för närvarande inga sparade sökningar.',
  toSetUp: 'För att ställa in en, gör du bara en sökning från',
  toSetUpGerman: '',
  searchPage: 'hemsidan',
  clickSave: ' och klickar sedan på knappen',
  saveButton: 'Spara sök',
  resultsPage: 'på resultatsidan.',
  thankYou: 'Tack!',
  sentSuccess: 'Ditt meddelande har skickats!',
}
