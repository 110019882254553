export default {
  filter: 'Filtrer',
  sort: 'Sorter',
  checked: 'Sjekket ',
  of: 'av',
  searching: 'Søker',
  providers: 'leverandører',
  results: 'resultater',
  collating: 'Sortering av data',
  topTen: 'Viser topp 10 resultater',
  showAll: 'Vis alt',
  appliedFilters: 'Vi har brukt filtrene dine.',
  combine: 'flykombinasjoner',
}
