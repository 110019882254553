export default {
  connection: 'Anslut på flygplats',
  longWait: 'Lång väntan',
  h: 'tim',
  min: '',
  change: 'Byt flygplats',
  in: 'i',
  from: 'från',
  to: 'till',
  stayOnPlane: 'Bo ombord på flygplanet',
  unknown: 'Anslutningsflygplats (okänd)',
}
