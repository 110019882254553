export default {
  confirm: 'Bekräfta lösenord',
  email: 'E-postadress',
  name: 'Namn',
  password: 'Lösenord',
  register: 'Registrera dig',
  registerText:
    'Registrering tar bara en minut och tillåter dig att få e-postmeddelanden om flygpriserna. Du kan avbryta när som helst ...',
  registerSuccess: 'Din registrering lyckades.',
  registerSubmitting: 'Vänta...',
  update: 'Uppdatera',
  updateSubmitting: 'Uppdatera...',
  delete: 'Radera',
  deleteSubmitting: 'Radera...',
  forgot: 'Glömt ditt lösenord?',
  login: 'Logga in',
  loginSubmitting: 'Logging in...',
  remember: 'Kom ihåg mig',
  tryAgain: 'Var god försök igen...',
  serverConnection: 'Det gick inte att ansluta till servern',
  failed: 'Dessa referenser matchar inte våra poster.',
  throttle: 'För många inloggningsförsök. Försök igen om :seconds sekunder.',
  passwordLength:
    'Lösenord måste vara minst sex tecken och matcha bekräftelsen.',
  reset: 'Ditt lösenord har blivit återställt!',
  sent: 'Vi har e-postat din länk för återställning av lösenord!',
  token: 'Denna lösenordsåterställningstoken är ogiltig.',
  user: 'Vi kan inte hitta en användare med den e-postadressen.',
  contactUs: 'Contact us',
  contactText:
    'Have you spotted an error? Is there anything you would like us to improve?',
  drop: "Drop us an email and we'll do our best to help!",
  message: 'Your message',
  contact: 'Send message',
  contactSubmitting: 'Sending message',
}
