export default {
  AMS: 'Amsterdam',
  BHX: 'Birmingham',
  LGW: 'Londres Gatwick',
  MAN: 'Manchester',
  BCN: 'Barcelona',
  BRU: 'Brussels',
  LPA: 'Gran Canaria',
  DKRA: 'Dakar',
  FRA: 'Francfort',
  DUS: 'Düsseldorf',
  CDG: 'Paris',
  MUC: 'Munich',
  return: 'regreso',
  oneWay: 'sólo ida',
  topDeals: 'Ofertas especiales de hoy',
  welcome:
    '¡Bienvenido al recurso más amplio del web para los vuelos baratos a Gambia!',
  explain_1:
    'Nótese bien: Este proyecto es/fue un trabajo en progreso y solo debe verse como tal. Originalmente lo escribí como una aplicación estándar de Laravel, pero luego desacoplaba el front-end y el back-end, por lo que ahora es un proyecto Vue-CLI que funciona con una API de Laravel.',
  explain_2:
    'Estaba a medio camino de migrar la interfaz de Bootstrap a Tailwind CSS, por lo que el estilo es bastante complicado en algunos lugares. Además, como cerré el raspador web que aumentó estos resultados de Skyscanner con los míos, no podrá experimentar el beneficio principal del sitio. ¡Pero con suerte todavía te dará una idea de lo que se trataba!',
  readMore: 'Lee mas',
  readLess: 'Leer menos',
}
