export default {
  loading: 'Chargement en cours',
  notAvailable: "Désolé, ce tarif n'est plus disponible",
  soldOut: 'Ce billet est probablement épuisé.',
  refresh: 'Rafraîchissez vos résultats',
  check: 'Vérifiez votre itinéraire',
  timesLocal: 'toutes les heures sont données en heure locale',
  departingFrom: 'Vous partez de',
  returningTo: 'mais vous repartez vers',
  zuruck: '',
  bookTicket: 'Réserver votre billet',
  readBefore: 'Lire avant de réserver',
  pricesShown:
    "Les prix affichés comprennent toujours une estimation de tous les frais et taxes obligatoires, mais n'oubliez pas de",
  checkAll:
    'vérifier TOUS les détails des billets, les prix finaux et les conditions',
  beforeBook: 'sur le site Internet de réservation avant de réserver.',
  extraFees: 'Consulter les frais supplémentaires',
  someAirlines:
    'Certaines compagnies aériennes / agences de voyage facturent un supplément pour les',
  baggage: 'baggages',
  insurance: "l'assurance",
  orUseOf: "ou l'utilisation des",
  creditCards: 'cartes de crédit',
  view: 'Voir les',
  airlineFees: 'frais des compagnies aériennes',
  an: '.',
  checkTC:
    'Consulter les termes et conditions pour les voyageurs âgés de 12-16 ans',
  restrictions:
    "Des restrictions peuvent s'appliquer à de jeunes passagers voyageant seuls.",
  adults: 'adultes',
  adult: 'adulte',
  children: 'enfants',
  child: 'enfant',
  infants: 'bébés',
  infant: 'bébé',
  combine: 'Combinez 2 vols simples',
}
