export default {
  to: 'til',
  of: 'ud af',
  results: 'resultater',
  flightDetails: 'Oplysninger',
  show: 'Vis',
  more: 'flere',
  fewer: 'færre',
  ofResults: 'resultater',
  anzeigen: '',
}
