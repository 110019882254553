<template>
  <div class="">
    <!--<div class="mistakes">-->
    <!--{{ $t('seoFooter.anyMistakes') }}-->
    <!--<a href="#">{{ $t('seoFooter.contactUs') }}</a>.-->
    <!--</div>-->

    <div
      class="
        -ml-2
        -mr-2
        mt-8
        text-sm
        border-t border-green-200
        bg-green-100
        text-green-500
      "
    >
      <div class="sm:flex sm:justify-between sm:w-10/12 lg:w-11/12 sm:m-auto">
        <div>
          <div class="mt-2 flex justify-center sm:justify-start">
            &copy; {{ year }} Gambia-flights.com
          </div>

          <div class="mt-2 flex justify-center sm:justify-start">
            <RouterLink
              class="
                p-1
                mr-1
                text-blue-700
                hover:underline
                focus:outline-none focus:shadow-outline
                rounded
              "
              :to="'/' + locale + '/terms'"
            >
              Terms of use
            </RouterLink>
            &nbsp;
            <span class="p-1">|</span>
            <RouterLink
              class="
                p-1
                mx-1
                text-blue-700
                hover:underline
                focus:outline-none focus:shadow-outline
                rounded
              "
              :to="'/' + locale + '/privacy'"
            >
              Privacy policy
            </RouterLink>
            &nbsp;
            <span class="p-1">|</span>
            <RouterLink
              class="
                p-1
                mx-1
                text-blue-700
                hover:underline
                focus:outline-none focus:shadow-outline
                rounded
              "
              :to="'/' + locale + '/contact'"
            >
              Contact us
            </RouterLink>
          </div>
        </div>

        <div>
          <div class="mt-2 flex justify-center">
            <div class="follow">{{ $t('seoFooter.social') }}:</div>
          </div>

          <div class="mt-1 flex justify-center pb-4">
            <a
              aria-label="Gambia-flights.com Facebook page"
              class="p-1 mr-2 rounded focus:outline-none focus:shadow-outline"
              href="https://www.facebook.com/Gambia-Flights-215915155818061"
              target="_blank"
            >
              <InlineSvg
                aria-hidden="true"
                name="facebook"
                classes="w-8 h-8 fill-current text-blue-700"
              ></InlineSvg>
            </a>
            <a
              aria-label="Gambia-flights.com Twitter page"
              class="p-1 ml-2 rounded focus:outline-none focus:shadow-outline"
              href="https://twitter.com/GambiaFlights?ref_src=twsrc%5Etfw"
              data-show-count="false"
              target="_blank"
            >
              <InlineSvg
                aria-hidden="true"
                name="twitter"
                classes="w-8 h-8 fill-current text-blue-400"
              ></InlineSvg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapState(['user']),
    ...mapGetters(['locale', 'isAuth']),
    year() {
      return new Date().getFullYear()
    },
  },
}
</script>
