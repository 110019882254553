export default {
  return: 'Retur',
  oneway: 'Enkeltbillet',
  from: 'Fra',
  To: 'Til',
  to: 'til',
  placeholder: 'Land, by eller lufthavn',
  nearby: 'Tilføj lufthavne i nærheden',
  swap: 'Klik her for at ombytte destinationerne',
  outbound: 'Afrejse',
  inbound: 'Retur',
  classpax: 'Kabineklasse og rejsende',
  done: 'Udført',
  cabin: 'Kabineklasse',
  travellers: 'rejsende',
  adult: 'voksen',
  Economy: 'Economy',
  PremiumEconomy: 'Economy extra',
  Business: 'Business class',
  First: 'First class',
  EconomyClass: 'Economy-klasse',
  PremiumEconomyClass: 'Economy extra',
  BusinessClass: 'Business class',
  FirstClass: 'First class',
  adults: 'Voksne',
  children: 'Børn',
  over16: '16+ år',
  to15: '0-15 år',
  childAge: 'Barnets alder',
  validAge:
    'Din alder på rejsetidspunktet skal være gyldigt i den aldersklasse der er booket. Flyselskaber har restriktioner på personer under 18 år der rejser alene.',
  ageLimits:
    'Politikker vedrørende aldersgrænser og rejser med børn kan variere, så tjek venligst med flyselskabet inden du booker.',
  directOnly: 'Vis kun direkte flyrejser',
  search: 'Søg',
  prev: 'Forrige',
  next: 'Næste',
  changeSearch: 'Ændr søgning',
  cheapestMonth: 'Billigste måned',
  specificDate: 'Specifik dato',
  flexibleDate: 'Hele måneden',
  wholeMonth: 'Vis hele måneden',
  showSpecific: 'Vis specifik dato',
  enterDate: 'Indtast en dato',
}
