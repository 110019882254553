export default {
  save: 'Salva',
  cancel: 'Annulla',
  contact: 'Contatto',
  direct: 'Voli diretti',
  deals: 'Migliori offerte',
  logout: 'Disconnettersi',
  london_gatwick: 'Londra Gatwick',
  preferences: 'Preferenze',
  search: 'Ricerca',
  title: 'Voli per Gambia',
  description: 'La risorsa più completa del mondo per i voli in Gambia',
}
