export default {
  outbound: 'Avgang:',
  inbound: 'Retur:',
  selectDates: 'Velg datoer',
  calendar: 'Kalender',
  chart: 'Tabell',
  valid: 'NB Pris gyldig',
  more: 'mer informasjon',
  less: 'mindre info',
  noPrice: 'NB Vi har ikke funnet noen priser for ',
  thisDate: 'denne datoen ',
  theseDates: 'disse datoene ',
  notMean:
    'i løpet av de siste 15 dagene, men flyreiser kan være tilgjengelige!',
  doSearch: 'Klikk på knappen for å utføre et levende søk',
  directOnly: 'Kun direkteflyvninger',
  none: 'ingen',
  loading: 'Lasting',
  prices: 'Priser',
  lowest: 'Laveste',
  medium: 'Middels pris',
  highest: 'Høyeste',
  checkPrice: 'Prisen må sjekkes',
  notDirect: 'Kun 1+ overganger',
  outboundFlights: 'Utgående flyreiser',
  inboundFlights: 'Innkommende flyreiser',
  showFlights: 'Vis flyvninger',
  from: 'fra',
  adultPrice: 'pris per voksen',
  min: 'Min',
  max: 'Max',
  any: 'Alle',
  summary: 'Oppsummering:',
  day: 'dag',
  days: 'dager',
  earliest: 'Tidligste avgang',
  latest: 'Siste retur',
  showCheapest: 'Vis billigste',
  showingCheapest: 'Viser billigste',
  option: 'opsjon',
  options: 'opsjoner',
  moreFilters: 'Flere filtre',
  hideFilters: 'Skjul filtre',
  showTips: 'Vis tips',
  hideTips: 'Skjul tips',
  estimatedOnly:
    'NB Kun de lavest anslåtte prisene. Funnet de siste 15 dagene.',
}
