<template>
  <div class="lg:flex lg:mt-2">
    <div class="lg:w-1/3">
      <h1
        class="text-lg sm:text-2xl md:text-3xl leading-tight mt-2"
        data-test-main-heading
      >
        {{ $t('deals.welcome') }}
      </h1>
      <p class="hidden sm:block mt-6 text-lg">
        {{ $t('deals.explain_1') }}
        <span
          class="text-blue-500 cursor-pointer hover:underline"
          @click="toggleMore"
        >
          <span v-if="isReadMore">{{ $t('deals.readLess') }}...</span>
          <span v-else>{{ $t('deals.readMore') }}...</span>
        </span>
      </p>
      <VueSlideUpDown :active="isReadMore">
        <p class="hidden sm:block mt-6 text-lg mb-6">
          {{ $t('deals.explain_2') }}
        </p>
      </VueSlideUpDown>
    </div>

    <div
      class="
        mt-4
        max-w-md
        m-auto
        sm:w-auto sm:max-w-6xl
        lg:mt-0 lg:w-2/3 lg:ml-8
      "
    >
      <SearchControlsContainer @monthDataReceived="selectCheapest(0)" />
    </div>

    <p class="sm:hidden mt-6 text-lg">{{ $t('deals.explain_1') }}</p>
    <p class="sm:hidden mt-6 text-lg">{{ $t('deals.explain_2') }}</p>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import SearchControlsContainer from '../components/SearchControlsContainer'

export default {
  name: 'HomeView',
  components: {
    SearchControlsContainer,
  },
  data() {
    return {
      expired: false,
      isReadMore: false,
    }
  },
  computed: {
    ...mapState(['status']),
    ...mapGetters(['currency']),
    initial() {
      return this.status === 'Initial'
    },
    updatesPending() {
      return this.status === 'UpdatesPending'
    },
    updatesComplete() {
      return this.status === 'UpdatesComplete'
    },
  },
  created() {
    this.$store.commit('setSearchControls', true)
  },
  methods: {
    toggleMore() {
      this.isReadMore = !this.isReadMore
    },
  },
}
</script>
