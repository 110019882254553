export default {
  filter: 'Filtro',
  sort: 'Ordenar',
  checked: 'Verificados(s) ',
  of: 'de',
  searching: 'Procurando',
  providers: 'fornecedores',
  results: 'resultados',
  showAll: 'Mostre tudo',
  appliedFilters: 'Nós aplicamos seus filtros.',
  combine: 'combinações de voo',
}
