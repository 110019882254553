export default {
  sortBy: 'Ordenar por',
  score: 'Mejor opción',
  price: 'Primero la opción más barata',
  duration: 'Primero la opción más rápida',
  scoreName: 'Mejor',
  priceName: 'Lo más barato',
  durationName: 'Lo más rápido',
  departure: 'Hora de salida',
  arrival: 'Hora de llegada',
  outboundDeparture: 'Ida: Hora de salida',
  outboundArrival: 'Ida: Hora de llegada',
  inboundDeparture: 'Vuelta: Hora de salida',
  inboundArrival: 'Vuelta: Hora de llegada',
  airline: 'Aerolínea',
  stops: 'Escalas',
  average: 'media',
  bestTip:
    'Pensamos que estos vuelos tienen la mejor combinación de <strong>precio</strong> y <strong>velocidad</strong>. ' +
    'También podemos tener en cuenta factores como el número de escalas y las complicaciones que pueda haber.',
  cheapestTip: 'Ordenar por precio más barato.',
  durationTip: 'Ordenar por la menor duración.',
}
