export default {
  filter: 'Filters',
  sort: 'Sort',
  checked: 'Checked ',
  of: 'of',
  searching: 'Searching',
  providers: 'providers',
  results: 'results',
  showAll: 'Show all',
  appliedFilters: "We've applied your filters.",
  combine: 'flight combinations',
}
