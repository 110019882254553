export default {
  sortBy: 'Sortér efter',
  score: 'Bedste',
  price: 'Billigste først',
  duration: 'Hurtigste først',
  scoreName: 'Bedst',
  priceName: 'Billigst',
  durationName: 'Hurtigste',
  departure: 'Afgangstid',
  arrival: 'Ankomsttid',
  outboundDeparture: 'Udrejse: Afgangstid',
  outboundArrival: 'Udrejse: Ankomsttid',
  inboundDeparture: 'Hjemrejse: Afgangstid',
  inboundArrival: 'Hjemrejse: Ankomsttid',
  airline: 'Flyselskab',
  stops: 'Mellemlandinger',
  average: 'gennemsnit',
  bestTip:
    'Vi mener, at disse fly tilbyder den bedste kombination af <strong>pris</strong> og <strong>fart</strong>. Vi inddrager eventuelt også faktorer som f.eks. antal stop og graden af besvær.',
  cheapestTip: 'Sorter efter billigste pris.',
  durationTip: 'Sorter efter korteste varighed',
}
