export default {
  to: 'a',
  of: 'de',
  results: 'resultados',
  flightDetails: 'Detalles de tu vuelo',
  show: 'Mostrar',
  more: 'más',
  fewer: 'menos',
  ofResults: 'resultados',
  anzeigen: '',
}
