export default {
  yourAccount: 'Sua conta',
  profile: 'perfil',
  Profile: 'Perfil',
  savedSearch: 'pesquisa salva',
  savedSearches: 'Pesquisas salvas',
  congratulations: 'Parabéns!',
  updatedProfile: 'Você atualizou seu perfil com sucesso',
  deletedProfile: 'Você excluiu seu perfil com sucesso',
  loggedInAs: 'Oi, você está logado como',
  loggedInAsGerman: '.',
  editYour: 'Você pode editar seu',
  editYourGerman: '',
  orSetup: ' ou configurar uma',
  orSetupGerman: '',
  weWill:
    'e nós lhe enviaremos os preços mais recentes do vôo que você está interessado',
  noSavedSearches: 'Atualmente, você não possui nenhuma pesquisa salva..',
  toSetUp: 'Para configurar um, basta fazer uma pesquisa na',
  toSetUpGerman: '',
  searchPage: 'página de pesquisa',
  clickSave: ' e depois clicar no botão',
  saveButton: 'Salvar pesquisa',
  resultsPage: 'na página de resultados.',
  thankYou: 'Obrigado!',
  sentSuccess: 'Sua mensagem foi enviada com sucesso!',
}
