export default {
  return: 'Retour',
  oneway: 'Enkele reis',
  from: 'Van',
  To: 'Naar',
  to: 'naar',
  placeholder: 'Voer een land, stad of luchthaven in',
  nearby: 'Vliegvelden in de buurt toevoegen',
  swap: 'Klik hier om van locatie te veranderen',
  outbound: 'Vertrek',
  inbound: 'Terug',
  classpax: 'Vliegklasse en reizigers',
  done: 'Gereed',
  cabin: 'Reisklasse',
  travellers: 'reizigers',
  adult: 'Volwassen',
  Economy: 'Economy',
  PremiumEconomy: 'Premium Economy',
  Business: 'Business class',
  First: 'First class',
  EconomyClass: 'Economy class',
  PremiumEconomyClass: 'Premium Economy class',
  BusinessClass: 'Business class',
  FirstClass: 'First class',
  adults: 'Volwassenen',
  children: 'Kinderen',
  over16: '16+ jaar',
  to15: '0-15 jaar',
  childAge: 'Leeftijd van kind',
  validAge:
    'Je leeftijd op het moment van reizen moet geldig zijn voor de geboekte leeftijdscategorie. Luchtvaartmaatschappijen hebben beperkingen voor personen onder de 18 jaar die alleen reizen.',
  ageLimits:
    'Leeftijdsgrenzen en beleidsvoorschriften voor reizen met kinderen kunnen variëren dus neem vóór het boeken contact op met de luchtvaartmaatschappij.',
  directOnly: 'Alleen directe vluchten weergeven',
  search: 'Zoeken',
  prev: 'Vorige',
  next: 'Volgende',
  changeSearch: 'Zoekopdracht wijzigen',
  cheapestMonth: 'Goedkoopste maand',
  specificDate: 'Specifieke datum',
  flexibleDate: 'De hele maand',
  wholeMonth: 'Toon de hele maand',
  showSpecific: 'Toon specifieke datum',
  enterDate: 'Voer een datum in',
}
