export default {
  getPriceAlerts: 'Få prisbevakningar',
  airlines: 'Flygbolag',
  combination: 'Kombinationer',
  airports: 'Flygplatser',
  stops: 'Stopp',
  direct: 'Direkt',
  oneStop: '1 stopp',
  twoPlusStops: '2+ stopp',
  none: 'Inget',
  selectAll: 'Markera alla',
  clearAll: 'Avmarkera alla',
  flyOut: 'Flyg båda vägar via',
  flyOutGerman: '',
  sameAirports: 'samma flygplatser',
  no: 'Ingen',
  changeAirport: 'förändring av flygplatsen',
  duringConnections: 'under anslutningarna',
  departFrom: 'Avgår från',
  arriveAt: 'Anländer till',
  journeyDuration: 'Restid',
  hours: 'timmar',
  departureTimes: 'Avgångstider',
  arrivalTimes: 'Ankomst tider',
  outbound: 'Avresa',
  inbound: 'Hemresa',
}
