export default {
  connection: 'Ligação no aeroporto',
  longWait: 'Escala longa',
  h: 'h',
  min: 'm',
  change: 'Alterar aeroporto',
  in: 'em',
  from: 'de',
  to: 'para',
  stayOnPlane: 'Fique a bordo da aeronave',
  unknown: 'Aeroporto de conexão (desconhecido)',
}
