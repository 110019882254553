export default {
  return: 'Tur & retur',
  oneway: 'Enkelresa',
  from: 'Från',
  To: 'Till',
  to: 'till',
  placeholder: 'Land, stad eller flygplats',
  nearby: 'Lägg till flygplatser i närheten',
  swap: 'Klicka här för att låta orterna byta plats',
  outbound: 'Avresa',
  inbound: 'Hemresa',
  classpax: 'Reseklass och resenärer',
  done: 'Klar',
  cabin: 'Reseklass',
  travellers: 'resenärer',
  adult: 'vuxen',
  Economy: 'Economy',
  PremiumEconomy: 'Economy plus',
  Business: 'Business class',
  First: 'Första klass',
  EconomyClass: 'Ekonomiklass',
  PremiumEconomyClass: 'Economy plus',
  BusinessClass: 'Business class',
  FirstClass: 'Första class',
  adults: 'Vuxna',
  children: 'Barn',
  over16: '16+ år',
  to15: '0 to 15 år',
  childAge: 'Ålder på barn',
  validAge:
    'Din ålder vid resan måste vara giltig för den ålderskategori du bokat. Flygbolagen har begränsningar för personer under 18 år som reser ensamma.',
  ageLimits:
    'Åldersgränser och regler för att resa med barn kan variera så kontrollera med flygbolaget innan du bokar.',
  directOnly: 'Visa endast direktflyg',
  search: 'Sök flyg',
  prev: 'Föreg.',
  next: 'Nästa',
  changeSearch: 'Ändra sökningen',
  cheapestMonth: 'Billigaste månaden',
  specificDate: 'Specifikt datum',
  flexibleDate: 'Hel månad',
  wholeMonth: 'Visa hela månaden',
  showSpecific: 'Visa specifikt datum',
  enterDate: 'Ange ett datum',
}
