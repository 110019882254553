export default {
  sortBy: 'Sorter etter',
  score: 'Beste',
  price: 'Billigste først',
  duration: 'Hurtigste først',
  scoreName: 'Beste',
  priceName: 'Billigst',
  durationName: 'Raskest',
  departure: 'Avgangstid',
  arrival: 'Ankomsttid',
  outboundDeparture: 'Utreise: avgangstid',
  outboundArrival: 'Utreise: ankomsttid',
  inboundDeparture: 'Retur: avgangstid',
  inboundArrival: 'Retur: ankomsttid',
  airline: 'Flyselskap',
  stops: 'Stopp',
  average: 'gjennomsnitt',
  bestTip:
    'Vi tror disse flyvningene tilbyr den beste kombinasjonen av <strong>pris</strong> og <strong>hastighet</strong>. Vi kan også vurdere faktorer som antall mellomlandinger og mengde ekstra bry.',
  cheapestTip: 'Sorter etter billigste pris.',
  durationTip: 'Sorter etter korteste reisetid.',
}
