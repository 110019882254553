export default {
  confirm: 'Bekreft passord',
  email: 'Epostadresse',
  name: 'Navn',
  password: 'Passord',
  register: 'Registrer',
  registerText:
    'Registrering tar bare et minutt og gir deg mulighet til å motta e-postvarsler om flypriser. Du kan når som helst avbryte ......',
  registerSuccess: 'Registreringen var vellykket.',
  registerSubmitting: 'Vennligst vent...',
  update: 'Oppdater',
  updateSubmitting: 'Oppdatering...',
  delete: 'Slett',
  deleteSubmitting: 'Slette',
  forgot: 'Glemt passordet ditt?',
  login: 'Logg inn',
  loginSubmitting: 'Logger inn...',
  remember: 'Husk meg',
  tryAgain: 'Vær så snill, prøv på nytt...',
  serverConnection: 'Kan ikke koble til server',
  failed: 'Disse legitimasjonene stemmer ikke overens med våre poster.',
  throttle:
    'For mange påloggingsforsøk. Vennligst prøv igjen på :seconds sekunder.',
  passwordLength:
    'Passord må være minst seks tegn og samsvar med bekreftelsen.',
  reset: 'passordet ditt har blitt tilbakestilt!',
  sent: 'Vi har sendt e-postadressen til tilbakestilling av passord!',
  token: 'Dette passordet tilbakestilt token er ugyldig.',
  user: 'Vi kan ikke finne en bruker med den e-postadressen.',
  contactUs: 'Contact us',
  contactText:
    'Have you spotted an error? Is there anything you would like us to improve?',
  drop: "Drop us an email and we'll do our best to help!",
  message: 'Your message',
  contact: 'Send message',
  contactSubmitting: 'Sending message',
}
