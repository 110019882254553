import agent from './agent'
import airlineFees from './airlineFees'
import arrivalInfo from './arrivalInfo'
import auth from './auth'
import bookingDetails from './bookingDetails'
import connection from './connection'
import dayHeader from './dayHeader'
import deals from './deals'
import error from './error'
import filters from './filters'
import flexible from './flexible'
import flightRoutes from './flightRoutes'
import itineraries from './itineraries'
import leg from './leg'
import legDetails from './legDetails'
import localSettings from './localSettings'
import mixins from './mixins'
import month from './month'
import navBar from './navBar'
import navHeader from './navHeader'
import noResults from './noResults'
import place from './place'
import priceAlerts from './priceAlerts'
import pricingOptions from './pricingOptions'
import searchContent from './searchContent'
import searchControlsContainer from './searchControlsContainer'
import searching from './searching'
import seoFooter from './seoFooter'
import sort from './sort'
import stopLine from './stopLine'
import user from './user'
import validatorAttributes from './validator_attributes'

export default {
  agent,
  airlineFees,
  arrivalInfo,
  auth,
  bookingDetails,
  connection,
  dayHeader,
  deals,
  error,
  filters,
  flexible,
  flightRoutes,
  itineraries,
  leg,
  legDetails,
  localSettings,
  mixins,
  month,
  navBar,
  navHeader,
  noResults,
  place,
  priceAlerts,
  pricingOptions,
  searchContent,
  searchControlsContainer,
  searching,
  seoFooter,
  stopLine,
  sort,
  user,
  validatorAttributes,
}
