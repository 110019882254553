export default {
  airlineFees: 'Flyselskabets gebyrer',
  previousPage: 'Tilbage til forrige side',
  pricesInclude:
    'De viste priser på Skyscanner er altid inklusive et overslag på alle obligatoriske skatter og afgifter, men nogle flyselskaber/rejsebureauer tager ekstragebyrer for ',
  baggage: 'bagage',
  insurance: 'forsikring',
  useOf: 'betaling med ',
  creditCards: 'kreditkort',
  other: 'eller andre tjenester',
  findDetails:
    'Du kan finde oplysninger om hvert flyselskabs gebyrer nedenfor:',
}
