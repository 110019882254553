export default {
  save: 'Gem',
  cancel: 'Annuller',
  contact: 'Kontakt',
  direct: 'Direkte flyvninger',
  deals: 'Bedste tilbud',
  logout: 'Log ud',
  london_gatwick: 'London Gatwick',
  preferences: 'Indstillinger',
  search: 'Søg',
  title: 'Flybilletter til Gambia',
  description: 'Verdens mest omfattende ressource for fly til Gambia',
}
