export default {
  confirm: 'Conferma password',
  email: 'Indirizzo e-mail',
  name: 'Nome',
  password: 'Password',
  register: 'Registrati',
  registerText:
    'La registrazione richiede solo un minuto e ti consente di ricevere notifiche via email dei prezzi dei voli. È possibile annullare in qualsiasi momento...',
  registerSuccess: 'Ti sei registrato con successo.',
  registerSubmitting: 'Attendere prego...',
  update: 'Aggiornare',
  updateSubmitting: 'In aggiornamento...',
  delete: 'Elimina',
  deleteSubmitting: 'Eliminazione',
  forgot: 'Password dimenticata?',
  login: 'Accedi',
  loginSubmitting: 'Entrando...',
  remember: 'Ricordami',
  tryAgain: 'Per favore riprova...',
  serverConnection: 'Impossibile stabilire una connessione al server',
  failed: 'Queste credenziali non corrispondono ai nostri record.',
  throttle:
    'Troppi tentativi di accesso. Per favore riprova tra :seconds secondi.',
  passwordLength:
    'Le password devono contenere almeno sei caratteri e devono corrispondere alla conferma.',
  reset: 'La tua password è stata resettata!',
  sent: 'Abbiamo inviato via e-mail il tuo link per la reimpostazione della password!',
  token: 'Questo token di reimpostazione della password non è valido.',
  user: "Non possiamo trovare un utente con quell'indirizzo e-mail.",
  contactUs: 'Contact us',
  contactText:
    'Have you spotted an error? Is there anything you would like us to improve?',
  drop: "Drop us an email and we'll do our best to help!",
  message: 'Your message',
  contact: 'Send message',
  contactSubmitting: 'Sending message',
}
