export default {
  AMS: 'Amsterdã',
  BHX: 'Birmingham',
  LGW: 'Londres Gatwick',
  MAN: 'Manchester',
  BCN: 'Barcelona',
  BRU: 'Bruxelas',
  LPA: 'Grã-Canária',
  DKRA: 'Dakar',
  FRA: 'Frankfurt',
  DUS: 'Dusseldorf',
  CDG: 'Paris',
  MUC: 'Munique',
  return: 'ida e volta',
  oneWay: 'só ida',
  topDeals: 'Principais negócios',
  welcome:
    'Bem-vindo ao site com a informação mais completa para voos baratos para Gâmbia!',
  explain_1:
    'N.B. Este projeto é/foi um trabalho em andamento e só deve ser visto como tal. Eu o escrevi originalmente como um aplicativo Laravel padrão, mas depois dissociei o front-end e o back-end, então agora é um projeto Vue-CLI que é alimentado por uma API Laravel.',
  explain_2:
    'Eu estava no meio da migração do frontend do Bootstrap para o Tailwind CSS, então o estilo é bastante complicado em alguns lugares. Além disso, como eu desliguei o web scraper que aumentou esses resultados do Skyscanner com os meus, você não poderá experimentar o principal benefício do site. Mas espero que ainda lhe dê um gostinho do que se tratava!',
  readMore: 'Leia mais',
  readLess: 'Leia menos',
}
