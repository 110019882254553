export default {
  yourAccount: 'Dein Konto',
  profile: 'Profil',
  Profile: 'Profil',
  savedSearch: 'gespeicherte Suche',
  savedSearches: 'Gespeicherte Suche',
  congratulations: 'Großartig!',
  updatedProfile: 'Du hast dein Profil erfolgreich aktualisiert',
  deletedProfile: 'Du hast dein Profil erfolgreich gelöscht',
  loggedInAs: 'Hallo, du bist als',
  loggedInAsGerman: ' angemeldet.',
  editYour: 'Du kannst dein ',
  editYourGerman: ' bearbeiten',
  orSetup: ', oder eine',
  orSetupGerman: ' einrichten',
  weWill:
    'und wir schicken dir die neuesten Flugpreise, die dir interessieren.',
  noSavedSearches: 'Du hast derzeit keine gespeicherten Suchvorgänge.',
  toSetUp: 'Um eine aufzustellen, einfach eine Suche von der ',
  toSetUpGerman: ' ausführen',
  searchPage: 'Suchseite',
  clickSave: ' und dann auf',
  saveButton: 'Suche speichern',
  resultsPage: 'auf der Ergebnisseite klicken.',
  thankYou: 'Danke!',
  sentSuccess: 'Deine Nachricht wurde erfolgreich versendet',
}
