export default {
  loading: 'Bezig met laden',
  notAvailable: 'Sorry, dit tarief is niet langer beschikbaar',
  soldOut: 'Het ticket kan uitverkocht zijn.',
  refresh: 'Vernieuw resultaten',
  check: 'Details',
  timesLocal: 'alle getoonde tijden zijn lokale tijden',
  departingFrom: 'U vertrekt vanaf',
  returningTo: 'maar vliegt terug naar',
  zuruck: '',
  bookTicket: 'Boek je ticket',
  readBefore: 'Lees deze informatie voordat je boekt',
  pricesShown:
    'De getoonde prijzen zijn altijd inclusief een schatting van alle verplichte belastingen en toeslagen, maar vergeet niet om',
  checkAll: 'ALLE ticketinformatie, totaalprijzen en algemene voorwaarden',
  beforeBook: 'op de website waar je boekt te controleren voordat je boekt.',
  extraFees: 'Controleer extra kosten',
  someAirlines:
    'Sommige luchtvaartmaatschappijen / reisbureaus brengen extra kosten in rekening voor',
  baggage: 'bagage',
  insurance: 'verzekeringen',
  orUseOf: 'of het gebruik van',
  creditCards: 'creditcards',
  view: 'Bekijk',
  airlineFees: 'kosten luchtvaartmaatschappijen',
  an: '.',
  checkTC: 'Controleer de algemene voorwaarden voor reizigers van 12-16 jaar',
  restrictions:
    'Er kunnen restricties gelden voor jonge reizigers die onbegeleid reizen.',
  adults: 'volwassenen',
  adult: 'volwassene',
  children: 'kinderen',
  child: 'kind',
  infants: "baby's",
  infant: 'baby',
  combine: 'Combineer 2 enkele vluchten',
}
