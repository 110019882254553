export default {
  getPriceAlerts: 'Obter Alertas de Preço',
  airlines: 'Companhias aéreas',
  combination: 'Combinações de companhias aéreas',
  airports: 'Aeroportos',
  stops: 'Escalas',
  direct: 'Direto',
  oneStop: '1 escala',
  twoPlusStops: '2+ escalas',
  none: 'Nenhum',
  selectAll: 'Selecionar tudo',
  clearAll: 'Limpar tudo',
  flyOut: 'Voo de ida e volta nos',
  flyOutGerman: '',
  sameAirports: 'mesmos aeroportos',
  no: 'Não',
  changeAirport: 'mudar de aeroporto',
  duringConnections: 'durante escalas',
  departFrom: 'Partida de',
  arriveAt: 'Chegada a',
  journeyDuration: 'Duração da viagem',
  hour: 'hora',
  hours: 'horas',
  departureTimes: 'Horários da partida',
  arrivalTimes: 'Horários da chegada',
  outbound: 'Voo de ida',
  inbound: 'Regresso',
}
