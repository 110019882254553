export default {
  yourAccount: 'Din konto',
  profile: 'profil',
  Profile: 'Profil',
  savedSearch: 'gemt søgning',
  savedSearches: 'gemte søgninger',
  congratulations: 'Tillykke!',
  updatedProfile: 'Du har opdateret din profil',
  deletedProfile: 'Du har slettet din profil',
  loggedInAs: 'Hej, du er logget ind som',
  loggedInAsGerman: '.',
  editYour: 'Du kan redigere din',
  editYourGerman: '',
  orSetup: ', eller opsæt en',
  orSetupGerman: '',
  weWill: 'og vi vil sende dig de seneste flypriser, du er interesseret i.',
  noSavedSearches: 'Du har i øjeblikket ingen gemte søgninger.',
  toSetUp: 'For at indstille en, skal du blot foretage en søgning fra',
  toSetUpGerman: '',
  searchPage: 'søgesiden',
  clickSave: ' og klik derefter på knappen',
  saveButton: 'Gem søgning',
  resultsPage: 'på resultatsiden.',
  thankYou: 'Tak!',
  sentSuccess: 'Din besked er blevet sendt',
}
