export default {
  to: 'à',
  of: 'de',
  results: 'résultats',
  flightDetails: 'Informations de votre vol',
  show: 'Afficher',
  more: 'plus',
  fewer: 'moins',
  ofResults: 'de résultats',
  anzeigen: '',
}
