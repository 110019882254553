export default {
  filter: 'Filter',
  sort: 'Ordnen',
  checked: '',
  of: 'von',
  searching: 'Suche',
  providers: 'Anbietern geprüft',
  results: 'Ergebnissen',
  collating: 'Zusammentrag der Daten',
  topTen: 'Top-10-Ergebnisse',
  showAll: 'alle anzeigen',
  appliedFilters: 'Wir haben deine Filter angewendet.',
  combine: 'nach Flugkombinationen',
}
