<template>
  <div class="flex sm:w-1/2">
    <div class="flex-1 mr-1 sm:mr-0 sm:w-1/4 sm:pr-2">
      <label class="form-label text-green-100" for="outbound-date">
        {{ $t('searchControlsContainer.outbound') }}
      </label>
      <PickDate
        id="outbound-date"
        class="text-gray-700"
        :disabled-dates="disabledOutbound"
        :highlighted="highlighted"
        :type="type"
        :minimum-view="minimumView"
        @selected="selectOutboundDate"
      ></PickDate>
    </div>

    <div class="flex-1 ml-1 sm:ml-0 sm:w-1/4 sm:pr-2">
      <label class="form-label text-green-100" for="inbound-date">
        {{ $t('searchControlsContainer.inbound') }}
      </label>
      <PickDate
        id="inbound-date"
        class="text-gray-700"
        :disabled-dates="disabledInbound"
        :highlighted="highlighted"
        :type="type"
        :minimum-view="minimumView"
        :value="criteria.inboundDate"
        @selected="selectInboundDate"
      ></PickDate>
    </div>
  </div>
</template>
<script>
import PickDate from './PickDate'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'TravelDates',
  components: { PickDate },
  computed: {
    ...mapState(['criteria']),
    ...mapGetters(['isRoundTrip']),
    disabledInbound() {
      const outboundDate = this.criteria.outboundDate

      if (outboundDate === 'anytime') {
        return
      }

      const disabledInboundDate = this.$moment(outboundDate)

      return {
        to: disabledInboundDate.toDate(), // Disable all dates up to specific date
      }
    },
    disabledOutbound() {
      return {
        to: this.$moment().startOf(this.minimumView).toDate(),
      }
    },
    highlighted() {
      const outboundDate = this.criteria.outboundDate

      if (this.criteria.tripType === 'one-way' || outboundDate === 'anytime') {
        return
      }

      return {
        ranges: [
          {
            from: this.$moment(outboundDate).toDate(), // Highlight all dates after specific date
            to: this.$moment(this.criteria.inboundDate).toDate(), // Highlight all dates up to specific date
          },
        ],
      }
    },
    minimumView() {
      return this.type === 'specific' ? 'day' : 'month'
    },
    type() {
      if (this.criteria.outboundDate === 'anytime') {
        return 'anytime'
      }

      return this.criteria.calendarType
    },
  },
  methods: {
    selectInboundDate(date) {
      if (date === 'anytime') {
        this.$store.commit('setInboundDate', 'anytime')
        this.$store.commit('setOutboundDate', 'anytime')
        return
      }

      this.$store.commit(
        'setInboundDate',
        date ? this.$moment(date).format('YYYY-MM-DD') : null,
      )
      this.updateOutboundDate()
    },
    selectOutboundDate(date) {
      if (date === 'anytime') {
        this.$store.commit('setOutboundDate', 'anytime')
        this.$store.commit('setInboundDate', 'anytime')
        return
      }

      this.$store.commit(
        'setOutboundDate',
        date ? this.$moment(date).format('YYYY-MM-DD') : null,
      )
      this.updateInboundDate()
    },
    updateInboundDate() {
      if (!this.isRoundTrip) {
        return
      }

      const outboundDate = this.criteria.outboundDate
      const inboundDate = this.criteria.inboundDate

      if (inboundDate === 'anytime') {
        this.$store.commit('setInboundDate', outboundDate)
      }

      if (
        outboundDate === null ||
        inboundDate === null ||
        inboundDate < outboundDate
      ) {
        this.selectInboundDate(outboundDate)
      }
    },
    updateOutboundDate() {
      const outboundDate = this.criteria.outboundDate
      const inboundDate = this.criteria.inboundDate

      if (outboundDate === 'anytime') {
        this.$store.commit('setOutboundDate', inboundDate)
      }

      if (outboundDate > inboundDate) {
        this.$store.commit('setOutboundDate', inboundDate)
      }
    },
  },
}
</script>
