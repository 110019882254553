export default {
  airlineFees: 'Taxas do operador',
  previousPage: 'Voltar para a pagina anterior',
  pricesInclude:
    'Os preços incluem sempre uma estimativa de todas as taxas e encargos obrigatórios, mas algumas companhias aéreas / agentes aplicam taxas adicionais por ',
  baggage: 'bagagem',
  insurance: 'seguro',
  useOf: 'utilização de ',
  creditCards: 'cartões de crédito',
  other: 'ou outros serviços',
  findDetails:
    'Poderá encontrar mais informação sobre as taxas de cada operador abaixo:',
}
