export default {
  outbound: 'Avgång:',
  inbound: 'Återresa:',
  selectDates: 'Välj datum',
  calendar: 'Kalender',
  chart: 'Diagram',
  valid: 'OBSERVERA Pris gäller',
  more: 'mer information',
  less: 'mindre information',
  noPrice: 'OBSERVERA Vi har inte hittat några priser för ',
  thisDate: 'det här datumet ',
  theseDates: 'dessa datum ',
  notMean: 'under de senaste 15 dagarna, men flyg kan vara tillgängliga!',
  doSearch: 'Klicka på knappen för att utföra en levande sökning',
  directOnly: 'Endast direktflyg',
  none: 'inga',
  loading: 'Hämtar',
  prices: 'Priser',
  lowest: 'Lägst',
  medium: 'Medium',
  highest: 'Högst',
  checkPrice: 'Priset måste kontrolleras',
  notDirect: 'Endast 1+ stopp',
  outboundFlights: 'Utgående flygningar',
  inboundFlights: 'Inkommande flygningar',
  showFlights: 'Visa flyg',
  from: 'från',
  adultPrice: 'pris per vuxen',
  min: 'Min',
  max: 'Max',
  any: 'Några',
  summary: 'Sammanfattning:',
  day: 'dag',
  days: 'dagar',
  earliest: 'Tidigaste avgång',
  latest: 'Senaste avkastningen',
  showCheapest: 'Visa billigaste',
  showingCheapest: 'Visar billigaste',
  option: 'alternativ',
  options: 'alternativ',
  moreFilters: 'Fler filter',
  hideFilters: 'Dölj filter',
  showTips: 'Visa tips',
  hideTips: 'Dölj tips',
  estimatedOnly:
    'OBSERVERA Endast ungefärliga lägsta priser. Hittades under de senaste 15 dagarna.',
}
