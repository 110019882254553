export default {
  outbound: 'Départ :',
  inbound: 'Retour :',
  selectDates: 'Sélectionner les dates',
  calendar: 'Calendrier',
  chart: 'Graphique',
  valid: 'N.B. Prix valable',
  more: "plus d'infos",
  less: "moins d'infos",
  noPrice: "N.B. Nous n'avons trouvé aucun prix pour ",
  thisDate: 'cette date ',
  theseDates: 'ces dates ',
  notMean:
    'dans les 15 derniers jours, mais des vols peuvent être disponibles!',
  doSearch: 'Cliquez sur le bouton pour effectuer une recherche en direct',
  directOnly: 'Vols directs uniquement',
  none: 'aucun',
  loading: 'Chargement en cours',
  prices: 'Des prix',
  lowest: 'Le moins cher',
  medium: 'Moyen',
  highest: 'Le plus cher',
  checkPrice: 'Le prix doit être vérifié',
  notDirect: 'Uniquement avec au moins une escale',
  outboundFlights: 'Vols aller',
  inboundFlights: 'Vols retour',
  showFlights: 'Afficher les vols',
  from: 'dès',
  adultPrice: 'prix par adulte',
  min: 'Min',
  max: 'Max',
  any: "N'importe",
  summary: 'Sommaire :',
  day: 'jour',
  days: 'jours',
  earliest: 'Départ le plus tôt',
  latest: 'Dernier retour',
  showCheapest: 'Sélectionner le moins cher',
  showingCheapest: 'Le moins cher est montré',
  option: 'possibilité',
  options: 'possibilités',
  moreFilters: 'Afficher plus de filtres',
  hideFilters: 'Masquer les filtres',
  showTips: 'Afficher les conseils',
  hideTips: 'Masquer les conseils',
  estimatedOnly:
    "N.B. Il ne s'agit que d'une estimation des prix les plus bas. Trouvé au cours des 15 derniers jours.",
}
