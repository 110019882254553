export default {
  sortBy: 'Ordenar por',
  score: 'Recomendado',
  price: 'Mais baratos primeiro',
  duration: 'Mais rápidos primeiro',
  scoreName: 'Recomendado',
  priceName: 'Mais barato',
  durationName: 'O mais rápido  ',
  departure: 'Hora de partida',
  arrival: 'Hora de chegada',
  outboundDeparture: 'Voo de ida: hora de partida',
  outboundArrival: 'Voo de ida: hora de chegada',
  inboundDeparture: 'Regresso: hora de partida',
  inboundArrival: 'Regresso: hora de chegada',
  airline: 'Companhia aérea',
  stops: 'Escalas',
  cheapest: 'Mais barato',
  fastest: 'Mais rápido',
  average: 'média',
  bestTip:
    'Pensamos que estes voos oferecem a melhor combinação de <strong>preço</strong> e <strong>rapidez</strong>. Também consideramos fatores como o número de escalas e quantidade de complicações.',
  cheapestTip: 'Ordenar pelo preço mais barato.',
  durationTip: 'Ordenar pela duração mais curta.',
}
