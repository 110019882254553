<template>
  <div v-click-outside="close" class="relative" :class="{ open: showDropdown }">
    <input
      :id="id"
      v-model="val"
      autocomplete="off"
      :autofocus="autofocus"
      :class="inputClass"
      :placeholder="placeholder"
      spellcheck="false"
      tabindex="0"
      :type="type"
      @blur="handleBlur"
      @click="$event.target.select()"
      @focus="showNearestAirports"
      @input="handleInput($event)"
      @keydown.down="down"
      @keydown.enter.prevent="setValue(items[current])"
      @keydown.esc="reset"
      @keydown.up="up"
    />
    <ul
      ref="dropdown"
      :aria-label="id"
      class="
        absolute
        w-full
        mt-px
        text-gray-700
        bg-white
        shadow-md
        rounded-b-lg
        overflow-hidden
        z-10
      "
      :class="{ showDropdown: showDropdown }"
      role="listbox"
    >
      <Component
        :is="placeType(item.type)"
        v-for="(item, i) in items"
        :key="item.id"
        :class="{
          'bg-blue-200': isActive(i),
          'rounded-b-lg': isLastItem(i),
        }"
        :item="item"
        :uses-miles="usesMiles"
        @hit="setValue($event)"
      />
    </ul>
  </div>
</template>

<script>
import { delayer, getJSON } from './datepicker/utils/utils.js'
import PlaceCity from './PlaceCity'
import PlaceAirport from './PlaceAirport'
import PlaceCountry from './PlaceCountry'

const DELAY = 100

export default {
  components: {
    PlaceAirport,
    PlaceCity,
    PlaceCountry,
  },
  props: {
    autofocus: {
      type: Boolean,
      default: false,
    },
    baseUrl: {
      type: String,
      default: '',
    },
    delay: {
      type: Number,
      default: DELAY,
    },
    id: {
      type: String,
      default: '',
    },
    inputClass: {
      type: String,
      default: '',
    },
    limit: {
      type: Number,
      default: 8,
    },
    nearestAirports: {
      type: Array,
      default: () => [],
    },
    onHit: {
      type: Function,
      default(item) {
        return item
      },
    },
    placeholder: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    usesMiles: {
      type: Boolean,
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      current: 0,
      items: [],
      noResults: true,
      showDropdown: false,
      val: this.value,
    }
  },
  watch: {
    val(val, old) {
      this.$emit('input', val)
      if (val !== old) this.__update()
    },
    value(val) {
      if (this.val !== val) {
        this.val = val
      }
    },
  },
  created() {
    this.__update = delayer(
      function () {
        if (!this.val.trim()) {
          this.reset()
          return
        }

        if (this.val.indexOf('(') > 0) return

        getJSON(this.baseUrl + this.val).then(({ data }) => {
          this.setItems(data)
        })
      },
      'delay',
      DELAY,
    )
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    close() {
      if (!this.showDropdown) {
        return
      }

      this.items = []
      this.showDropdown = false
    },
    down() {
      if (!this.items.length) {
        this.showNearestAirports()
        return
      }

      if (this.current < this.items.length - 1) {
        this.current++
      } else {
        this.current = 0
      }
    },
    handleBlur() {
      if (!this.items.length) {
        return
      }

      this.$emit('set-place', this.items[this.current])
      this.close()
    },
    handleInput(e) {
      this.val = e.target.value
    },
    isActive(index) {
      return this.current === index
    },
    isLastItem(i) {
      return i === this.items.length - 1
    },
    placeType(type) {
      return 'Place' + this.capitalizeFirstLetter(type)
    },
    reset() {
      this.setValue(null)
      this.close()
    },
    setItems(data) {
      this.current = 0
      this.items = data.slice(0, this.limit)
      this.showDropdown = this.items.length > 0
    },
    setValue(value) {
      if (!value) {
        this.val = ''
        return
      }

      this.$emit('set-place', value)
      this.close()
      document.getElementById(this.id).focus()
    },
    showNearestAirports() {
      if (this.id === 'destination' || this.val.length > 0) {
        return
      }

      if (this.nearestAirports) {
        this.setItems(this.nearestAirports)
        this.showDropdown = true
      }
    },
    up() {
      if (this.current > 0) {
        this.current--
      } else {
        this.current = this.items.length - 1
      }
    },
  },
}
</script>
