export default {
  outbound: 'Heen:',
  inbound: 'Terug:',
  selectDates: 'Selecteer datums',
  calendar: 'Kalender',
  chart: 'Grafiek',
  valid: 'N.B. Prijs geldig',
  more: 'meer informatie',
  less: 'minder info',
  noPrice: 'N.B. We hebben geen prijzen gevonden voor ',
  thisDate: 'deze datum ',
  theseDates: 'deze datums ',
  notMean: 'in de afgelopen 15 dagen, maar vluchten kunnen beschikbaar zijn!',
  doSearch: 'Klik op de knop om een live zoekopdracht uit te voeren',
  directOnly: 'Alleen directe vluchten',
  none: 'geen',
  loading: 'Bezig met laden',
  prices: 'Prijzen',
  lowest: 'Laagste',
  medium: 'Medium',
  highest: 'Hoogste',
  checkPrice: 'Prijs moet gecontroleerd worden',
  notDirect: 'alleen 1+ tussenstops',
  outboundFlights: 'Uitgaande vluchten',
  inboundFlights: 'Inkomende vluchten',
  showFlights: 'Vluchten tonen',
  from: 'vanaf',
  adultPrice: 'prijs per volwassene',
  min: 'Min',
  max: 'Max',
  any: 'Alle',
  summary: 'Overzicht:',
  day: 'dag',
  days: 'dagen',
  earliest: 'Vroegste vertrek',
  latest: 'Laatste terugkeer',
  showCheapest: 'Toon goedkoopste',
  showingCheapest: 'Toont goedkoopste',
  option: 'optie',
  options: 'opties',
  moreFilters: 'Toon meer filters',
  hideFilters: 'Verberg filters',
  showTips: 'Laat tips zien',
  hideTips: 'Tips verbergen',
  estimatedOnly:
    'N.B. Alleen geschatte laagste prijzen. Gevonden in de laatste 15 dagen.',
}
