export default {
  confirm: 'Bevestig wachtwoord',
  email: 'E-mailadres',
  name: 'Naam',
  password: 'Wachtwoord',
  register: 'Registreren',
  registerText:
    'Registratie duurt slechts een minuut en kunt u e-mailberichten ontvangen van de vliegprijzen. ' +
    'U kunt op elk moment annuleren...',
  registerSuccess: 'Je hebt succesvol geregistreerd.',
  registerSubmitting: 'Even geduld aub...',
  update: 'Bijwerken',
  updateSubmitting: 'Bijwerking...',
  delete: 'Verwijderen',
  deleteSubmitting: 'Wordt verwijderd',
  forgot: 'Wachtwoord vergeten?',
  login: 'Inloggen',
  loginSubmitting: 'Inloggen...',
  remember: 'Onthoud me',
  tryAgain: 'Probeer het opnieuw...',
  serverConnection: 'Kan geen verbinding maken met de server',
  failed: 'Deze referenties komen niet overeen met onze records.',
  throttle:
    'Te veel aanmeldpogingen. Probeer het opnieuw over :seconds seconden.',
  passwordLength:
    'Wachtwoorden moeten ten minste zes karakters zijn en overeenkomen met de bevestiging.',
  reset: 'Je wachtwoord is gereset!',
  sent: 'We hebben uw wachtwoord reset link ge-maild!',
  token: 'Dit wachtwoord reset token is ongeldig.',
  user: 'We kunnen een gebruiker niet vinden met dat e-mailadres.',
  contactUs: 'Contact us',
  contactText:
    'Have you spotted an error? Is there anything you would like us to improve?',
  drop: "Drop us an email and we'll do our best to help!",
  message: 'Your message',
  contact: 'Send message',
  contactSubmitting: 'Sending message',
}
