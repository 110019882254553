export default {
  AMS: 'Amsterdam',
  BHX: 'Birmingham',
  LGW: 'Londra Gatwick',
  MAN: 'Manchester',
  BCN: 'Barcellona',
  BRU: 'Bruxelles',
  LPA: 'Gran Canaria',
  DKRA: 'Dakar',
  FRA: 'Francoforte',
  DUS: 'Dusseldorf',
  CDG: 'Parigi',
  MUC: 'Monaco',
  return: 'andata e ritorno',
  oneWay: 'solo andata',
  topDeals: 'Le migliori offerte di oggi',
  welcome:
    'Benvenuti nel sito Web con le informazioni più complete per i voli economici per il Gambia!',
  explain_1:
    "NB Questo progetto è/era un work-in-progress e dovrebbe essere considerato solo come tale. Inizialmente l'ho scritto come un'applicazione Laravel standard, ma poi ho disaccoppiato il front-end e il back-end, quindi ora è un progetto Vue-CLI basato su un'API Laravel.",
  explain_2:
    'Ero a metà della migrazione del frontend da Bootstrap a Tailwind CSS, quindi lo stile è piuttosto armonioso in alcuni punti. Inoltre, poiché ho spento il web scraper che ha aumentato questi risultati di Skyscanner con il mio, non potrai sperimentare il principale vantaggio del sito. Ma spero che ti dia ancora un assaggio di cosa si trattava!',
  readMore: 'Leggi di più',
  readLess: 'Leggi meno',
}
