export default {
  connection: 'Transit i lufthavn',
  longWait: 'Lang ventetid',
  h: 't.',
  min: 'min',
  change: 'Skift lufthavn',
  in: 'i',
  from: 'fra',
  to: 'til',
  stayOnPlane: 'Bliv ombord på flyet',
  unknown: 'Tilslutnings lufthavn (ukendt)',
}
