export default {
  yourAccount: 'Kontoen din',
  profile: 'profil',
  Profile: 'Profil',
  savedSearch: 'lagret søk',
  savedSearches: 'Lagrede søk',
  congratulations: 'Gratulerer!',
  updatedProfile: 'Du har oppdatert profilen din',
  deletedProfile: 'Du har slettet din profil',
  loggedInAs: 'Hei, du er logget inn som',
  loggedInAsGerman: '.',
  editYour: 'Du kan redigere',
  editYourGerman: 'din',
  orSetup: ', eller sette opp et',
  orSetupGerman: '',
  weWill: 'og vi vil sende deg de siste flyprisene du er interessert i.',
  noSavedSearches: 'Du har for øyeblikket ingen lagrede søk.',
  toSetUp: 'For å sette opp, gjør du bare et søk fra',
  toSetUpGerman: '',
  searchPage: 'søkesiden',
  clickSave: ' og klikker deretter på',
  saveButton: 'Lagre søk',
  resultsPage: '-knappen på resultatsiden.',
  thankYou: 'Takk skal du ha!',
  sentSuccess: 'Din melding har blitt sendt!',
}
