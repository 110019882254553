export default {
  airlineFees: 'Airline fees',
  previousPage: 'Back to previous page',
  pricesInclude:
    'Prices always include an estimate of all mandatory taxes and charges, but some airlines / agents will apply additional fees for ',
  baggage: 'baggage',
  insurance: 'insurance',
  useOf: 'use of ',
  creditCards: 'credit cards',
  other: 'or other services',
  findDetails: "You can find details of each airline's fees below:",
}
