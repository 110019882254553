export default {
  NB: 'Bitte beachten',
  nextTip: 'Nächster Tipp',
  previousTip: 'Voriger Tipp',
  lastTip: 'Letzter Tipp',
  tip_1:
    "Diese 'Monatsansicht' bietet einen Überblick über die günstigsten Flugpreise der letzten 15 Tage. Die " +
    'Preise haben sich möglicherweise geändert. Um die neuesten Angebote zu erhalten, müssen Sie eine Live-Suche ' +
    'durchführen, indem Sie auf die Schaltfläche ',
  button: ' klicken.',
  tip_2: 'Verwenden Sie die Registerkarte',
  toShow: ', um',
  restrict:
    ' anzuzeigen, oder die Dauer und / oder die früheste Abreise / späteste Rückkehr einzuschränken. ',
  cycle: 'Sie können dann die verbleibenden Ergebnisse mit den Schaltflächen ',
  anzuzeigen: ' anzuzeigen',
  orClick: 'durchblättern (oder direkt auf die Daten klicken)',
  tip_3:
    'Sobald Sie geeignete Daten gefunden haben, können Sie auf die Schaltfläche',
  liveSearch:
    'klicken, um eine Live-Suche nach den neuesten Angeboten und Preisen für die von Ihnen gewählten Daten durchzuführen',
  letsGo: "OK, los geht's!",
}
