export default {
  AMS: 'Amsterdam',
  BHX: 'Birmingham',
  LGW: 'Londres Gatwick',
  MAN: 'Manchester',
  BCN: 'Barcelone',
  BRU: 'Bruxelles',
  LPA: 'Gran Canaria',
  DKRA: 'Dakar',
  FRA: 'Francfort',
  DUS: 'Düsseldorf',
  CDG: 'Paris',
  MUC: 'Munich',
  return: 'aller retour',
  oneWay: 'aller simple',
  topDeals: "Offres spéciales d'aujourd'hui",
  welcome:
    'Bienvenue à la ressource la plus complète du Web pour les vols pas chers vers la Gambie!',
  explain_1:
    "N.B. Ce projet est/était un travail en cours et ne doit être considéré que comme tel. Je l'ai initialement écrit comme une application Laravel standard, mais j'ai ensuite découplé le front et le back-end, c'est donc maintenant un projet Vue-CLI qui est alimenté par une API Laravel.",
  explain_2:
    "J'étais à mi-chemin de la migration de l'interface de Bootstrap vers Tailwind CSS, donc le style est assez compliqué par endroits. De plus, comme j'ai fermé le grattoir Web qui a augmenté ces résultats Skyscanner avec les miens, vous ne pourrez pas profiter du principal avantage du site. Mais j'espère que cela vous donnera quand même une idée de ce dont il s'agissait!",
  readMore: 'Lire la suite',
  readLess: 'Lire moins',
}
