export default {
  save: 'Spara',
  cancel: 'Avbryt',
  contact: 'Kontakta',
  direct: 'Direktflyg',
  deals: 'Bästa erbjudandena',
  logout: 'Logga ut',
  london_gatwick: 'London Gatwick',
  preferences: 'Inställningar',
  search: 'Sök',
  title: 'Flyg till Gambia',
  description: 'Världens mest omfattande resurs för flyg till Gambia',
}
