export default {
  airlineFees: 'Luchtvaartmaatschappijkosten',
  previousPage: 'Terug naar de vorige pagina',
  pricesInclude:
    'Prijzen zijn altijd inclusief een schatting van alle verplichte belastingen en toeslagen, maar sommige luchtvaartmaatschappijen / online ticketwebsites berekenen extra kosten voor ',
  baggage: 'bagage',
  insurance: 'verzekeringen',
  useOf: 'gebruik van ',
  creditCards: 'creditcards',
  other: 'of andere services',
  findDetails:
    'Onderstaand vind je informatie over de kosten van alle luchtvaartmaatschappijen:',
}
