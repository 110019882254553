export default {
  outbound: 'Hinreise:',
  inbound: 'Rückreise:',
  selectDates: 'Reisetermine auswählen',
  calendar: 'Kalender',
  chart: 'Diagramm',
  valid: 'N.B. Preis gültig',
  more: 'weitere Informationen',
  less: 'weniger Informationen',
  noPrice: 'N.B. Wir haben keine Preise für ',
  thisDate: 'dieses Datum ',
  theseDates: 'diese Daten ',
  notMean:
    'in den letzten 15 Tagen gefunden, jedoch sind Flüge eventuell verfügbar!',
  doSearch:
    'Klicken Sie auf die Schaltfläche, um eine Live-Suche durchzuführen',
  directOnly: 'Nur Direktflüge',
  none: 'keine',
  loading: 'Wird geladen',
  prices: 'Prices',
  lowest: 'Niedrigster Preis',
  medium: 'Mittlerer Preis',
  highest: 'Höchster Preis',
  checkPrice: 'Preis muss überprüft werden',
  notDirect: 'Nur 1+ Zwischenstopps',
  outboundFlights: 'Hinreise',
  inboundFlights: 'Rückreise',
  showFlights: 'Flüge anzeigen',
  from: 'ab',
  adultPrice: 'Preis pro Erwachsener',
  min: 'Min',
  max: 'Max',
  any: 'Alle',
  summary: 'Übersicht:',
  day: 'Tag',
  days: 'Tage',
  earliest: 'Früheste Abreise',
  latest: 'Späteste Rückkehr',
  showCheapest: 'Günstigsten Flug anzeigen',
  showingCheapest: 'Günstigster Flug angezeigt',
  option: 'Möglichkeit',
  options: 'Möglichkeiten',
  moreFilters: 'Filter anzeigen',
  hideFilters: 'Filter ausblenden',
  showTips: 'Tipps zeigen',
  hideTips: 'Tipps ausblenden',
  estimatedOnly:
    'N.B. Nur die niedrigsten, voraussichtlichen Preise. Gefunden in den letzten 15 Tagen.',
}
