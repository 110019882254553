export default {
  confirm: 'Confirm Password',
  email: 'Email address',
  name: 'Name',
  password: 'Password',
  register: 'Register',
  registerText:
    'Registration only takes a minute and allows you to receive email notifications of flight prices. You can cancel at any time...',
  registerSuccess: 'You have registered successfully.',
  registerSubmitting: 'Please wait...',
  update: 'Update',
  updateSubmitting: 'Updating...',
  delete: 'Delete',
  deleteSubmitting: 'Deleting',
  forgot: 'Forgotten your password?',
  login: 'Login',
  loginSubmitting: 'Logging in...',
  remember: 'Remember me',
  tryAgain: 'Please try again...',
  serverConnection: 'Unable to connect to server',
  failed: 'These credentials do not match our records.',
  throttle: 'Too many login attempts. Please try again in :seconds seconds.',
  passwordLength:
    'Passwords must be at least six characters and match the confirmation.',
  reset: 'Your password has been reset!',
  sent: 'We have e-mailed your password reset link!',
  token: 'This password reset token is invalid.',
  user: "We can't find a user with that e-mail address.",
  contactUs: 'Contact us',
  contactText:
    'Have you spotted an error? Is there anything you would like us to improve?',
  drop: "Drop us an email and we'll do our best to help!",
  message: 'Your message',
  contact: 'Send message',
  contactSubmitting: 'Sending message',
}
