export default {
  to: 'bis',
  of: 'von',
  results: 'Ergebnissen',
  flightDetails: 'Deine Flugübersicht',
  show: '',
  more: 'Weitere',
  fewer: 'Weniger',
  ofResults: 'Ergebnisse',
  anzeigen: 'anzeigen',
}
