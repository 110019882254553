import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize,
} from 'vee-validate'
import { required, email, confirmed, min } from 'vee-validate/dist/rules'
import en from 'vee-validate/dist/locale/en.json'
import da from 'vee-validate/dist/locale/da.json'
import de from 'vee-validate/dist/locale/de.json'
import es from 'vee-validate/dist/locale/es.json'
import fr from 'vee-validate/dist/locale/fr.json'
import it from 'vee-validate/dist/locale/it.json'
import no from 'vee-validate/dist/locale/nb_NO.json'
import nl from 'vee-validate/dist/locale/nl.json'
import pt from 'vee-validate/dist/locale/pt_PT.json'
import sv from 'vee-validate/dist/locale/sv.json'

extend('required', {
  ...required,
  message: 'This field is required',
})

extend('email', {
  ...email,
  message: 'Please enter your email address',
})

extend('confirmed', {
  ...confirmed,
  message: 'Please confirm your password',
})

extend('min', {
  ...min,
  message: 'Please enter minimum of 10 characters',
})

localize({
  en,
  da,
  de,
  es,
  fr,
  it,
  no,
  nl,
  pt,
  sv,
})

export function validationObserver() {
  return ValidationObserver
}

export function validationProvider() {
  return ValidationProvider
}
