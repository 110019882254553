export default {
  yourAccount: 'Jouw rekening',
  profile: 'profiel',
  Profile: 'Profiel',
  savedSearch: 'opgeslagen zoekopdracht',
  savedSearches: 'Bewaarde zoekopdrachten',
  congratulations: 'Hartelijk gefeliciteerd!',
  updatedProfile: 'Je hebt je profiel succesvol geupdate',
  deletedProfile: 'Je hebt je profiel succesvol verwijderd',
  loggedInAs: 'Hallo, je bent ingelogd als',
  loggedInAsGerman: '.',
  editYour: 'U kunt uw',
  editYourGerman: '',
  orSetup: ', of stel een',
  orSetupGerman: 'op',
  weWill:
    'en wij zullen u de laatste vluchtprijzen waaraan u geïnteresseerd bent, emailen.',
  noSavedSearches: 'U heeft momenteel geen opgeslagen zoekopdrachten.',
  toSetUp: 'Om een op te stellen, maak gewoon een zoekopdracht op de',
  toSetUpGerman: '',
  searchPage: 'zoekpagina',
  clickSave: ' en klik vervolgens op de',
  saveButton: 'Zoekopdracht opslaan',
  resultsPage: 'op de resultatenpagina.',
  thankYou: 'Dank je!',
  sentSuccess: 'Uw bericht is succesvol verzonden!',
}
