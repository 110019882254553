export default {
  filter: 'Filtrer',
  sort: 'Trier',
  checked: '',
  of: 'de',
  searching: 'Recherche en cours de', //
  providers: 'fournisseurs vérifiés',
  results: 'résultats',
  collating: 'Assemblage de données',
  topTen: 'Affichage des premiers 10 résultats',
  showAll: 'afficher tous',
  appliedFilters: 'Nous avons appliqué vos filtres.',
  combine: 'combinaisons de vol',
}
