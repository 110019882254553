export default {
  to: 'tot',
  of: 'van',
  results: 'resultaten',
  flightDetails: 'Details',
  show: '',
  more: 'Meer',
  fewer: 'Minder',
  ofResults: 'resultaten',
  anzeigen: 'weergeven',
}
