<template>
  <div class="flex flex-col items-start" data-test-settings-form>
    <label for="country" class="mt-4 form-label text-gray-500">
      {{ $t('localSettings.country') }}
    </label>
    <select
      id="country"
      v-model="form.settings.countryId"
      class="mt-1 form-control"
      data-test-country
      @change="setCountryDefaults"
    >
      <option
        v-for="country in countries"
        :key="country.id"
        :value="country.id"
      >
        {{ country.name }}
      </option>
    </select>

    <label for="language" class="mt-4 form-label text-gray-500">
      {{ $t('localSettings.language') }}
    </label>
    <select
      id="language"
      v-model="form.settings.languageId"
      class="mt-1 form-control"
      data-test-language
    >
      <option
        v-for="language in languages"
        :key="language.id"
        :value="language.id"
      >
        {{ language.name.substring(0, language.name.indexOf(' (')) }}
      </option>
    </select>

    <label for="currency" class="mt-4 form-label text-gray-500">
      {{ $t('localSettings.currency') }}
    </label>
    <select
      id="currency"
      v-model="form.settings.currencyId"
      class="mt-1 form-control"
      data-test-currency
    >
      <option
        v-for="currency in currencies"
        :key="currency.id"
        :value="currency.id"
      >
        {{ currency.id + ' - ' + currency.symbol }}
      </option>
    </select>

    <div class="mt-4 w-full">
      <div class="flex justify-end">
        <button
          class="btn btn-gray uppercase tracking-wider text-sm"
          data-test-cancel-button
          @click="$emit('close')"
        >
          {{ $t('navBar.cancel') }}
        </button>

        <button
          class="btn btn-green uppercase tracking-wider text-sm ml-2"
          data-test-save-button
          @click="save()"
        >
          {{ $t('navBar.save') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { loadLanguageAsync } from '@/i18n'

export default {
  data() {
    return {
      form: {
        settings: {
          countryId: '',
          currencyId: '',
          languageId: '',
        },
      },
    }
  },
  computed: {
    ...mapState(['countries', 'languages', 'currencies', 'settings']),
    ...mapGetters(['api', 'validLanguages']),
  },
  // watch: {
  //   settings (newSettings, oldSettings) {
  //     if (!oldSettings.countryId) {
  //       this.form.settings = { ...newSettings }
  //     }
  //   }
  // },
  created() {
    this.form.settings.countryId = this.settings.countryId
    this.form.settings.currencyId = this.settings.currencyId
    this.form.settings.languageId = this.settings.languageId
  },
  methods: {
    save() {
      const settings = this.form.settings

      loadLanguageAsync(settings.languageId.substring(0, 2))

      // Save settings in local storage
      try {
        localStorage.setItem('settings', JSON.stringify(settings))
      } catch (e) {
        // console.log('Local storage quota exceeded!')
      }

      // Update user profile
      this.$axios
        .put(`${this.api}/me/update-profile`, { settings })
        .then((response) => {
          this.$store.dispatch('saveSettings', settings)
          this.$store.commit('setCountries', response.data.data)
        })
        .catch((error) => {
          this.$store.commit('setError', error.response)
          this.$store.commit('setStatus', 'Error')
        })

      this.$emit('close')
    },
    setCountryDefaults(e) {
      const newCountry = this.countries[e.target.value]

      this.form.settings.countryId = newCountry.id
      this.form.settings.languageId = this.validLanguages.includes(
        newCountry.language_id,
      )
        ? newCountry.language_id
        : 'en-GB'
      this.form.settings.currencyId = newCountry.currency_id
    },
  },
}
</script>
