export default {
  save: 'Enregistrer',
  cancel: 'Annuler',
  contact: 'Contacte',
  direct: 'Vols directs',
  deals: 'Acceuil',
  logout: 'Déconnecter',
  london_gatwick: 'Londres Gatwick',
  preferences: 'Préférences',
  search: 'Recherche de vols',
  title: 'Vols à destination de Gambie',
  description:
    'La ressource la plus complète du monde pour trouver des vols vers la Gambie',
}
