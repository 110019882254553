export default {
  AMS: 'Amsterdam',
  BHX: 'Birmingham',
  LGW: 'London Gatwick',
  MAN: 'Manchester',
  BCN: 'Barcelona',
  BRU: 'Brüssel',
  LPA: 'Gran Canaria',
  DKRA: 'Dakar',
  FRA: 'Frankfurt',
  DUS: 'Düsseldorf',
  CDG: 'Paris',
  MUC: 'München',
  return: 'Hin-und Rückflug',
  oneWay: 'Nur Hinflug',
  topDeals: 'Heutige Sonderangebote',
  welcome:
    'Die umfassendste Ressource des Internets für günstige Flüge nach Gambia!',
  explain_1:
    'Hinweis: Dieses Projekt ist/war ein Work-in-Progress und sollte nur als solches betrachtet werden. Ich habe es ursprünglich als Standard-Laravel-Anwendung geschrieben, aber dann Front- und Backend entkoppelt, sodass es jetzt ein Vue-CLI-Projekt ist, das von einer Laravel-API unterstützt wird.',
  explain_2:
    'Ich war halbwegs dabei, das Frontend von Bootstrap zu Tailwind CSS zu migrieren, daher ist das Styling an manchen Stellen ziemlich zäh. Da ich den Web-Scraper, der diese Skyscanner-Ergebnisse mit meinen eigenen ergänzt hat, abgeschaltet habe, werden Sie den Hauptnutzen der Website nicht erleben können. Aber hoffentlich gibt es Ihnen immer noch einen Vorgeschmack darauf, worum es ging!',
  readMore: 'Weiterlesen',
  readLess: 'Weniger lesen',
}
