export default {
  sortBy: 'Trier par',
  score: 'Le meilleur',
  price: "Le moins cher d'abord",
  duration: "Le plus rapide d'abord",
  scoreName: 'Le meilleur',
  priceName: 'Le moins cher',
  durationName: 'Le plus rapide',
  departure: 'Heure de départ',
  arrival: "Heure d'arrivée",
  outboundDeparture: 'Aller : heure de départ',
  outboundArrival: "Aller : heure d'arrivée",
  inboundDeparture: 'Retour : heure de départ',
  inboundArrival: "Retour : heure d'arrivée",
  airline: 'Compagnie aérienne',
  stops: 'Escales',
  average: 'en moyenne',
  bestTip:
    "Nous pensons que ces vols offrent la meilleure combinaison entre le <strong>prix</strong> et d'autres facteurs tels que la <strong>durée</strong> du voyage et le <strong>nombre d'escales<strong>.",
  cheapestTip: 'Trier par prix le plus bas.',
  durationTip: 'Trier par durée la plus courte.',
}
