export default {
  connection: 'Transittflyplass',
  longWait: 'Lang ventetid',
  h: 't',
  min: 'm',
  change: 'Endre flyplass',
  in: 'i',
  from: 'fra',
  to: 'til',
  stayOnPlane: 'Hold deg ombord på flyet',
  unknown: 'Tilkoblingsflyplass (ukjent)',
}
