export default {
  connection: 'Escala en aeropuerto',
  longWait: 'Espera larga',
  h: ' h',
  min: ' min',
  change: 'Cambiar el aeropuerto',
  in: 'en',
  from: 'de',
  to: 'a',
  stayOnPlane: 'Permanecer a bordo del avión',
  unknown: 'Aeropuerto de conexión (desconocido)',
}
