export default {
  save: 'Guardar',
  cancel: 'Cancelar',
  contact: 'Contate-Nos',
  direct: 'Voos diretos',
  deals: 'Melhores negócios',
  logout: 'Sair',
  london_gatwick: 'Londres Gatwick',
  preferences: 'Preferências',
  search: 'Procurar',
  title: 'Voos para Gâmbia',
  description: 'O recurso mais abrangente do mundo para vôos para a Gâmbia',
}
