export default {
  loading: 'Caricamento in corso',
  notAvailable: 'Siamo spiacenti, questa tariffa non è più disponibile',
  soldOut: 'Il biglietto potrebbe essere esaurito.',
  refresh: 'Aggiorna i risultati',
  check: 'Controlla il tuo itinerario',
  timesLocal: 'tutti gli orari sono locali',
  departingFrom: 'Partirai da',
  arrivingAt: 'Arriverai a',
  returningTo: 'ma tornerai a',
  returningFrom: 'ma tornerai da',
  zuruck: '',
  bookTicket: 'Prenota i tuoi biglietti',
  readBefore: 'Leggi prima di prenotare',
  pricesShown:
    'I prezzi visualizzati includono sempre tutte le tasse e gli addebiti obbligatori, ma ricordati di',
  checkAll:
    'controllare TUTTI i dettagli del biglietto, i costi finali e i termini e condizioni',
  beforeBook: 'sul sito fornitore prima di prenotare.',
  extraFees: 'Controlla costi extra',
  someAirlines:
    'Alcune compagnie aeree o agenzie prevedono ulteriori addebiti per ',
  baggage: 'bagagli',
  insurance: 'assicurazione',
  orUseOf: 'o',
  creditCards: 'carte di credito',
  view: 'Guarda le',
  airlineFees: 'tasse delle compagnie aeree',
  an: '.',
  checkTC: 'Controlla i Termini e condizioni per i viaggiatori di anni 12-16',
  restrictions:
    'Possono esserci delle restrizioni su minori che viaggiano non accompagnati.',
  adults: 'adulti',
  adult: 'adulto',
  children: 'bambini',
  child: 'bambino',
  infants: 'neonati',
  infant: 'neonato',
  combine: 'Combina 2 voli singoli',
}
