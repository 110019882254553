export default {
  connection: 'Connect in airport',
  longWait: 'Long wait',
  h: 'h',
  min: '',
  change: 'Change airport',
  in: 'in',
  from: 'from',
  to: 'to',
  stayOnPlane: 'Stay onboard the aircraft',
  unknown: 'Connection airport (unknown)',
}
