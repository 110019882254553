<template>
  <div class="mr-1">
    <label
      :tabindex="tabIndex"
      class="form-label uppercase tracking-wider inline-block border-t border-r"
      :class="tripClass"
      @focus.prevent
      @keyup.space="selectTripType"
      @keyup.enter.prevent="selectTripType"
      @click="selectTripType"
    >
      {{ buttonText }}
    </label>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'TripTypeButton',
  props: {
    tripType: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(['criteria']),
    buttonText() {
      return this.isReturn
        ? this.$t('searchControlsContainer.return')
        : this.$t('searchControlsContainer.oneway')
    },
    isReturn() {
      return this.tripType === 'roundTrip'
    },
    isSelected() {
      return (
        (this.criteria.tripType === 'roundTrip' && this.isReturn) ||
        (this.criteria.tripType === 'one-way' && !this.isReturn)
      )
    },
    tabIndex() {
      return this.isSelected ? -1 : 0
    },
    tripClass() {
      let tripClass = this.isReturn
        ? 'border-l rounded-tl-lg py-2 px-4'
        : 'rounded-tr-lg py-2 px-4'

      this.isSelected
        ? (tripClass += ' bg-green-500 text-white focus:outline-none')
        : (tripClass +=
            ' cursor-pointer focus:outline-none focus:shadow-outline bg-gray-100 text-gray-700 hover:bg-white active:bg-gray-300 active:text-gray-800')
      return tripClass
    },
  },
  methods: {
    selectTripType() {
      this.$store.commit('setTripType', this.isReturn ? 'roundTrip' : 'one-way')

      this.$emit('search-deals')
    },
  },
}
</script>
