export default {
  NB: 'Por favor nota',
  nextTip: 'Siguiente consejo',
  previousTip: 'Consejo anterior',
  lastTip: 'Último consejo',
  tip_1:
    "Esta 'vista mensual' ofrece una visión general de los precios de vuelo más baratos encontrados en los " +
    'últimos 15 días. Los precios pueden haber cambiado, por lo que para obtener las últimas ofertas deberá realizar ' +
    'una búsqueda en vivo haciendo clic en el botón ',
  button: '.',
  tip_2: 'Use la pestaña',
  toShow: 'para mostrar',
  restrict:
    ' o para restringir la duración y / o las fechas de primera salida o de último retorno. ',
  cycle:
    'Puede desplazarse por los resultados restantes utilizando los botones ',
  anzuzeigen: '',
  orClick: '(o hacer clic en las fechas directamente)',
  tip_3:
    'Una vez que haya encontrado las fechas adecuadas, puede hacer clic en el botón ',
  liveSearch:
    'para realizar una búsqueda en vivo de las últimas ofertas y precios de las fechas elegidas',
  letsGo: '¡OK vamos!',
}
