export default {
  getPriceAlerts: 'Preismeldungen erhalten',
  airlines: 'Fluglinien',
  combination: 'Fluglinien Kombinationen',
  airports: 'Flughäfen',
  stops: 'Stopps',
  direct: 'Direct',
  oneStop: '1 Stopp',
  twoPlusStops: '2+ Stopps',
  none: 'Keine',
  selectAll: 'Alle auswählen',
  clearAll: 'Alle löschen',
  flyOut: '',
  flyOutGerman: ' für Hin- und Rückflug',
  sameAirports: 'Gleiche Flughäfen',
  no: 'Kein',
  changeAirport: 'Flughafenwechsel',
  duringConnections: 'für Anschlussflüge',
  departFrom: 'Abflug von',
  arriveAt: 'Ankunft in',
  journeyDuration: 'Reisezeit',
  hours: 'Stunden',
  departureTimes: 'Reisezeiten',
  arrivalTimes: 'Ankunftzeiten',
  outbound: 'Abreise',
  inbound: 'Rückreise',
}
