export default {
  NB: 'A savoir',
  nextTip: 'Conseil suivant',
  previousTip: 'Conseil précédent',
  lastTip: 'Dernier conseil',
  tip_1:
    'Cette «vue mensuelle» donne un aperçu des prix des vols les moins chers trouvés au cours des 15 derniers jours. ' +
    'Les prix peuvent avoir changé, donc pour obtenir les dernières offres, vous devrez effectuer une recherche ' +
    'en direct en cliquant sur le bouton ',
  button: '.',
  tip_2: "Utilisez l'onglet",
  toShow: 'pour afficher les',
  restrict:
    ' ou pour limiter la durée et / ou la date de départ le plus tôt / le dernier retour.',
  cycle:
    'Vous pouvez ensuite faire défiler les résultats restants en utilisant les boutons ',
  anzuzeigen: '',
  orClick: '(ou cliquez directement sur les dates)',
  tip_3:
    'Une fois que vous avez trouvé les dates appropriées, vous pouvez cliquer sur le bouton',
  liveSearch:
    'pour effectuer une recherche en direct des dernières offres et des prix pour les dates choisies',
  letsGo: 'Allons-y!',
}
