export default function (Vue) {
  Vue.auth = {
    setToken(token, expiration) {
      try {
        localStorage.setItem('token', token)
        localStorage.setItem('expiration', expiration)
      } catch (e) {
        // console.log('Local storage quota exceeded!')
      }
    },

    getToken() {
      const token = localStorage.getItem('token')
      const expiration = localStorage.getItem('expiration')

      if (!token || !expiration) {
        return null
      }

      if (Date.now() > parseInt(expiration)) {
        this.destroyToken()
        return null
      } else {
        return token
      }
    },

    destroyToken() {
      localStorage.removeItem('token')
      localStorage.removeItem('expiration')
    },

    isAuthenticated() {
      if (this.getToken()) {
        return true
      } else {
        return false
      }
    },
  }

  Object.defineProperties(Vue.prototype, {
    $auth: {
      get: () => {
        return Vue.auth
      },
    },
  })
}
