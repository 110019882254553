export default {
  airlineFees: 'Flyselskapenes avgifter',
  previousPage: 'Tilbake til forrige side',
  pricesInclude:
    'Prisene hos Skyscanner inkluderer alltid en beregning av alle obligatoriske skatter og avgifter, men noen flyselskaper / reisebyråer legger til ekstra avgifter for ',
  baggage: 'bagasje',
  insurance: 'forsikring',
  useOf: 'bruk av ',
  creditCards: 'kredittkort',
  other: 'eller andre tjenester',
  findDetails:
    'Du kan se informasjon om avgifter for hvert enkelt flyselskap nedenfor:',
}
