import { mapState, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapState(['criteria', 'filters', 'browse']),
    ...mapGetters([
      'isRoundTrip',
      'filteredQuotes',
      'filteredQuotesCount',
      'country',
      'language',
      'currency',
    ]),
    inboundDate() {
      return this.criteria.inboundDate
    },
    inboundFirstDayOfMonth() {
      return this.$moment(this.criteria.inboundDate).startOf('month')
    },
    inboundLastDayOfMonth() {
      return this.$moment(this.criteria.inboundDate).endOf('month')
    },
    inboundLatest() {
      if (this.filters.outboundEarliest) {
        return this.filters.inboundLatest
      }

      return this.inboundLastDayOfMonth.format('YYYY-MM-DD')
    },
    outboundDate() {
      return this.criteria.outboundDate
    },
    outboundEarliest() {
      if (this.filters.outboundEarliest) {
        return this.filters.outboundEarliest
      }

      return this.outboundFirstDayOfMonth.format('YYYY-MM-DD')
    },
    outboundFirstDayOfMonth() {
      return this.$moment(this.criteria.outboundDate).startOf('month')
    },
    outboundLastDayOfMonth() {
      return this.$moment(this.criteria.outboundDate).endOf('month')
    },
    showFilters: {
      get() {
        return this.filters.showFilters
      },
      set() {},
    },
    showTips: {
      get() {
        return this.user.tipCounter > -1
      },
      set() {},
    },
    today() {
      return this.$moment(this.$moment().format('YYYY-MM-DD'))
    },
  },
  methods: {
    selectCheapest(val) {
      if (this.filteredQuotesCount === 0) {
        // No results - so set highlighted dates to criteria dates
        this.selectOutboundDate(this.criteria.outboundDate)
        if (this.isRoundTrip) {
          this.selectInboundDate(this.criteria.inboundDate)
        }
        return
      }

      const browseId = val === 0 ? 0 : this.browse.browseId + val

      if (browseId > this.filteredQuotesCount) {
        return
      }

      this.$store.commit('setBrowseId', browseId)

      if (this.isRoundTrip) {
        this.selectOutboundDate(this.filteredQuotes[browseId].outboundDate)
        this.selectInboundDate(this.filteredQuotes[browseId].inboundDate)
      } else {
        this.selectOutboundDate(this.filteredQuotes[browseId].outboundDate)
      }
    },
    // selectInboundDate (date) {
    //     this.$store.commit ('setInboundDate', this.$moment(date).format('YYYY-MM-DD'))
    //
    //     this.updateOutboundDate()
    //     this.updateNights()
    //     this.updateBrowseId()
    // },
    selectInboundDate(date) {
      if (date === 'anytime') {
        this.$store.commit('setInboundDate', 'anytime')
        this.$store.commit('setOutboundDate', 'anytime')
        return
      }

      this.$store.commit(
        'setInboundDate',
        this.$moment(date).format('YYYY-MM-DD'),
      )
      this.updateOutboundDate()
      this.updateBrowseId()
    },
    selectInboundLatest(date) {
      if (!this.isRoundTrip) {
        return
      }

      const dateAsMoment = this.$moment(date)
      date = dateAsMoment.format('YYYY-MM-DD')

      if (dateAsMoment.isBefore(this.inboundFirstDayOfMonth)) {
        if (dateAsMoment.isBefore(this.$moment(this.outboundEarliest))) {
          this.selectOutboundEarliest(
            dateAsMoment.startOf('month').format('YYYY-MM-DD'),
          )
        }

        this.$store.commit('setInboundDate', date)
        this.$store.commit('setInboundLatest', date)

        return this.browseSearch()
      }

      if (dateAsMoment.isAfter(this.inboundLastDayOfMonth)) {
        this.selectInboundDate(date)
        this.$store.commit('setInboundLatest', date)

        return this.browseSearch()
      }

      if (dateAsMoment.isBefore(this.outboundEarliest)) {
        this.$store.commit('setOutboundEarliest', date)
      }

      if (dateAsMoment.isBefore(this.inboundDate)) {
        this.selectInboundDate(date)
      }

      this.$store.commit('setInboundLatest', date)
      this.selectCheapest(0)
    },
    // selectOutboundDate (date) {
    //     this.$store.commit ('setOutboundDate', this.$moment(date).format('YYYY-MM-DD'))
    //
    //     this.updateInboundDate()
    //     this.updateNights()
    //     this.updateBrowseId()
    // },
    selectOutboundDate(date) {
      if (date === 'anytime') {
        this.$store.commit('setOutboundDate', 'anytime')
        this.$store.commit('setInboundDate', 'anytime')
        return
      }

      this.$store.commit(
        'setOutboundDate',
        this.$moment(date).format('YYYY-MM-DD'),
      )
      this.updateInboundDate()
      this.updateBrowseId()
    },
    selectOutboundEarliest(date) {
      let dateAsMoment = this.$moment(date)
      date = dateAsMoment.format('YYYY-MM-DD')

      if (dateAsMoment.isBefore(this.today)) {
        date = this.today.format('YYYY-MM-DD')
        dateAsMoment = this.today

        this.$store.commit(
          'setOutboundEarliest',
          dateAsMoment.format('YYYY-MM-DD'),
        )
      }

      if (dateAsMoment.isAfter(this.outboundLastDayOfMonth)) {
        this.selectOutboundDate(date)

        if (
          this.isRoundTrip &&
          dateAsMoment.isAfter(this.$moment(this.inboundLatest))
        ) {
          this.selectInboundLatest(
            dateAsMoment.endOf('month').format('YYYY-MM-DD'),
          )
        }

        this.$store.commit('setOutboundEarliest', date)

        return this.browseSearch()
      }

      if (dateAsMoment.isBefore(this.outboundFirstDayOfMonth)) {
        this.selectOutboundDate(date)
        this.$store.commit('setOutboundEarliest', date)

        return this.browseSearch()
      }

      if (this.isRoundTrip) {
        if (dateAsMoment.isAfter(this.inboundLatest)) {
          this.$store.commit('setInboundLatest', date)
        }

        if (dateAsMoment.isAfter(this.inboundDate)) {
          this.selectOutboundDate(date)
        }
      }

      this.$store.commit('setOutboundEarliest', date)
      this.selectCheapest(0)
    },
    updateBrowseId() {
      for (let i = 0; i < this.filteredQuotes.length; i++) {
        const quote = this.filteredQuotes[i]

        if (this.isRoundTrip) {
          if (
            this.outboundDate === quote.outboundDate &&
            this.inboundDate === quote.inboundDate
          ) {
            return this.$store.commit('setBrowseId', i)
          }
        } else {
          if (this.outboundDate === quote.outboundDate) {
            return this.$store.commit('setBrowseId', i)
          }
        }
      }

      this.$store.commit('setBrowseId', 0)
    },
    updateInboundDate() {
      if (!this.isRoundTrip) {
        return
      }

      const outboundDate = this.criteria.outboundDate
      const inboundDate = this.criteria.inboundDate

      if (inboundDate === 'anytime') {
        this.$store.commit('setInboundDate', outboundDate)
      }

      if (inboundDate === null || inboundDate < outboundDate) {
        this.selectInboundDate(outboundDate)
      }
    },
    updateNights() {
      if (!this.isRoundTrip) {
        return
      }

      if (
        this.filters.nights.min !== 0 &&
        this.nights < this.filters.nights.min
      ) {
        this.$store.commit('setMinNights', this.nights)
      }

      if (
        this.filters.nights.max !== 0 &&
        this.nights > this.filters.nights.max
      ) {
        this.$store.commit('setMaxNights', this.nights)
      }
    },
    updateOutboundDate() {
      const outboundDate = this.criteria.outboundDate
      const inboundDate = this.criteria.inboundDate

      if (outboundDate === 'anytime' || outboundDate > inboundDate) {
        this.$store.commit('setOutboundDate', inboundDate)
      }
    },
  },
}
