<template>
  <Portal selector="#modals">
    <Transition name="fade">
      <div
        v-if="showing"
        class="pt-4 fixed top-0 bg-semi-75 inset-0 z-30"
        :class="backgroundClasses"
        @click.self="$emit('close')"
      >
        <div :class="cardClasses">
          <header
            class="
              flex
              justify-between
              items-center
              px-6
              py-2
              bg-white
              rounded-t-lg
            "
          >
            <div class="mt-2 text-2xl font-semibold">
              <slot name="header"></slot>
            </div>

            <button
              aria-label="close"
              class="-mr-4"
              @click.prevent="$emit('close')"
            >
              <InlineSvg
                name="icon-x-circle"
                classes="current-fill text-gray-500 h-8 w-8"
              ></InlineSvg>
            </button>
          </header>

          <hr class="m-0 text-blue-500" />

          <div class="px-6 pb-6">
            <slot />
          </div>
        </div>
      </div>
    </Transition>
  </Portal>
</template>

<script>
export default {
  props: {
    backgroundClasses: {
      type: String,
      default: '',
    },
    cardClasses: {
      type: String,
      default: '',
    },
    showing: {
      required: true,
      type: Boolean,
    },
  },
  watch: {
    showing: {
      immediate: true,
      handler: function (showing) {
        if (showing) {
          return document.querySelector('body').classList.add('overflow-hidden')
        }

        document.querySelector('body').classList.remove('overflow-hidden')
      },
    },
  },
  created() {
    const escapeHandler = (e) => {
      if (e.key === 'Escape' && this.showing) {
        this.$emit('close')
      }
    }
    document.addEventListener('keydown', escapeHandler)
    this.$once('hook:destroyed', () => {
      document.removeEventListener('keydown', escapeHandler)
    })
  },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.4s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
