export default {
  save: 'Opslaan',
  cancel: 'Annuleren',
  contact: 'Contact',
  direct: 'Directe vluchten',
  deals: 'Beste aanbiedingen',
  logout: 'Uitloggen',
  london_gatwick: 'Londen Gatwick',
  preferences: 'Regionale instellingen',
  search: 'Zoeken',
  title: 'Vluchten naar Gambia',
  description: 'De meest uitgebreide bron ter wereld voor vluchten naar Gambia',
}
