export default {
  confirm: 'Passwort bestätigen',
  email: 'E-Mail-Addresse',
  name: 'Name',
  password: 'Passwort',
  register: 'Neu registrieren',
  registerText:
    'Die Anmeldung dauert nur eine Minute und erlaubt dir, E-Mail-Benachrichtigungen über Flugpreise zu erhalten. Du kannst jederzeit abbrechen...',
  registerSuccess: 'Du hast dich erfolgreich registriert.',
  registerSubmitting: 'Einen Moment...',
  update: 'Aktualisieren',
  updateSubmitting: 'Aktualisierung...',
  delete: 'Löschen',
  deleteSubmitting: 'Löschen...',
  forgot: 'Passwort vergessen?',
  login: 'Anmeldung',
  loginSubmitting: 'Anmeldung...',
  remember: 'Behalte mich in Erinnerung',
  tryAgain: 'Bitte versuche es erneut...',
  serverConnection: 'Die Verbindung zum Server kann nicht hergestellt werden',
  failed: 'Diese Zugangsdaten wurden nicht in unserer Datenbank gefunden.',
  throttle:
    'Zu viele Loginversuche. Versuchen Sie es bitte in :seconds Sekunden nochmal.',
  passwordLength:
    'Passwörter müssen mindestens 6 Zeichen lang sein und korrekt bestätigt werden.',
  reset: 'Das Passwort wurde zurückgesetzt!',
  sent: 'Passworterinnerung wurde gesendet!',
  token:
    'Der Passwort-Wiederherstellungs-Schlüssel ist ungültig oder abgelaufen.',
  user: 'Es konnte leider kein Nutzer mit dieser E-Mail-Adresse gefunden werden.',
  contactUs: 'Contact us',
  contactText:
    'Have you spotted an error? Is there anything you would like us to improve?',
  drop: "Drop us an email and we'll do our best to help!",
  message: 'Your message',
  contact: 'Send message',
  contactSubmitting: 'Sending message',
}
