export default {
  NB: 'Let op:',
  nextTip: 'Volgende tip',
  previousTip: 'Vorige tip',
  lastTip: 'Laatste tip',
  tip_1:
    "Deze 'maandelijkse weergave' biedt een overzicht van de goedkoopste vluchtprijzen die zijn gevonden in " +
    'de afgelopen 15 dagen. De prijzen zijn mogelijk gewijzigd, dus als u de nieuwste aanbiedingen wilt ontvangen, ' +
    'moet u een live zoekopdracht uitvoeren door op de knop ',
  button: '.',
  tip_2: 'Gebruik het tabblad',
  toShow: 'om',
  restrict:
    ' weer te geven of om de duur en / of vroegste vertrek / laatste terugkeer te beperken.',
  cycle: 'U kunt de resterende resultaten doorlopen met de knoppen ',
  anzuzeigen: ' weer te geven',
  orClick: '(of direct op de datums klikken)',
  tip_3: 'Zodra u geschikte datums hebt gevonden, kunt u klikken op de knop',
  liveSearch:
    'om een live zoekopdracht uit te voeren naar de nieuwste aanbiedingen en prijzen voor de door u gekozen datums',
  letsGo: 'OK, laten we gaan!',
}
