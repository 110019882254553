export default {
  connection: 'Coincidenza in aeroporto',
  longWait: 'Scalo di lunga durata',
  h: 'h',
  min: 'min',
  change: 'Cambia aeroporto',
  in: 'a',
  from: 'da',
  to: 'a',
  stayOnPlane: "Rimani a bordo dell'aeromobile",
  unknown: 'Aeroporto di collegamento (sconosciuto)',
}
