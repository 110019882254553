<template>
  <DropDown
    :options="options"
    :selected-option-index="selectedOptionIndex"
    :place-holder="placeHolder"
    @set-selected-option="setNewSelectedOption($event)"
  ></DropDown>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import DropDown from './DropDown'

export default {
  name: 'UserDropdownButton',
  components: {
    DropDown,
  },
  data() {
    return {
      isSubmitting: false,
      options: [
        {
          element: 'router-link',
          icon: 'home',
          to: 'home',
          value: this.$t('user.yourAccount'),
        },
        {
          element: 'router-link',
          icon: 'cog',
          to: 'admin',
          value: 'Application settings',
        },
        {
          element: 'router-link',
          icon: 'user',
          to: 'profile',
          value: this.$t('user.Profile'),
        },
        {
          element: 'router-link',
          icon: 'list',
          to: 'price-alerts',
          value: this.$t('user.savedSearches'),
        },
        {
          element: 'router-link',
          icon: 'sign-out-alt',
          to: 'logout',
          value: this.$t('navBar.logout'),
        },
      ],
      placeHolder: '',
    }
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters(['locale']),
    selectedOptionIndex() {
      for (let i = 0; i < this.options.length; i++) {
        if (`/${this.locale}/${this.options[i].to}` === this.$route.path) {
          return i
        }
      }

      return 0
    },
  },
  created() {
    this.placeHolder = this.user.name || 'Mark Thompson'
    this.setCurrentUser()
  },
  methods: {
    getCountries(locale) {
      this.$axios
        .get(`/api/${locale}/countries`)
        .then((response) => {
          this.$store.commit('setCountries', response.data.data)
        })
        .catch((error) => {
          // console.log(error)

          this.$store.commit('setError', error.response)
          this.$store.commit('setStatus', 'Error')
        })
    },
    logout() {
      this.$auth.destroyToken()
      this.$store.commit('setAuthenticatedUser', { id: 0 })
      this.$router.push(`/${this.locale}/login`)
    },
    setNewSelectedOption(e) {
      if (e.to === 'logout') {
        this.logout()
      }

      this.$router.push(`/${this.locale}/${e.to}`)
    },
    setCurrentUser(loginType) {
      if (!this.$auth.getToken()) return

      this.$axios
        .get('/api/me')
        .then((response) => {
          const user = response.data.data
          const locale = user.locale.substring(0, 2)
          const settings = {
            countryId: user.country_code,
            locale: user.locale,
            currencyId: user.currency_id,
          }

          this.$store.commit('setAuthenticatedUser', user)
          this.$store.dispatch('saveSettings', settings)

          localStorage.setItem('settings', JSON.stringify(settings))

          if (loginType === 'register') {
            this.$swal(
              this.$t('user.congratulations'),
              this.$t('auth.registerSuccess'),
              'success',
            )
          }

          if (loginType) {
            this.$router.push('/' + locale + '/home')
          }
        })
        .catch((error) => {
          if (error.response) {
            this.$swal(
              '2: ' + error.response.status.toString(),
              error.response.data.message,
              'error',
            )
          }
        })
    },
  },
}
</script>
