export default {
  save: 'Speichern',
  cancel: 'Abbrechen',
  contact: 'Kontakt',
  direct: 'Direkte Flüge',
  deals: 'Top Angebote',
  logout: 'Abmeldung',
  london_gatwick: 'London Gatwick',
  preferences: 'Einstellungen',
  search: 'Flugsuche',
  title: 'Flüge nach Gambia',
  description: 'Die weltweit umfassendste Ressource für Flüge nach Gambia',
}
