export default {
  return: 'Return',
  oneway: 'One way',
  from: 'From',
  To: 'To',
  to: 'to',
  placeholder: 'Enter a country, city or airport',
  nearby: 'Add nearby airports',
  swap: 'Click here to swap locations',
  outbound: 'Depart',
  inbound: 'Return',
  classpax: 'Cabin Class & Travellers',
  done: 'Done',
  cabin: 'Cabin class',
  travellers: 'Travellers',
  adult: 'Adult',
  Economy: 'Economy',
  PremiumEconomy: 'Premium Economy',
  Business: 'Business class',
  First: 'First class',
  EconomyClass: 'Economy class',
  PremiumEconomyClass: 'Premium Economy class',
  BusinessClass: 'Business class',
  FirstClass: 'First class',
  adults: 'Adults',
  children: 'Children',
  over16: '16+ years',
  to15: '0 to 15 years',
  childAge: 'Age of child',
  validAge:
    'Your age at time of travel must be valid for the age category booked. Airlines have restrictions on under 18s travelling alone.',
  ageLimits:
    'Age limits and policies for travelling with children may vary so please check with the airline before booking.',
  directOnly: 'Direct flights only',
  search: 'Search flights',
  prev: 'Prev',
  next: 'Next',
  changeSearch: 'Change search',
  cheapestMonth: 'Cheapest month',
  specificDate: 'Specific date',
  flexibleDate: 'Whole month',
  wholeMonth: 'Show whole month',
  showSpecific: 'Show specific date',
  enterDate: 'Enter a date',
}
