export default {
  return: 'Ida y vuelta',
  oneway: 'Sólo ida',
  from: 'Desde',
  To: 'A',
  to: 'a',
  placeholder: 'País, ciudad o aeropuerto',
  nearby: 'Añade aeropuertos cercanos',
  swap: 'Haga clic aquí para intercambiar las ubicaciones',
  outbound: 'Salida',
  inbound: 'Regreso',
  classpax: 'Clase de cabina y viajeros',
  done: 'Listo',
  cabin: 'Clase',
  travellers: 'viageros',
  adult: 'adulto',
  Economy: 'Turista',
  PremiumEconomy: 'Turista superior',
  Business: 'Business',
  First: 'Primera',
  EconomyClass: 'Clase turista',
  PremiumEconomyClass: 'Turista superior',
  BusinessClass: 'Business',
  FirstClass: 'Primera',
  adults: 'Adultos',
  children: 'Niños',
  over16: '16 años o más',
  to15: 'De 0 a 15 años',
  childAge: 'Edad del niño',
  validAge:
    'La edad que tengas a la hora de viajar tiene que coincidir con la categoría de edad que hayas reservado. Las aerolíneas aplican restricciones a los menores de 18 años que viajan solos.',
  ageLimits:
    'Las políticas de límite de edad para viajar con niños pueden variar; consúltalo con la aerolínea antes de hacer la reserva.',
  directOnly: 'TuristaSolo vuelos directos',
  search: 'Buscar',
  prev: 'Anterior',
  next: 'Siguiente',
  changeSearch: 'Cambiar búsqueda',
  cheapestMonth: 'Mes más económico',
  specificDate: 'Fecha específica',
  flexibleDate: 'Mes completo',
  wholeMonth: 'Mostrar todo el mes',
  showSpecific: 'Mostrar Fecha específica',
  enterDate: 'Introduce una fecha',
}
