import Vue from 'vue'
import App from './App.vue'
import router from './router/'
import store from './store'
// import { i18n } from './i18n'
import { initializeVueI18n } from './i18n'
import { validationObserver, validationProvider } from './vee-validate'
import clickOutside from './directives/click-outside'
import loseFocus from './directives/lose-focus'
import VueSlideUpDown from 'vue-slide-up-down'

Vue.component('InlineSvg', require('./components/InlineSvg').default)
Vue.component('AppPrice', require('./components/AppPrice.vue').default)
Vue.component('CardModal', require('./components/CardModal.vue').default)
Vue.component('VueDropdown', require('./components/DropDown.vue').default)
Vue.component('PopOver', require('./components/popover/PopOver.vue').default)
Vue.component(
  'PrimaryButton',
  require('./components/PrimaryButton.vue').default,
)
Vue.component(
  'SecondaryButton',
  require('./components/SecondaryButton.vue').default,
)
Vue.component(
  'WithDimensions',
  require('./components/WithDimensions.vue').default,
)
Vue.component('VueSlideUpDown', VueSlideUpDown)
Vue.component('ValidationObserver', validationObserver)
Vue.component('ValidationProvider', validationProvider)

Vue.directive('clickOutside', clickOutside)
Vue.directive('loseFocus', loseFocus)

require('./bootstrap')

Vue.config.productionTip = false

initializeVueI18n().then((i18n) => {
  window.vm = new Vue({
    i18n,
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app')
})

// Vue.prototype.$scrollToTop = () => window.scrollTo(0, 0)
Vue.prototype.$scrollToTop = () =>
  document
    .getElementsByTagName('BODY')[0]
    .scrollIntoView({ behavior: 'smooth' })

// window.vm = new Vue({
//   i18n,
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app')
