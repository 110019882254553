export default {
  bind(el, binding) {
    el.__LoseFocusHandler__ = (event) => {
      if (!el.contains(event.target)) {
        binding.value(event)
      }
    }
    document.body.addEventListener('focus', el.__LoseFocusHandler__, true)
  },
  unbind(el) {
    document.body.removeEventListener('focus', el.__LoseFocusHandler__, true)
  },
}
