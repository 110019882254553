export default {
  confirm: 'Bekræft kodeord',
  email: 'Email adresse',
  name: 'Navn',
  password: 'Adgangskode',
  register: 'Registrer',
  registerText:
    'Registrering tager kun et minut og giver dig mulighed for at modtage e-mail notifikationer af flypriser. Du kan når som helst annullere ...',
  registerSuccess: 'Du har registreret dig.',
  registerSubmitting: 'Vent venligst...',
  update: 'Opdatere',
  updateSubmitting: 'Opdatering...',
  delete: 'Slet',
  deleteSubmitting: 'Sletning',
  forgot: 'Glemt dit password?',
  login: 'Log ind',
  loginSubmitting: 'Logger ind...',
  remember: 'Gem login og password',
  tryAgain: 'Prøv igen...',
  serverConnection: 'Kan ikke oprette forbindelse til serveren',
  failed: 'Disse legitimationsoplysninger svarer ikke til vores poster.',
  throttle: 'For mange login forsøg. Prøv igen i :seconds sekunder.',
  passwordLength:
    'Adgangskoder skal være mindst seks tegn og matche bekræftelsen.',
  reset: 'Dit kodeord er blevet nulstillet!',
  sent: 'Vi har emailet din adgangskode reset link!',
  token: 'Denne adgangskode til nulstilling af adgangskode er ugyldig.',
  user: 'Vi kan ikke finde en bruger med den e-mail-adresse.',
  contactUs: 'Contact us',
  contactText:
    'Have you spotted an error? Is there anything you would like us to improve?',
  drop: "Drop us an email and we'll do our best to help!",
  message: 'Your message',
  contact: 'Send message',
  contactSubmitting: 'Sending message',
}
