export default {
  connection: 'Verbindung am Flughafen',
  longWait: 'Lange Wartezeit',
  h: ' St.',
  min: ' Min.',
  change: 'Flughafen wechseln',
  in: 'in',
  from: 'von',
  to: 'nach',
  stayOnPlane: 'An Bord des Flugzeugs bleiben',
  unknown: 'Verbindungsflughafen (unbekannt)',
}
