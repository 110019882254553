<template>
  <DatePicker
    :id="id"
    :ref="id"
    :append-to-body="true"
    :format="customFormatter"
    :parser="customParser"
    input-class="text-input sm:h-12 flex-1 focus:outline-none focus:shadow-outline"
    :language="languageDatepicker"
    :disabled-dates="disabledDates"
    :first-day-of-week="firstDayOfWeek"
    :highlighted="highlighted"
    :include-disabled="false"
    :initial-view="minimumView"
    :minimum-view="minimumView"
    :show-calendar-on-focus="false"
    :placeholder="placeholder"
    :value="value"
    @selected="$emit('selected', $event)"
    @cleared="$emit('selected', null)"
  >
    <div slot="beforeCalendarHeader">
      <CalendarTypeTabs />
      <CheapestMonthButton
        v-if="minimumView === 'month'"
        @select-cheapest-month="selectCheapestMonth"
      />
    </div>
  </DatePicker>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { format, parse } from 'date-fns'
import { en } from '@sum.cumo/vue-datepicker/dist/locale/index.esm'
import { enGB } from 'date-fns/locale'
// import DatePicker from '@sum.cumo/vue-datepicker'
import DatePicker from './vue-datepicker/index.js'
import CheapestMonthButton from './CheapestMonthButton'
import CalendarTypeTabs from './CalendarTypeTabs'

const SPECIFIC = 'E d MMM yyyy'
const FLEXIBLE = 'MMM yyyy'

export default {
  name: 'PickDate',
  components: {
    CheapestMonthButton,
    CalendarTypeTabs,
    DatePicker,
  },
  props: {
    disabledDates: {
      type: Object,
      default: () => {},
    },
    highlighted: {
      type: Object,
      default: () => {},
    },
    id: {
      type: String,
      required: true,
    },
    minimumView: {
      type: String,
      default: 'day',
    },
    type: {
      type: String,
      default: 'specific',
    },
    value: {
      type: [String, Date, Number],
      default: '',
      validator: (val) =>
        val === null ||
        val instanceof Date ||
        typeof val === 'string' ||
        typeof val === 'number',
    },
  },
  data() {
    return {
      languageDatepicker: en,
      languageDateFns: enGB,
    }
  },
  computed: {
    ...mapState(['criteria', 'settings']),
    ...mapGetters(['country', 'locale']),
    countryId() {
      return this.settings.countryId
    },
    dateFormat() {
      return this.type === 'specific' ? SPECIFIC : FLEXIBLE
    },
    firstDayOfWeek() {
      return this.country && this.country.first_day_of_week
    },
    placeholder() {
      if (this.id === 'inbound-date' && this.criteria.tripType === 'one-way') {
        return `(${this.$t('deals.oneWay')})`
      }
      return this.value === 'anytime'
        ? this.$t('searchControlsContainer.cheapestMonth')
        : this.$t('searchControlsContainer.enterDate')
    },
  },
  watch: {
    locale(locale) {
      this.importLanguage(locale)
      this.importLanguageFns(locale)
    },
  },
  methods: {
    customFormatter(date) {
      if (this.type === 'anytime') {
        return this.$t('searchControlsContainer.cheapestMonth')
      }

      return format(date, this.dateFormat, { locale: this.languageDateFns })
    },
    customParser(date) {
      return parse(date, this.dateFormat, new Date())
    },
    importLanguage(locale) {
      import(
        /* webpackInclude: /(en|da|de|fr|es|it|nb|nl|pt|sv)+\.js$/ */
        /* webpackChunkName: "dates-[request]" */
        `@sum.cumo/vue-datepicker/dist/locale/`
      ).then((language) => {
        this.languageDatepicker = language[locale]
      })
    },
    importLanguageFns(locale) {
      let localeDateFns = locale

      switch (localeDateFns) {
        case 'en':
          if (['AU', 'CA', 'IN', 'NZ', 'US', 'ZA'].includes(this.countryId)) {
            localeDateFns = `en${this.countryId}`
          } else {
            localeDateFns = 'enGB'
          }
          break
        case 'de':
          if (this.countryId === 'AT') {
            localeDateFns = 'deAT'
          }
          break
        case 'fr':
          if (['CA', 'CH'].includes(this.countryId)) {
            localeDateFns = `fr${this.countryId}`
          }
          break
        case 'nl':
          if (this.countryId === 'BE') {
            localeDateFns = 'nlBE'
          }
          break
        case 'pt':
          if (this.countryId === 'BR') {
            localeDateFns = 'ptBR'
          }
          break
      }

      import(
        /* webpackInclude: /(enAU|enCA|enIN|enNZ|enUS|enZA|enGB|da|de|deAT|fr|frCA|frCH|es|it|nb|nl|nlBE|pt|ptBR|sv)+\.js$/ */
        /* webpackChunkName: "date-fns-[request]" */
        `date-fns/locale`
      ).then((languageDateFns) => {
        this.languageDateFns = languageDateFns[localeDateFns]
      })
    },
    selectCheapestMonth() {
      this.$emit('selected', 'anytime')
      this.$refs[this.id].close()
    },
  },
}
</script>
