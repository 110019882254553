export default {
  yourAccount: 'Il tuo account',
  profile: 'profilo',
  Profile: 'Profilo',
  savedSearch: 'ricerca salvata',
  savedSearches: 'Ricerche salvate',
  congratulations: 'Complimenti!',
  updatedProfile: 'Hai aggiornato il tuo profilo con successo',
  deletedProfile: 'Hai cancellato con successo il tuo profilo',
  loggedInAs: 'Ciao, sei entrato come',
  loggedInAsGerman: '.',
  editYour: 'Puoi modificare il tuo',
  editYourGerman: '',
  orSetup: ', o impostare una',
  orSetupGerman: '',
  weWill:
    "e ti invieremo un'email con gli ultimi prezzi dei voli che ti interessano.",
  noSavedSearches: 'Al momento non hai ricerche salvate.',
  toSetUp: 'Per impostarne uno, è sufficiente effettuare una ricerca dalla',
  toSetUpGerman: '',
  searchPage: 'pagina di ricerca',
  clickSave: ' e quindi fare clic sul pulsante',
  saveButton: 'Salva ricerca',
  resultsPage: 'nella pagina dei risultati.',
  thankYou: 'Thank you!',
  sentSuccess: 'Il tuo messaggio è stato inviato con successo!',
}
