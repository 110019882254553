export default {
  save: 'Guardar',
  cancel: 'Cancelar',
  contact: 'Contacto',
  direct: 'Vuelos directos',
  deals: 'Ofertas especiales',
  logout: 'Cerrar sesión',
  london_gatwick: 'Londres Gatwick',
  preferences: 'Preferencias',
  search: 'Buscar vuelos',
  title: 'Vuelos a Gambia',
  description:
    'La fuente más completa del mundo para encontrar vuelos a Gambia',
}
