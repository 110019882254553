<template>
  <div
    v-click-outside="closeCabinClass"
    v-lose-focus="blurCabinClass"
    class="w-full mt-3 relative sm:m-0 sm:w-1/2"
  >
    <label class="form-label text-green-100" for="travellers">
      {{ $t('searchControlsContainer.classpax') }}
    </label>
    <button
      ref="cabinClassButton"
      class="
        flex
        justify-between
        items-center
        btn
        bg-white
        text-lg
        leading-none
        font-normal
        py-3
        px-2
        w-full
        sm:h-12
      "
      tabindex="0"
      type="button"
      @click.prevent="toggleCabinClass"
      @keydown.down="showCabinClass = true"
      @keydown.esc="closeCabinClass"
    >
      {{ travellersText }}
      <InlineSvg name="chevron-down" classes="fill-current w-4 h-4"></InlineSvg>
    </button>

    <CabinClass
      v-show="showCabinClass"
      ref="cabinClass"
      @close-cabin-class="closeCabinClass"
    />
  </div>
</template>

<script>
import CabinClass from './CabinClass'
import { mapState } from 'vuex'

export default {
  name: 'CabinClassAndTravellers',
  components: { CabinClass },
  data() {
    return {
      showCabinClass: false,
    }
  },
  computed: {
    ...mapState(['criteria']),
    invalidAges() {
      let invalidAges = []
      const ages = this.criteria.children

      for (let i = 0; i < ages.length; i++) {
        if (ages[i] === -1) {
          invalidAges.push(i)
        }
      }

      return invalidAges
    },
    travellersText() {
      const { cabinClass, adults, children } = this.criteria
      const cabinClassText = this.$t('searchControlsContainer')[cabinClass]
      const travellersCount = adults + children.length
      let travellersText = `1 ${this.$t('searchControlsContainer.adult')}`

      if (travellersCount > 1) {
        travellersText = `${travellersCount} ${this.$t(
          'searchControlsContainer.travellers',
        )}`
      }

      return `${travellersText}, ${cabinClassText}`
    },
  },
  watch: {
    showCabinClass(isOpen) {
      if (isOpen) {
        this.$nextTick(() => {
          this.$refs.cabinClass.$refs.cabinClass.focus()
        })
      }
    },
  },
  methods: {
    agesAreValid() {
      if (this.invalidAges.length) {
        document.getElementById(`childAge${this.invalidAges[0]}`).focus()
        return false
      }
      return true
    },
    closeCabinClass() {
      if (!this.showCabinClass || !this.agesAreValid()) {
        return
      }
      this.showCabinClass = false
      this.$refs.cabinClassButton.focus()
    },
    blurCabinClass() {
      if (!this.showCabinClass || !this.agesAreValid()) {
        return
      }
      this.showCabinClass = false
    },
    toggleCabinClass() {
      if (this.showCabinClass) {
        this.closeCabinClass()
        return
      }

      this.showCabinClass = true
    },
  },
}
</script>
