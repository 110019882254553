export default {
  filter: 'Filtre',
  sort: 'Sortér',
  checked: '',
  of: 'af',
  searching: 'Søger',
  providers: 'udbydere',
  results: 'resultater',
  showAll: 'Vis alt',
  appliedFilters: 'Vi har anvendt dine filtre.',
  combine: 'fly kombinationer',
}
