export default {
  loading: 'Hämtar',
  notAvailable: 'Tyvärr, den här biljetten är inte längre tillgänglig',
  soldOut: 'Biljetten kan ha sålt ut.',
  refresh: 'Uppdatera resultat',
  check: 'Kolla din resplan',
  timesLocal: 'alla klockslag är lokal tid',
  departingFrom: 'Du reser ut från',
  returningTo: 'men kommer tillbaka till',
  zuruck: '',
  bookTicket: 'Boka dina biljetter',
  readBefore: 'Läs innan du bokar',
  pricesShown:
    'I priserna du ser ingår alltid en ungefärlig uppskattning av alla obligatoriska skatter och avgifter, men du bör ändå',
  checkAll: 'kontrollera ALLA biljettuppgifter, slutpriser och villkor',
  beforeBook: 'på den aktuella webbplatsen innan du bokar.',
  extraFees: 'Kontrollera om det tillkommer extra avgifter\n',
  someAirlines: 'Vissa flyg- och resebolag tar ut extra avgifter för',
  baggage: 'bagage',
  insurance: 'försäkring',
  orUseOf: 'eller betalning med ',
  creditCards: 'kreditkort',
  view: 'Se',
  airlineFees: 'flygbolagens avgifter',
  an: '.',
  checkTC:
    'Kontrollera vilka villkor som gäller för resenärer som är mellan 12 och 16 år\n',
  restrictions: 'Minderåriga passagerare kan behöva resa i sällskap av vuxen.',
  adults: 'vuxna',
  adult: 'vuxen',
  children: 'barn',
  child: 'barn',
  infants: 'spädbarn',
  infant: 'spädbarn',
  combine: 'Kombinera 2 enkla flygningar',
}
