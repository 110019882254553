export default {
  sortBy: 'Sort by',
  score: 'Best',
  price: 'Cheapest first',
  duration: 'Fastest first',
  scoreName: 'Best',
  priceName: 'Cheapest',
  durationName: 'Fastest',
  departure: 'Departure time',
  arrival: 'Arrival time',
  outboundDeparture: 'Outbound: Departure time',
  outboundArrival: 'Outbound: Arrival time',
  inboundDeparture: 'Return: Departure time',
  inboundArrival: 'Return: Arrival time',
  airline: 'Airline',
  stops: 'Stops',
  average: 'average',
  scoreTip:
    'We think these flights offer the best combination of <strong>price</strong> and <strong>speed</strong>. We may also consider factors like number of stops and amount of hassle.',
  priceTip: 'Sort by cheapest price.',
  durationTip: 'Sort by shortest duration.',
}
