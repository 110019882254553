export default {
  getPriceAlerts: 'Stel prijsalarm in',
  airlines: 'Luchtvaartmaatschappij',
  combination: 'Combinaties',
  airports: 'Vliegvelden',
  stops: 'Tussenstops',
  direct: 'Direct',
  oneStop: '1 tussenstop',
  twoPlusStops: '2+ tussenstops',
  none: 'Geen',
  selectAll: 'Alles selecteren',
  clearAll: 'Alles wissen',
  flyOut: 'Vlieg heen en weer vanaf',
  flyOutGerman: '',
  sameAirports: 'dezelfde luchthaven',
  no: 'Geen',
  changeAirport: 'verandering van luchthaven',
  duringConnections: 'tijdens verbindingen',
  departFrom: 'Vertrek vanaf',
  arriveAt: 'Aankomst op',
  journeyDuration: 'Reisduur',
  hours: 'uur',
  departureTimes: 'Vertrektijden',
  arrivalTimes: 'Aankomsttijden',
  outbound: 'Heen',
  inbound: 'Terug',
}
