export default {
  loading: 'Cargando',
  notAvailable: 'Lo sentimos, pero esta tarifa ya no está disponible',
  soldOut: 'Es posible que este billete se haya agotado.',
  refresh: 'Volver a los resultados',
  check: 'Itinerario',
  timesLocal: 'todos los horarios son locales',
  departingFrom: 'La salida será desde',
  returningTo: 'pero llegará a',
  zuruck: '',
  bookTicket: 'Reserva tus billetes',
  readBefore: 'Léelo antes de reservar',
  pricesShown:
    'Los precios mostrados incluyen siempre un cálculo estimado de todos los impuestos y cargos obligatorios, pero acuérdate de',
  checkAll:
    'comprobar TODOS los detalles, precios finales y términos y condiciones del billete',
  beforeBook: 'en la página de reservas antes de hacer la tuya.',
  extraFees: 'Consulta los recargos',
  someAirlines: 'Algunas aerolíneas/agencias aplican un recargo para',
  baggage: 'equipaje',
  insurance: 'seguros',
  orUseOf: 'o uso de',
  creditCards: 'tarjetas de crédito',
  view: 'Ver',
  airlineFees: 'cargos de las aerolíneas',
  an: '.',
  checkTC: 'Consultar las condiciones para pasajeros de entre 12 y 16 años',
  restrictions:
    'Puede haber restricciones para pasajeros jóvenes que viajen solos.',
  adults: 'adultes',
  adult: 'adulto',
  children: 'niños',
  child: 'niño',
  infants: 'bebés',
  infant: 'bebé',
  combine: 'Combina 2 vuelos individuales',
}
