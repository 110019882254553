export default {
  loading: 'Loading',
  notAvailable: 'Sorry, this fare is no longer available',
  soldOut: 'The ticket may have sold out.',
  refresh: 'Refresh results',
  check: 'Check your itinerary',
  timesLocal: 'all times are local',
  departingFrom: 'You are departing from',
  arrivingAt: 'You are arriving at',
  returningTo: 'but are returning to',
  returningFrom: 'but are returning from',
  zuruck: '',
  bookTicket: 'Book your ticket',
  readBefore: 'Read before booking',
  pricesShown:
    'Prices shown always include an estimate of all mandatory taxes and charges, but remember to',
  checkAll: 'check ALL ticket details, final prices and terms and conditions',
  beforeBook: 'on the booking website before you book.',
  extraFees: 'Check for extra fees',
  someAirlines: 'Some airlines / agents charge extra for',
  baggage: 'baggage',
  insurance: 'insurance',
  orUseOf: 'or use of',
  creditCards: 'credit cards',
  view: 'View',
  airlineFees: 'airlines fees',
  an: '.',
  checkTC: 'Check T&Cs for travellers aged 12-16',
  restrictions: 'Restrictions may apply to young passengers travelling alone.',
  adults: 'adults',
  adult: 'adult',
  children: 'children',
  child: 'child',
  infants: 'infants',
  infant: 'infant',
  combine: 'Combine',
  flights: 'flights',
  showDetails: 'Show details',
  hideDetails: 'Hide details',
}
