import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import HomePage from '../views/HomeView'

Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage,
  },
  {
    path: '/:locale',
    name: 'homePage',
    component: HomePage,
  },
  {
    path: '/:locale/admin',
    name: 'admin',
    component: () =>
      import(/* webpackChunkName: "route-admin" */ '../views/AdminView.vue'),
    meta: {
      forAuth: true,
    },
  },
  {
    path: '/:locale/routes',
    name: 'routes',
    component: () =>
      import(/* webpackChunkName: "route-routes" */ '../views/RoutesView.vue'),
  },
  {
    path: '/:locale/flexible',
    name: 'flexible',
    component: () =>
      import(
        /* webpackChunkName: "route-flexible" */ '../views/FlexibleView.vue'
      ),
  },
  {
    path: '/:locale/specific',
    name: 'specific',
    component: () =>
      import(
        /* webpackChunkName: "route-specific" */ '../views/SpecificView.vue'
      ),
  },
  {
    path: '/:locale/contact',
    name: 'contact',
    component: () =>
      import(
        /* webpackChunkName: "route-contact" */ '../views/auth/ContactView.vue'
      ),
  },
  {
    path: '/:locale/terms',
    name: 'terms',
    component: () =>
      import(/* webpackChunkName: "route-terms" */ '../views/TermsView.vue'),
  },
  {
    path: '/:locale/privacy',
    name: 'privacy',
    component: () =>
      import(
        /* webpackChunkName: "route-privacy" */ '../views/PrivacyView.vue'
      ),
  },
  {
    path: '/:locale/login',
    name: 'login',
    component: () =>
      import(
        /* webpackChunkName: "route-login" */ '../views/auth/LoginView.vue'
      ),
    meta: {
      forVisitors: true,
    },
  },
  {
    path: '/:locale/register',
    name: 'register',
    component: () =>
      import(
        /* webpackChunkName: "route-register" */ '../views/auth/RegisterView.vue'
      ),
    meta: {
      forVisitors: true,
    },
  },
  {
    path: '/:locale/airline-fees',
    name: 'airlineFees',
    component: () =>
      import(
        /* webpackChunkName: "route-airlineFees" */ '../views/AirlineFeesView.vue'
      ),
  },
  {
    path: '/:locale/home',
    name: 'userHome',
    component: () =>
      import(
        /* webpackChunkName: "route-userHome" */ '../views/UserHomeView.vue'
      ),
    meta: {
      forAuth: true,
    },
  },
  {
    path: '/:locale/profile',
    name: 'userProfile',
    component: () =>
      import(
        /* webpackChunkName: "route-userProfile" */ '../views/UserProfileView.vue'
      ),
    meta: {
      forAuth: true,
    },
  },
  {
    path: '/:locale/price-alerts',
    name: 'priceAlerts',
    component: () =>
      import(
        /* webpackChunkName: "route-priceAlerts" */ '../views/PriceAlertsView.vue'
      ),
    meta: {
      forAuth: true,
    },
  },
  {
    path: '/:locale/sms',
    name: 'SMS',
    component: () =>
      import(/* webpackChunkName: "route-sms" */ '../views/SMSView.vue'),
    meta: {
      forAuth: true,
    },
  },
  {
    path: '*',
    name: 'notFound',
    redirect: {
      name: 'home',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
