export default {
  getPriceAlerts: 'Get price alerts',
  airlines: 'Airlines',
  agents: 'Agents',
  combination: 'Airline combinations',
  airports: 'Airports',
  stops: 'Stops',
  direct: 'Direct',
  oneStop: '1 stop',
  twoPlusStops: '2+ stops',
  none: 'None',
  selectAll: 'Select all',
  clearAll: 'Clear all',
  flyOut: 'Fly out and back using the',
  flyOutGerman: '',
  sameAirports: 'same airports',
  no: 'No',
  changeAirport: 'change of airport',
  duringConnections: 'during connections',
  departFrom: 'Depart from',
  arriveAt: 'Arrive at',
  journeyDuration: 'Journey duration',
  hours: 'hours',
  departureTimes: 'Departure times',
  arrivalTimes: 'Arrival times',
  outbound: 'Outbound',
  inbound: 'Return',
}
