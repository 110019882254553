export default {
  NB: 'Vänligen notera',
  nextTip: 'Nästa tips',
  previousTip: 'Föregående tips',
  lastTip: 'Sista tipset',
  tip_1:
    'Denna månadsvy ger en översikt över de billigaste flygpriserna som hittats de senaste 15 dagarna. Priserna ' +
    'kan ha ändrats, så för att få de senaste erbjudandenen måste du utföra en levande sökning genom att klicka på ',
  button: '.',
  tip_2: 'Använd fliken',
  toShow: 'för att visa',
  restrict:
    ' eller för att begränsa varaktighet och / eller tidigaste avgång / senaste avkastningen.',
  cycle: 'Du kan cykla genom de återstående resultaten med hjälp av knappen ',
  anzuzeigen: '',
  orClick: 'knapparna (eller klicka direkt på datum)',
  tip_3: 'När du har hittat lämpliga datum kan du klicka på',
  liveSearch:
    'för att utföra en levande sökning av de senaste erbjudandenen och priserna för dina valda datum.',
  letsGo: 'Okej då drar vi!',
}
