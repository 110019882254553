export default {
  sortBy: 'Ordina per',
  score: 'Il migliore',
  price: 'Dal più economico',
  duration: 'Dal più veloce',
  scoreName: 'Migliore',
  priceName: 'Il più economico',
  durationName: 'Il più veloce',
  departure: 'Orario di partenza',
  arrival: 'Orario di arrivo',
  outboundDeparture: 'Andata: orario di partenza',
  outboundArrival: 'Andata: orario di arrivo',
  inboundDeparture: 'Ritorno: orario di partenza',
  inboundArrival: 'Ritorno: orario di arrivo',
  airline: 'Compagnia aerea',
  stops: 'Scali',
  average: 'media',
  bestTip:
    'Riteniamo che questi voli offrano la combinazione migliore di <strong>prezzo</strong> e <strong>durata</strong>. Teniamo conto anche di fattori come il numero di scali e gli eventuali disagi.',
  cheapestTip: 'Ordina per prezzo più basso.',
  durationTip: 'Ordina per volo più breve.',
}
