export default {
  confirm: 'Confirmer le mot de passe',
  email: 'Adresse email',
  name: 'Nom',
  password: 'Mot de passe',
  register: 'Enregistrer',
  registerText:
    "L'inscription ne prend qu'une minute et te permet de recevoir des notifications par email des prix des vols. Tu peux annuler à tout moment...",
  registerSuccess: "Tu t'es enregistré avec succès.",
  registerSubmitting: 'Veuillez patienter...',
  update: 'Mettre à jour',
  updateSubmitting: 'Mise à jour...',
  delete: 'Supprimer',
  deleteSubmitting: 'Supression...',
  forgot: 'Mot de passe oublié?',
  login: "S'identifier",
  loginSubmitting: 'Connexion en cours...',
  remember: 'Se souvenir de moi',
  tryAgain: "Oups, ça n'a pas marché!",
  serverConnection: 'Incapable de se connecter au serveur',
  failed: 'Ces identifiants ne correspondent pas à nos enregistrements.',
  throttle:
    'Trop de tentatives de connexion. Veuillez essayer de nouveau dans :seconds secondes.',
  passwordLength:
    'Passwords must be at least six characters and match the confirmation.',
  reset:
    'Les mots de passe doivent contenir au moins six caractères et doivent être identiques.',
  sent: 'Nous vous avons envoyé par courriel le lien de réinitialisation du mot de passe !',
  token: "Ce jeton de réinitialisation du mot de passe n'est pas valide.",
  user: "Aucun utilisateur n'a été trouvé avec cette adresse e-mail.",
  contactUs: 'Contact us',
  contactText:
    'Have you spotted an error? Is there anything you would like us to improve?',
  drop: "Drop us an email and we'll do our best to help!",
  message: 'Your message',
  contact: 'Send message',
  contactSubmitting: 'Sending message',
}
