export default {
  sortBy: 'Sortera efter',
  score: 'Bäst',
  price: 'Billigast först',
  duration: 'Snabbast först',
  scoreName: 'Bäst',
  priceName: 'Billigast',
  durationName: 'Snabbast',
  departure: 'Avgångstid',
  arrival: 'Ankomst tid',
  outboundDeparture: 'Utresa: Avgångstid',
  outboundArrival: 'Utresa: Ankomst tid',
  inboundDeparture: 'Hemresa: Avgångstid',
  inboundArrival: 'Hemresa: Ankomst tid',
  airline: 'Flygbolag',
  stops: 'Stopp',
  cheapest: 'Billigast',
  fastest: 'Snabbast',
  average: 'genomsnittlig',
  bestTip:
    'Vi tycker att dessa flyg erbjuder den bästa kombinationen av <strong>pris</strong> och <strong>hastighet</strong>. Vi kan även ta med faktorer som antal mellanlandningar och bekvämlighet i beräkningen.',
  cheapestTip: 'Sortera efter billigaste pris.',
  durationTip: 'Sortera efter kortast restid.',
}
