export default {
  AMS: 'Amsterdam',
  BHX: 'Birmingham',
  LGW: 'London Gatwick',
  MAN: 'Manchester',
  BCN: 'Barcelona',
  BRU: 'Brussels',
  LPA: 'Gran Canaria',
  DKRA: 'Dakar',
  FRA: 'Frankfurt',
  DUS: 'Dusseldorf',
  CDG: 'Paris',
  MUC: 'Munich',
  return: 'return',
  oneWay: 'one way',
  topDeals: "Today's top deals",
  welcome: "The web's most comprehensive resource for flights to The Gambia!",
  explain_1:
    'N.B. This project is/was a work-in-progress and should only be viewed as such. I originally wrote it as a standard Laravel application, but then decoupled the front and back-ends, so it is now a Vue-CLI project that is powered by a Laravel API.',
  explain_2:
    "I'd like to revive the project for learning purposes, so would appreciate it if you could provide me with an updated API key (the one I currently have is for v1). Thank you!",
  explain_3:
    "I was part-way through migrating the frontend from Bootstrap to Tailwind CSS, so the styling is quite ropey in places. Also, as I've shut down the web scraper that augmented these Skyscanner results with my own, you won't get to experience the main benefit of the site. But hopefully you'll get a flavour of what it was all about!",
  readMore: 'Read more',
  readLess: 'Read less',
}
