<template>
  <div v-click-outside="close">
    <div class="relative cursor-pointer z-10">
      <button
        ref="dropdownButton"
        class="flex items-center btn btn-white h-12"
        :class="[isOpen ? placeHolderClass : '']"
        @click="toggleOptions"
        @keydown.down="open"
      >
        {{ placeHolder }}
        <InlineSvg name="chevron-down" classes="w-4 h-4 ml-2"></InlineSvg>
      </button>

      <ul
        v-show="isOpen"
        ref="optionList"
        class="
          flex flex-col
          w-48
          absolute
          left-0
          top-0
          mt-12
          bg-white
          border border-gray-300
          rounded-b
          shadow-md
          focus:outline-none focus:shadow-outline
        "
        tabindex="0"
        @keydown.esc="close"
        @keydown.down.prevent="highlightNext"
        @keydown.up="highlightPrevious"
        @keydown.enter.prevent="select(options[highlightedIndex])"
      >
        <li
          v-for="(option, i) in items"
          :key="option.value"
          class="
            flex
            items-center
            w-auto
            p-2
            h-12
            border-b border-gray-300
            hover:bg-blue-200
          "
          :class="{ 'bg-blue-200': i === highlightedIndex }"
          @click="select(option)"
        >
          <InlineSvg
            :name="option.icon"
            classes="items-center w-4 h-4 mr-2"
          ></InlineSvg>
          {{ option.value }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DropDown',
  props: {
    placeHolder: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default: () => {},
    },
    selectedOptionIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      highlightedIndex: 0,
      isOpen: false,
      items: [],
    }
  },
  computed: {
    placeHolderClass() {
      return 'rounded-b-none overflow-hidden bg-white shadow-md'
    },
  },
  // directives: {
  //   clickOutside
  // },
  created() {
    this.setConfigData()
  },
  methods: {
    close() {
      if (!this.isOpen) {
        return
      }
      this.isOpen = false

      document.body.style.removeProperty('overflow')
    },
    highlight(index) {
      if (this.isOpen) {
        this.highlightedIndex = index
        if (this.highlightedIndex === this.options.length) {
          this.highlightedIndex = 0
        }
        if (this.highlightedIndex < 0) {
          this.highlightedIndex = this.options.length - 1
        }
      }
    },
    highlightNext() {
      this.highlight(this.highlightedIndex + 1)
    },
    highlightPrevious() {
      this.highlight(this.highlightedIndex - 1)
    },
    open() {
      this.isOpen = true
      this.highlight(this.selectedOptionIndex)

      document.body.style.setProperty('overflow', 'hidden')

      this.$nextTick(() => {
        this.$refs.optionList.focus()
      })
    },
    select(option) {
      this.$emit('set-selected-option', option)
      this.close()
      this.$refs.dropdownButton.focus()
    },
    setConfigData() {
      this.items = this.options
    },
    toggleOptions() {
      this.isOpen ? this.close() : this.open()
    },
  },
}
</script>
