export default {
  sortBy: 'Sorteren op',
  score: 'Aanbevolen',
  price: 'Goedkoopste eerst',
  duration: 'Snelste eerst',
  scoreName: 'Het beste',
  priceName: 'Goedkoopste',
  durationName: 'Snelste',
  departure: 'Vertrektijd',
  arrival: 'Aankomsttijd',
  outboundDeparture: 'Heenreis: Vertrektijd',
  outboundArrival: 'Heenreis: Aankomsttijd',
  inboundDeparture: 'Terugreis: Vertrektijd',
  inboundArrival: 'Terugreis: Aankomsttijd',
  airline: 'Maatschappij',
  stops: 'Tussenstops',
  average: 'gemiddeld',
  bestTip:
    'We denken dat deze vluchten de beste combinatie bieden van <strong>prijs</strong> en <strong>snelheid</strong>. We houden ook rekening met factoren zoals het aantal tussenlandingen en andere hinderlijke factoren.',
  cheapestTip: 'Sorteren op laagste prijs',
  durationTip: 'Sorteren op kortste duur',
}
