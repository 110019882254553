export default {
  save: 'Lagre',
  cancel: 'Avbryt',
  contact: 'Kontakt',
  direct: 'Direkte fly',
  deals: 'Beste tilbud',
  logout: 'Logg ut',
  london_gatwick: 'London Gatwick',
  preferences: 'Regionale innstillinger',
  search: 'Søke',
  title: 'Fly til Gambia',
  description: 'Verdens mest omfattende ressurs for flyreiser til Gambia',
}
