export default {
  NB: 'Notare che',
  nextTip: 'Prossimo consiglio',
  previousTip: 'Consiglio precedente',
  lastTip: 'Ultimo consiglio',
  tip_1:
    'Questa "vista mensile" fornisce una panoramica dei prezzi dei voli più economici trovati negli ultimi 15 giorni. ' +
    'I prezzi potrebbero essere cambiati, quindi per ottenere le ultime offerte è necessario eseguire una ricerca ' +
    'dal vivo facendo clic sul pulsante ',
  button: '.',
  tip_2: 'Utilizza la scheda',
  toShow: 'per visualizzare',
  restrict:
    ' o per limitare limitare la durata e / o le date de prima partenza o de ultimo ritorno. ',
  cycle: 'È possibile scorrere i risultati rimanenti utilizzando i pulsanti ',
  anzuzeigen: '',
  orClick: '(o fare clic direttamente sulle date)',
  tip_3: 'Una volta trovate le date adatte, puoi fare clic sul pulsante',
  liveSearch:
    'per eseguire una ricerca in diretta delle ultime offerte e dei prezzi per le date scelte',
  letsGo: 'Ok andiamo!',
}
