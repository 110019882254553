export default {
  filter: 'Filteren',
  sort: 'Sorteren',
  checked: 'Gecontroleerd ',
  of: 'van',
  searching: 'Bezig met zoeken bij',
  providers: 'aanbieders',
  results: 'results',
  collating: 'Gegevens verzamelen',
  topTen: 'Toont de top 10 resultaten',
  showAll: 'Toon alles',
  appliedFilters: 'We hebben uw filters toegepast.',
  combine: 'vluchtcombinaties',
}
