export default {
  airlineFees: 'Costi compagnia aerea',
  previousPage: 'Torna alla PAGINA precedente',
  pricesInclude:
    'I prezzi includono sempre una stima di tutte le tasse e gli addebiti obbligatori, ma alcune compagnie aeree / agenzie viaggi potranno applicare costi aggiuntivi per ',
  baggage: 'bagagli',
  insurance: 'assicurazioni',
  useOf: "l'utilizzo di ",
  creditCards: 'carte di credito',
  other: 'o altri servizi',
  findDetails:
    'I dettagli dei costi della compagnia aerea puoi trovarli qui sotto:',
}
