export default {
  return: 'Ida e Volta',
  oneway: 'Só ida',
  from: 'De',
  To: 'Para',
  to: 'para',
  placeholder: 'Insira país, cidade ou aeroporto',
  nearby: 'Adicionar aeroportos próximos',
  swap: 'Clique aqui para trocar os locais entre si',
  outbound: 'Partida',
  inbound: 'Regresso',
  classpax: 'Classe de cabina e viajantes',
  done: 'Concluir',
  cabin: 'Classe de cabine',
  travellers: 'viajantes',
  adult: 'adulto',
  Economy: 'Económica',
  PremiumEconomy: 'Económica superior',
  Business: 'Business',
  First: 'Primeira classe',
  EconomyClass: 'Classe económica',
  PremiumEconomyClass: 'Classe económica superior',
  BusinessClass: 'Business',
  FirstClass: 'Primeira classe',
  adults: 'Adultos',
  children: 'Crianças',
  over16: 'Mais de 16 anos',
  to15: '0-15 anos',
  childAge: 'Idade da criança',
  validAge:
    'A sua idade no momento da viagem deve ser válida para a categoria de idade reservada. As companhias aéreas têm restrições relativamente a menores de 18 anos que viajam sozinhos.',
  ageLimits:
    'Os limites de idade e as políticas de viagem com crianças podem variar, por isso, confirme com a companhia aérea antes de efetuar a reserva.',
  directOnly: 'Apenas voos diretos',
  search: 'Procurar voos',
  prev: 'Anterior',
  next: 'Próximo',
  changeSearch: 'Altere pesquisa',
  cheapestMonth: 'O mês mais barato',
  specificDate: 'Data específica',
  flexibleDate: 'Mês inteiro',
  wholeMonth: 'Apresentar o mês completo',
  showSpecific: 'Apresentar data específica',
  enterDate: 'Insira uma data',
}
