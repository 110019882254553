export default {
  outbound: 'Partenza:',
  inbound: 'Ritorno:',
  selectDates: 'Seleziona le date',
  calendar: 'Calendario',
  chart: 'Grafico',
  valid: 'N.B. Prezzo valido',
  more: 'più informazioni',
  less: 'meno informazioni',
  noPrice: 'N.B. Non abbiamo trovato alcun prezzo per ',
  thisDate: 'questa data ',
  theseDates: 'queste date ',
  notMean: 'negli ultimi 15 giorni, ma i voli potrebbero essere disponibili!',
  doSearch: 'Fare clic sul pulsante per eseguire una ricerca dal vivo',
  directOnly: 'Solo voli diretti',
  none: 'nessuno',
  loading: 'Caricamento in corso',
  prices: 'Prezzi',
  lowest: 'Più basso',
  medium: 'Medio',
  highest: 'Più alto',
  checkPrice: 'Prezzo da verificare',
  notDirect: 'Solo 1+ scali',
  outboundFlights: 'Voli in uscita',
  inboundFlights: 'Voli in entrata',
  showFlights: 'Mostra voli',
  from: 'a partire da',
  adultPrice: 'prezzo per adulto',
  min: 'Min',
  max: 'Max',
  any: 'Qualsiasi',
  summary: 'Sommario:',
  day: 'giorno',
  days: 'giorni',
  earliest: 'Prima partenza',
  latest: 'Ultimo ritorno',
  showCheapest: 'Mostra più economico',
  showingCheapest: 'Mostrando più economico',
  option: 'opzione',
  options: 'opzioni',
  moreFilters: 'Più filtri',
  hideFilters: 'Nascondi i filtri',
  showTips: 'Mostra consigli',
  hideTips: 'Nascondi consigli',
  estimatedOnly:
    'N.B. Solo i prezzi più bassi stimati. Aggiunti negli ultimi 15 giorni.',
}
