export default {
  AMS: 'Amsterdam',
  BHX: 'Birmingham',
  LGW: 'London Gatwick',
  MAN: 'Manchester',
  BCN: 'Barcelona',
  BRU: 'Brussel',
  LPA: 'Gran Canaria',
  DKRA: 'Dakar',
  FRA: 'Frankfurt',
  DUS: 'Dusseldorf',
  CDG: 'Paris',
  MUC: 'München',
  return: 'retur',
  oneWay: 'en vei',
  topDeals: 'Dagens beste tilbud',
  welcome:
    'Velkommen til nettets mest omfattende ressurs for billige flyreiser til Gambia!',
  explain_1:
    'N.B. Dette prosjektet er/var et under arbeid og bør kun sees på som det. Jeg skrev det opprinnelig som en standard Laravel-applikasjon, men koblet deretter fra front- og bakenden, så det er nå et Vue-CLI-prosjekt som drives av en Laravel API.',
  explain_2:
    'Jeg var halvveis med å migrere frontend fra Bootstrap til Tailwind CSS, så stylingen er noen steder ganske vanskelig. Ettersom jeg har stengt ned nettskraperen som utvidet disse Skyscanner-resultatene med mine egne, vil du ikke få oppleve hovedfordelen med nettstedet. Men forhåpentligvis vil det fortsatt gi deg en smak av hva det handlet om!',
  readMore: 'Les mer',
  readLess: 'Les mindre',
}
