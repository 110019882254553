<template>
  <li
    :aria-label="item.country_name"
    class="
      flex
      w-full
      p-4
      border-b border-gray-300
      cursor-pointer
      group
      hover:bg-blue-200 hover:text-blue-800
    "
    role="option"
    @click="setValue"
    @mousedown="setValue"
  >
    <svg
      class="mt-1 w-4 h-4 mr-2 align-text-bottom fill-current"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        d="M349.565 98.783C295.978 98.783 251.721 64 184.348 64c-24.955 0-47.309 4.384-68.045 12.013a55.947 55.947 0 0 0 3.586-23.562C118.117 24.015 94.806 1.206 66.338.048 34.345-1.254 8 24.296 8 56c0 19.026 9.497 35.825 24 45.945V488c0 13.255 10.745 24 24 24h16c13.255 0 24-10.745 24-24v-94.4c28.311-12.064 63.582-22.122 114.435-22.122 53.588 0 97.844 34.783 165.217 34.783 48.169 0 86.667-16.294 122.505-40.858C506.84 359.452 512 349.571 512 339.045v-243.1c0-23.393-24.269-38.87-45.485-29.016-34.338 15.948-76.454 31.854-116.95 31.854z"
      />
    </svg>

    <!-- eslint-disable vue/no-v-html -->
    <div>
      <p
        class="mt-0"
        v-html="`${item.country_name} (${item.id_highlighted})`"
      ></p>
      <p class="mt-2 text-xs tracking-wider" v-html="item.country_name"></p>
    </div>
    <!--eslint-enable-->
  </li>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    setValue() {
      this.$emit('hit', this.item)
    },
  },
}
</script>
