class Svg {
  constructor(name) {
    const div = document.createElement('div')
    div.innerHTML = require('html-loader!../assets/icons/' + name + '.svg') // be careful with dynamic webpack requires.

    // import(/* webpackChunkName: "svg-[request]" */ `html-loader!@/assets/icons/${name}.svg`).then(svg => {
    //   div.innerHTML = svg.default
    // })

    const fragment = document.createDocumentFragment()
    fragment.appendChild(div)

    this.svg = fragment.querySelector('svg')
  }

  classes(classes) {
    if (classes) {
      this.svg.classList.add('fill-current')

      classes.split(' ').forEach((className) => {
        this.svg.classList.add(className)
      })
    }

    return this
  }

  width(width) {
    if (width) {
      this.svg.setAttribute('width', width)
    }

    return this
  }

  height(height) {
    if (height) {
      this.svg.setAttribute('height', height)
    }

    return this
  }

  title(title) {
    if (title) {
      const myTitle = document.createElement('title')
      myTitle.innerHTML = title

      this.svg.appendChild(myTitle)
    }

    return this
  }

  toString() {
    return this.svg.outerHTML
  }
}

export default {
  props: ['name', 'classes', 'width', 'height', 'title'],

  render(h) {
    return h('span', {
      domProps: {
        innerHTML: new Svg(this.name)
          .classes(this.classes)
          .width(this.width)
          .height(this.height)
          .title(this.title),
      },
    })
  },
}
