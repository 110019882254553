export default {
  loading: 'Wird geladen',
  notAvailable: 'Dieser Preis ist leider nicht mehr verfügbar',
  soldOut: 'Dieses Ticket könnte ausverkauft sein.',
  refresh: 'Ergebnisse aktualisieren',
  check: 'Reiseplan überprüfen',
  timesLocal: 'Alle Zeitangaben sind in Ortszeit',
  departingFrom: 'Sie reisen von',
  returningTo: 'ab, reisen aber nach',
  zuruck: ' zurück',
  bookTicket: 'Tickets buchen',
  readBefore: 'Bitte vor der Buchung lesen',
  pricesShown:
    'Alle Preise enthalten eine Schätzung aller gesetzlichen Steuern und Gebühren, trotzdem',
  checkAll:
    'überprüfen Sie bitte ALLE Angaben auf dem Ticket, die endgültigen Preise und die allgemeinen Geschäftsbedingungen',
  beforeBook:
    'auf der Webseite des Ticketanbieters, bevor Sie Ihre Buchung vervollständigen.',
  extraFees: 'Zusätzliche Gebühren überprüfen',
  someAirlines:
    'Einige Fluggesellschaften / Reisebüros berechnen zusätzliche Gebühren für',
  baggage: 'Gepäck',
  insurance: 'Versicherung',
  orUseOf: 'oder die Verwendung von',
  creditCards: 'Kreditkarten',
  view: 'Sehen Sie sich die',
  airlineFees: 'Gebühren der Fluggesellschaft',
  an: ' an.',
  checkTC:
    'Bitte überprüfen Sie die AGB für Reisende im Alter von 12-16 Jahren',
  restrictions: 'Für alleinreisende Kinder gelten eventuell Sonderregelungen.',
  adults: 'Erwachsene',
  adult: 'Erwachsener',
  children: 'Kinder',
  child: 'Kind',
  infants: 'Kleinkinder',
  infant: 'Kleinkind',
  combine: '2 Einzelflüge kombinieren',
}
