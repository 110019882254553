export default {
  to: 'a',
  of: 'di',
  results: 'risultati',
  flightDetails: 'Dettagli',
  show: 'Mostra',
  more: 'più',
  fewer: 'meno',
  ofResults: 'risultati',
  anzeigen: '',
}
