export default {
  return: 'Aller-retour',
  oneway: 'Aller simple',
  from: 'De',
  To: 'A',
  to: 'à',
  placeholder: 'Saisir un pays, une ville ou un aéroport',
  nearby: 'Ajouter des aéroports à proximité',
  swap: 'Cliquer ici pour changer les endroits',
  outbound: 'Départ',
  inbound: 'Retour',
  classpax: 'Classe de cabine et voyageurs',
  done: 'Terminer',
  cabin: 'Classe de cabine',
  travellers: 'voyageurs',
  adult: 'adulte',
  Economy: 'Economique',
  PremiumEconomy: 'Éco. Supérieure',
  Business: 'Affaires',
  First: 'Première',
  EconomyClass: 'Classe économique',
  PremiumEconomyClass: 'Éco. Supérieure',
  BusinessClass: 'Affaires',
  FirstClass: 'Première',
  adults: 'Adultes',
  children: 'Enfants',
  over16: '16 ans et plus',
  to15: '0 à 15 ans',
  childAge: "Âge de l'enfant",
  validAge:
    "Votre âge à la date du voyage doit être valide pour la catégorie d'âge réservée. Les compagnies aériennes ont des restrictions pour les passagers de moins de 18 ans voyageant seuls.",
  ageLimits:
    "Les limites d'âge et les politiques en matière de voyage avec des enfants peuvent varier, veuillez vérifier avec la compagnie aérienne avant la réservation.",
  directOnly: 'Vols directs uniquement',
  search: 'Rechercher',
  prev: 'Préc.',
  next: 'Suivante',
  changeSearch: 'Modifier la recherche',
  cheapestMonth: 'Mois le moins cher',
  specificDate: 'Date précise',
  flexibleDate: 'Tout le mois',
  wholeMonth: 'Voir le mois entier',
  showSpecific: 'Voir date précise',
  enterDate: 'Entrez une date',
}
